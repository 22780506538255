<template>
  <div class="logs-table">
      <div>
        <a-table :columns="columns" :data-source="logsData" :rowKey="(record, index) => index" :loading="loading"
          :pagination="pagination" @change="changePagination" class="prisma-users__table">
          <template slot="updated_at" slot-scope="text, record">
            <span>{{ formatDate(record.updated_at) }}</span>
          </template>
          <template slot="old_status" slot-scope="text, record">
            <VerificationStatus v-if="record.old_status" :status="record.old_status" />
            <span v-else> - </span>
          </template>
          <template slot="new_status" slot-scope="text, record">
            <VerificationStatus v-if="record.new_status" :status="record.new_status" />
            <span v-else> - </span>
          </template>
          <template slot="is_amount_changed" slot-scope="text, record">
            <span>{{ record.is_amount_changed ? 'Changed' : 'Not changed' }}</span>
          </template>
          <template slot="old_amount" slot-scope="text, record">
            <span v-if="record.old_amount">{{ record.old_amount }}</span>
            <span v-else> - </span>
          </template>
          <template slot="new_amount" slot-scope="text, record">
            <span v-if="record.new_amount">{{ record.new_amount }}</span>
            <span v-else> - </span>
          </template>
        </a-table>
      </div>
  </div>
</template>
<script>
import PrismaService from '@/services/api/apiPrismaService'
import VerificationStatus from '@/views/apps/prisma/components/core/verificationStatus.vue'

export default {
  name: 'prizeLogs',
  props: ['prizeId'],
  components: { VerificationStatus },
  data: () => ({
    loading: false,
    logsData: null,
    pagination: {
      pageSize: 20,
      current: 1,
      total: 0,
    },
    columns: [
      {
        title: 'Id',
        dataIndex: 'id',
        width: '10%',
        scopedSlots: { customRender: 'id' },
      },
      {
        title: 'Initiator',
        dataIndex: 'initiator',
        scopedSlots: { customRender: 'initiator' },
        width: '15%',
      },
      {
        title: 'Date',
        dataIndex: 'updated_at',
        scopedSlots: { customRender: 'updated_at' },
        width: '15%',
      },
      {
        title: 'Old status',
        dataIndex: 'old_status',
        scopedSlots: { customRender: 'old_status' },
        width: '10%',
      },
      {
        title: 'New status',
        dataIndex: 'new_status',
        scopedSlots: { customRender: 'new_status' },
        width: '10%',
      },
      {
        title: 'Is amount changed',
        dataIndex: 'is_amount_changed',
        scopedSlots: { customRender: 'is_amount_changed' },
        width: '10%',
      },
      {
        title: 'Old amount',
        dataIndex: 'old_amount',
        scopedSlots: { customRender: 'old_amount' },
        width: '15%',
      },
      {
        title: 'New amount',
        dataIndex: 'new_amount',
        scopedSlots: { customRender: 'new_amount' },
        width: '15%',
      },
    ],
  }),
  computed: {
  },
  created() {
    this.getLogs()
  },
  methods: {
    async getLogs() {
      this.loading = true

      try {
        const { pageSize, current } = this.pagination
        const searchParams = new URLSearchParams()
        searchParams.append('perPage', pageSize)
        searchParams.append('page', current)
        const response = await PrismaService.getPrizesLogs(this.prizeId)
        if (response.data.data) {
          this.logsData = response.data.data
          this.pagination.total = response.data.data.meta.total
        }
      } catch (err) {
        this.$notification.error({
          message: 'Something went wrong',
          description: err.response.data.data.message,
        })
      } finally {
        this.loading = false
      }
    },
    formatDate: function (date) {
      return this.$moment(date).tz('Etc/GMT-3').format('DD.MM.YY HH:mm')
    },
    changePagination(pagination) {
      this.pagination = pagination
      this.getLogs()
    },
  },
}
</script>
  <style lang="scss">
  </style>
