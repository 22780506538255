import apiClient from '@/services/axios'

class AccountService {
  async getModelLogs(reqData) {
    return apiClient
      .get('/admin/user/change_logs', { params: reqData })
      .then((response) => {
        return response
      })
  }
}
export default new AccountService()
