<template>
  <div :class="$style.wrapperForgot" class="olimp-admin-auth">
    <!-- <div class="text-center">
      <h1 :class="$style.logo">
        <span class="text-primary">{{ settings.logo }}<span class="text-white">Bet</span></span>
      </h1>
    </div> -->
    <transition name="slide-fade" mode="out-in">
      <div v-if="show" class="card" :class="$style.containerForgot">
        <div  :class="$style.title">
          Reset Password
        </div>
        <div>
          <a-radio-group
            :value="settings.authProvider"
            @change="e => changeAuthProvider(e.target.value)"
          >
  <!--          <a-radio value="firebase" disabled>Firebase</a-radio>-->
            <a-radio value="jwt" hidden>JWT</a-radio>
  <!--          <a-tooltip>-->
  <!--            <template slot="title">-->
  <!--              <span>Read Docs Guide</span>-->
  <!--            </template>-->
  <!--            <a-radio value="auth0" disabled>Auth0</a-radio>-->
  <!--          </a-tooltip>-->
  <!--          <a-tooltip>-->
  <!--            <template slot="title">-->
  <!--              <span>Read Docs Guide</span>-->
  <!--            </template>-->
  <!--            <a-radio value="strapi" disabled>Strapi</a-radio>-->
  <!--          </a-tooltip>-->
          </a-radio-group>
        </div>
        <a-form :form="form">
          <a-form-item>
            <a-input
              size="large"
              placeholder="Email Address"
              v-decorator="['email', {rules: [{ required: true, message: 'Please input your Email!' }]}]"
            />
          </a-form-item>
          <a-button size="large" type="primary" :class="$style.button">
            Reset my password
          </a-button>
        </a-form>
        <div class="text-center mt-4">
          <router-link to="/auth/login" class="kit__utils__link">
            <i class="text-primary fe fe-arrow-left" />
            Go to Sign in
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'CuiForgotPassword',
  computed: {
    ...mapState(['settings']),
  },
  data: function () {
    return {
      show: false,
      form: this.$form.createForm(this),
    }
  },
  mounted() {
    this.show = true
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
