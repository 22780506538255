import { notification } from 'ant-design-vue'

const getErrorMessage = (data) => {
  if (data?.message) {
    notification.error({
      message: 'Error',
      description: data?.message || '',
    })
  } else if (data?.error) {
    notification.error({
      message: 'Error',
      description: data.error?.message || '',
    })
  } else {
    notification.error({
      message: 'Error',
    })
  }
}

export { getErrorMessage }
