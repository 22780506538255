<template>
  <div class="table-head">
    <a-form-model class="form-segmentsModal" :model="form" >
      <a-row class="pb-3">
        <a-radio-group v-if="segmentTypes.length" name="radioGroup" v-model="form.selectedType">
          <a-radio v-for="segmentType in segmentTypes" :key="segmentType.value" :value="segmentType.value" class="inline-radio">
            {{ segmentType.title.charAt(0) + segmentType.title.slice(1).toLowerCase() }}
          </a-radio>
        </a-radio-group>
        <div v-else class="mb-3 d-flex justify-content-center">
          <a-icon class="text-primary font-size-21" type="loading"></a-icon>
        </div>
      </a-row>
      <a-row class="footer-modal">
        <a-col class="text-right" :span="24">
          <a-button @click="closeModal"> Cancel </a-button>
          <a-button :loading="loading" :disabled="!isTypeDisabled" type="primary" @click="typeChosen" class="ml-2">
            Next
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  props: [],
  data: () => ({
    form: {
      selectedType: '',
    },
    loading: false,
    segmentTypes: [],
  }),
  computed: {
    isTypeDisabled() {
      return this.form.selectedType !== ''
    },
  },
  methods: {
    async getSegmentTypes(name) {
      this.loading = true
      try {
        const url = '/admin/segments/visibilities'
        const response = await apiClient.get(url)
        if (response.data.data) {
          this.segmentTypes = response.data.data
        }
      } finally {
        this.loading = false
      }
    },
    typeChosen () {
      this.$emit('selectSegmentType', this.form.selectedType)
    },
    closeModal() {
      this.$emit('closeModal')
    },
  },
  async created() {
    await this.getSegmentTypes()
  },
}
</script>

<style lang="scss">
</style>
