<template>
  <div class="emergency-tabs">
    <span class="emergency-tabs__head">Emergency text</span>
    <a-form-model :model="form" :rules="rules" ref="emergencyForm">
      <a-tabs
        v-model="activeLang"
        type="card"
        class="mt-2"
      >
        <a-tab-pane key="rus" tab="Rus">
          <a-textarea
            v-model="ruMessageValue"
            class="emergency-tabs__textarea"
            placeholder="Add text"
            :rows="4"
            @change="changeRuValue"
          />
          <div class="mt-3">
            <div class="d-flex flex-column">
              <span class="emergency-tabs__head">Button</span>
              <span class="emergency-tabs__desc">This is an optional field. If you want to display the button, you need to fill in both the "Button name" and "Button link" fields</span>
            </div>
            <div class="mt-3 d-flex align-items-center">
              <a-col :span="12" class="d-flex flex-column pr-2">
                <a-form-model-item label="Button name" prop="buttonRuNameValue">
                  <a-input
                    v-model="form.buttonRuNameValue"
                    placeholder="Name"
                    class="emergency-tabs__input"
                    @change="changeRuButtonName"
                  />
              </a-form-model-item>
              </a-col>
              <a-col :span="12" class="d-flex flex-column pl-2">
                <a-form-model-item label="Button link" prop="buttonLinkValue">
                  <a-input
                    v-model="form.buttonLinkValue"
                    placeholder="Link"
                    class="emergency-tabs__input"
                    @change="changeButtonLink"
                  />
                </a-form-model-item>
              </a-col>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="eng" tab="Eng">
          <a-textarea
            v-model="enMessageValue"
            class="emergency-tabs__textarea"
            placeholder="Add text"
            :rows="4"
            @change="changeEnValue"
          />
          <div class="mt-3">
            <div class="d-flex flex-column">
              <span class="emergency-tabs__head">Button</span>
              <span class="emergency-tabs__desc">This is an optional field. If you want to display the button, you need to fill in both the "Button name" and "Button link" fields</span>
            </div>
            <div class="mt-3 d-flex align-items-center">
              <a-col :span="12" class="d-flex flex-column pr-2">
                <a-form-model-item label="Button name" prop="buttonEnNameValue">
                  <a-input
                    v-model="form.buttonEnNameValue"
                    placeholder="Name"
                    class="emergency-tabs__input"
                    @change="changeEnButtonName"
                  />
              </a-form-model-item>
              </a-col>
              <a-col :span="12" class="d-flex flex-column pl-2">
                <a-form-model-item label="Button link" prop="buttonLinkValue">
                  <a-input
                    v-model="form.buttonLinkValue"
                    placeholder="Link"
                    class="emergency-tabs__input"
                    @change="changeButtonLink"
                  />
                </a-form-model-item>
              </a-col>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-form-model>
  </div>
</template>

<script>

export default {
  props: ['ruMessage', 'enMessage', 'ruButtonName', 'enButtonName', 'buttonLink'],
  data() {
    return {
      form: {
        buttonRuNameValue: '',
        buttonEnNameValue: '',
        buttonLinkValue: '',
      },
      rules: {
        buttonRuNameValue: [],
        buttonEnNameValue: [],
        buttonLinkValue: [],
      },
      errValidateForm: false,
      activeLang: 'rus',
      ruMessageValue: '',
      enMessageValue: '',
    }
  },
  watch: {
    'form.buttonRuNameValue'(val) {
      this.installRules()
    },
    'form.buttonEnNameValue'(val) {
      this.installRules()
    },
    'form.buttonLinkValue'(val) {
      this.installRules()
    },
  },
  computed: {
    validateBtnValue() {
      return (this.form.buttonRuNameValue?.length || this.form.buttonEnNameValue?.length) || this.form.buttonLinkValue?.length
    },
  },
  methods: {
    installRules() {
      if (this.validateBtnValue) {
        this.addRules()
      } else {
        this.rules.buttonRuNameValue = []
        this.rules.buttonEnNameValue = []
        this.rules.buttonLinkValue = []
        this.$nextTick(() => {
          this.removeTabErrClass()
          this.removeErrorClasses()
        })
      }
    },
    addRules() {
      this.rules.buttonRuNameValue = [{ required: true, message: 'Button name is required!' }]
      this.rules.buttonEnNameValue = [{ required: true, message: 'Button name is required!' }]
      this.rules.buttonLinkValue = [{ required: true, message: 'Button link is required!' }]
    },
    removeErrorClasses() {
      const inputsContainers = document.querySelectorAll('.ant-form-item-with-help')
      inputsContainers.forEach(el => {
        el.classList.remove('ant-form-item-with-help')
      })
      const inputs = document.querySelectorAll('.has-error')
      inputs.forEach(el => {
        el.classList.remove('has-error')
        if (el.children.length > 1) {
          el.removeChild(el.children[1])
        }
      })
    },
    changeRuValue() {
      this.$emit('changeRuValue', this.ruMessageValue)
    },
    changeEnValue() {
      this.$emit('changeEnValue', this.enMessageValue)
    },
    changeRuButtonName() {
      this.$emit('changeRuButtonName', this.form.buttonRuNameValue)
    },
    changeEnButtonName() {
      this.$emit('changeEnButtonName', this.form.buttonEnNameValue)
    },
    changeButtonLink() {
      this.$emit('changeButtonLink', this.form.buttonLinkValue)
    },
    checkFormValues() {
      let validate = null
      this.$refs.emergencyForm.validate((valid, values) => {
        if ((values?.buttonRuNameValue && this.activeLang !== 'rus') || (values?.buttonEnNameValue && this.activeLang !== 'eng')) {
          this.addTabErrClass()
        }
        validate = valid
      })
      return validate
    },
    addTabErrClass() {
      const tabs = document.querySelectorAll('.ant-tabs-tab')
      const inactiveTab = tabs[0].classList.contains('ant-tabs-tab-active') ? tabs[1] : tabs[0]
      inactiveTab.classList.add('tab-error')
    },
    removeTabErrClass() {
      const tabErr = document.querySelector('.tab-error')
      if (tabErr) {
        tabErr.classList.remove('tab-error')
      }
    },
  },
  mounted() {
    this.ruMessageValue = this.ruMessage
    this.enMessageValue = this.enMessage

    this.form.buttonRuNameValue = this.ruButtonName
    this.form.buttonEnNameValue = this.enButtonName
    this.form.buttonLinkValue = this.buttonLink

    if (this.validateBtnValue) {
      this.addRules()
    }

    // Костыль, так как валидатор не видит инпута в табе, который мы не открывали
    this.activeLang = 'eng'
    this.$nextTick(() => {
      this.activeLang = 'rus'
    })
  },
}
</script>

<style lang="scss">
@import '@/components/mixins.scss';

.emergency-tabs {
  & .ant-tabs-tab {
    margin-right: 2px !important;
  }
  & > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    max-width: 650px;
    margin-top: 0px !important;
    border: 1px solid $gray-2;
    border-radius: 0 4px 4px 4px;
    background: $white;
    padding: 16px;
  }
  & .ant-tabs-content > .ant-tabs-tabpane-inactive {
    border: none !important;
  }
  & > .ant-tabs-card > .ant-tabs-bar {
    margin-bottom: -1px !important;
    display: flex;
    border-color: $white;
    & .ant-tabs-extra-content {
      order: 1;
      float: none !important;
      display: flex;
      margin-bottom: -1px;
    }
  }
  & > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    color: $gray-8;
    border-color: transparent;
    background: transparent;
    &:not(.ant-tabs-tab-active) {
      background-color: $gray-11 !important;
    }
  }
  & > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: $white;
    background: $white;
  }
  & .tab-error {
    color: #f5222e;
    font-weight: 700;
    border-top-color: #f5222e !important;
    border-right-color: #f5222e !important;
    border-left-color: #f5222e !important;
  }
  // & .ant-input {
  //   border: none;
  //   &:focus {
  //     box-shadow: none;
  //   }
  // }
}
</style>
