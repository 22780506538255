<template>
  <div>
    <a-row class="align-items-center mb-4 justify-items-center d-flex sticky-top"
      style="margin: -30px; top: 64px; padding: 0 30px 22px 30px; background: #fff">
      <a-col :span="18">
        <div class="d-flex flex-column">
          <a-breadcrumb>
            <a-breadcrumb-item><a style="color: #1890FF" @click="redirectToRoute">{{ routeTitle }}</a></a-breadcrumb-item>
            <a-breadcrumb-item v-if="isViewscreen"><a style="color: rgba(0, 0, 0, 0.45)" href="javascript:;">{{ viewscreenName }}</a></a-breadcrumb-item>
            <a-breadcrumb-item><a style="color: rgba(0, 0, 0, 0.45)" href="javascript:;">{{ informerTitle }}</a></a-breadcrumb-item>
          </a-breadcrumb>
          <div class="d-flex align-items-end mt-2">
            <h3 class="mb-0 mt-2 mr-4" style="font-size: 20px">{{ informerTitle }}</h3>
            <a-switch v-model="form.isActive" />
          </div>
        </div>
      </a-col>
      <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end">
        <div v-if="isEditMode">
          <a-popconfirm title="Sure to delete?" placement="bottom" @confirm.stop="deleteInformer">
            <a-button :loading="loading" type="danger"> Delete </a-button>
          </a-popconfirm>
          <a-button :loading="loading" type="primary" class="ml-4" @click="save">
            Save
          </a-button>
        </div>
        <div v-else class="pt-4">
          <a-button class="ml-4" @click="clearForm">
            Clear
          </a-button>
          <a-button :loading="loading" type="primary" class="ml-4" @click="save">
            Save
          </a-button>
        </div>
      </a-col>
    </a-row>
    <div style="padding: 24px; background-color: #fff">
      <a-form-model :rules="rules" :model="form" ref="form">
        <div class="d-flex flex-column informer-form">
          <span class="font-weight-bold font-size-16">General</span>
          <a-row>
            <a-col :span="7">
              <a-form-model-item label="Name" prop="informerName" :colon="false" style="max-width: 427px;">
                <a-input v-model="form.informerName" placeholder="Name" size="large" :disabled="loading" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <div class="d-flex flex-row align-items-start" style="gap: 1rem">
            <a-form-model-item label="Start date" prop="startDate" :colon="false" class="d-inline-block pr-1">
              <a-date-picker v-model="form.startDate" show-time format="YYYY-MM-DD HH:mm:ss" type="date" size="large"
                placeholder="Select date" :disabled="loading" />
            </a-form-model-item>
            <a-form-model-item label="End date" prop="endDate" :colon="false" class="d-inline-block pr-1">
              <a-date-picker v-model="form.endDate" show-time format="YYYY-MM-DD HH:mm:ss" type="date" size="large"
                placeholder="Select date" :disabled="loading" />
            </a-form-model-item>
          </div>
          <a-row>
            <a-col :span="3">
              <a-form-model-item prop="informerTag">
                <template #label>Tag</template>
                <a-select v-model="form.informerTag" size="large" :disabled="loading" allow-clear>
                  <a-select-option v-for="tag in tags" :key="tag.name">
                    <div class="informer-form__tag-select">
                      <component v-if="tag.name !== 'no tag'" :is="tag.icon" />
                      {{ tag.name.charAt(0).toUpperCase() + tag.name.slice(1) }}
                    </div>
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="2">
              <a-form-model-item prop="informerDisplay">
                <template #label><span class="required-label">Display section</span></template>
                <a-select v-model="form.informerDisplays" mode="multiple" size="large" disabled allow-clear style="width: 110px;">
                  <a-select-option v-for="display in displays" :key="display.value">
                    {{ display.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item prop="informerApps" required :colon="false" class="d-block">
            <template #label>
              <span class="mr-4">Apps</span>
              <a @click.stop="openAddAppModal" style="color: rgb(0, 123, 255) !important; font-weight: 400">+ Add</a>
            </template>
            <div v-if="form.informerApps">
              <a-tag style="
                    margin-right: 15px;
                    padding: 1px 8px;
                    border-radius: 2px;
                  " v-for="app in form.informerApps.slice(0, 5)" :key="app.id">
                {{ app.name }}
                <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
                <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
                <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
              </a-tag>
              <a-tag v-if="form.informerApps.length > 4"> ... </a-tag>
            </div>
          </a-form-model-item>
          <a-divider class="m-0 mb-3" />
          <div>
            <div class="d-flex flex-row align-items-center justify-content-between mb-2">
              <span class="font-weight-bold font-size-16 required-label">Media</span>
              <div class="checkbox-container">
                <a-button type="link" :disabled="!isApllyToAllAvailable" @click="addToAllLangs">
                  Apply to all languages
                </a-button>
              </div>
            </div>
            <div>
              <a-tabs v-if="languages.length > 1" :default-active-key="languages[0].name" :active-key="selectedLanguage"
                @change="handleTabChange" :tabBarStyle="{ border: 'none' }" class="mb-2">
                <a-tab-pane v-for="language in languages" :key="language.name" :tab="language.desc" />
              </a-tabs>
              <div class="images mb-4">
                <div class="d-flex flex-column">
                  <span class="mb-2 title">Image</span>
                  <div class="main-container">
                    <div>
                      <a-upload list-type="picture-card" class="uploader main" :show-upload-list="false"
                        @change="handleImageUpload" :disabled="loading">
                        <div>
                          <div v-if="selectedImage.url" class="informer-image">
                            <img :src="selectedImage.url" />
                          </div>
                          <template v-else>
                            <a-icon :type="selectedImage.loading ? 'loading' : 'inbox'" class="text-primary" style="font-size: 48px" />
                            <div>
                              Click or drag file to this area to upload<br />
                              <div class="text">
                              File size 1160 x 414 px
                            </div>
                            </div>
                          </template>
                        </div>
                      </a-upload>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <a-row class="">
                  <a-col :span="7">
                    <a-form-model-item :colon="false" style="max-width: 427px;">
                      <template #label><span class="required-label">Title</span></template>
                      <a-textarea v-model="selectedInfo.title" placeholder="Enter title"
                        :autoSize="{ minRows: 1, maxRows: 4 }" :showCount="true" :maxLength="100" />
                      <div class="text-count">
                        <span>{{ selectedTitleLength }} / 100</span>
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="8">
                    <a-form-model-item :colon="false" style="max-width: 427px;">
                      <template #label><span class="required-label">Text area</span></template>
                      <a-textarea v-model="selectedInfo.description" placeholder="Enter text"
                        :autoSize="{ minRows: 3, maxRows: 4 }" :showCount="true" :maxLength="250" />
                      <div class="text-count">
                        <span>{{ selectedDescriptionLength }} / 250</span>
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </div>
            </div>
            <div>
              <div class="d-flex flex-row align-items-center mb-2">
                <div>
                  <span class="font-weight-bold font-size-16 mr-3">Redirect</span>
                  <a v-if="!isRedirectAvailable" @click.stop="handleAddRedirect"
                    style="color: rgb(0, 123, 255) !important; font-weight: 400">+
                    Add</a>
                  <a-icon v-else type="delete" @click="handleClearRedirect" style="
                    color: red;
                    cursor: pointer;
                    margin-left: 8px;
                    font-size: 16px;" />
                </div>
              </div>
              <div v-if="isRedirectAvailable" class="mb-4">
                <div class="d-flex flex-column redirect-list">
                  <div class="d-flex flex-row align-items-center mb-2 mr-4" style="gap: 1rem">
                    <a-form-model-item :colon="false">
                      <template #label><span class="required-label">Name</span></template>
                      <a-input placeholder="Name" :disabled="loading" v-model="redirect.buttonName"
                        style="max-width: 200px" size="large">
                        <template #placeholder><span style="color: #a1a1c2">Name</span></template>
                      </a-input>
                    </a-form-model-item>
                    <a-form-model-item prop="buttonColor" :colon="false">
                      <template #label><span>Button color</span></template>
                      <a-input placeholder="#112233" :disabled="loading" v-model="redirect.fontColor"
                        style="max-width: 200px" size="large">
                      </a-input>
                    </a-form-model-item>
                    <!-- Font color and button color reversed is not a bug -->
                    <a-form-model-item prop="fontColor" :colon="false">
                      <template #label><span>Font color</span></template>
                      <a-input placeholder="#112233" :disabled="loading" v-model="redirect.buttonColor"
                        style="max-width: 200px" size="large">
                      </a-input>
                    </a-form-model-item>
                  </div>
                  <div class="d-flex flex-row align-items-end mb-2 mr-4" style="gap: 1rem">
                    <a-form-model-item>
                      <template #label><span class="required-label">Link type</span></template>
                      <a-select v-model="redirect.linkType" size="large" :disabled="loading" allow-clear>
                        <template #placeholder><span style="color: #a1a1c2">Select link type</span></template>
                        <a-select-option value="custom"> Custom </a-select-option>
                        <a-select-option value="sport"> Sport </a-select-option>
                        <a-select-option value="match"> Match </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <div v-if="redirect.linkType === 'custom'" class="d-flex flex-ro align-items-end">
                      <a-form-model-item :colon="false" class="mr-3" style="min-width: 416px">
                        <template #label><span class="required-label">Link</span></template>
                        <a-input placeholder="https://www.olimp.bet/line/1/5956496/78487617" :disabled="loading"
                          v-model="redirect.link" size="large">
                        </a-input>
                      </a-form-model-item>
                      <!-- TODO WIDTH -->
                      <a-button type="primary" size="large" class="mr-4" :disabled="loading"
                        @click="openAddLinkTemplatesModal">
                        Create new
                      </a-button>
                      <a-form-model-item label="Select template">
                        <a-select v-model="redirect.id" @change="changeRedirectTemplate(redirect.id)" size="large"
                          :disabled="loading" allow-clear>
                          <template #placeholder><span style="color: #a1a1c2">Template</span></template>
                          <a-select-option v-for="linkTemplate in linkTemplates" :key="linkTemplate.id">
                            {{ linkTemplate.name }}
                            <span style="color: #a1a1c2;">[{{ linkTemplate.link }}]</span>
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </div>
                    <a-form-model-item v-if="redirect.linkType === 'match'" :colon="false" class="mr-3"
                      style="min-width: 416px">
                      <template #label><span class="required-label">Match link</span></template>
                      <a-input placeholder="https://www.olimp.bet/line/1/5956496/78487617" :disabled="loading"
                        v-model="redirect.link" size="large">
                      </a-input>
                    </a-form-model-item>
                    <a-form-model-item v-if="redirect.linkType === 'sport'">
                      <template #label><span class="required-label">Select sport</span></template>
                      <a-select v-model="redirect.sportId" size="large" :disabled="loading" allow-clear>
                        <template #placeholder><span style="color: #a1a1c2;">Select sport</span></template>
                        <a-select-option v-for="sport in sports" :key="sport.sport_id">
                          {{ sport.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
      <div v-if="errors.oneFullInformer" style="color: red; margin-top: 8px;">At least one language should have both title and description</div>
      <div v-if="errors.bothTitleAndDescription" style="color: red; margin-top: 8px">Can't create informer with title but without description and vice versa</div>
      <div v-if="errors.onlyImage" style="color: red; margin-top: 8px">Can't create informer with image but without title and description</div>
      <div v-if="errors.isRedirectValid" style="color: red; margin-top: 8px">Redirect must have name, link type and link / sport itself</div>
      <div v-if="errors.redirectColorLength" style="color: red; margin-top: 8px">Redirect color inputs can't be longer than 7 chars</div>
    </div>
    <a-modal v-model="addAppsModal" title="Add new apps" :footer="null" width="700px" :destroyOnClose="true">
      <AppsModal @selectApps="changeSelectedApps" @close="closeAddAppsModal" :modalAppsData="modalAppsData" />
    </a-modal>
    <a-modal v-model="addLinkTemplateModal" width="395px" :destroyOnClose="true" @ok="handleAddTemplatesModal">
      <template #title><span class="modal-title">Add new template</span></template>
      <a-form-model :model="addTemplateForm" ref="templateForm" :rules="templateRules">
        <a-form-model-item label="Name" prop="name" :colon="false">
          <a-input placeholder="Name" :disabled="loading" size="large" v-model="addTemplateForm.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Link" prop="link" :colon="false">
          <a-input placeholder="https://www.olimp.bet/line/1/5956496/7848761" :disabled="loading" size="large"
            v-model="addTemplateForm.link"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import AppsModal from '@/components/custom/modals/appsModal.vue'
import NewIcon from '@/components/custom/icons/informers/newIcon.vue'
import PromoIcon from '@/components/custom/icons/informers/promoIcon.vue'
import TopIcon from '@/components/custom/icons/informers/topIcon.vue'
import TechIcon from '@/components/custom/icons/informers/techIcon.vue'
import MaintenanceIcon from '@/components/custom/icons/informers/maintenanceIcon.vue'

import ViewscreenService from '@/services/api/apiViewscreenService'
import informerService from '@/services/api/apiInformersService'
import apiClient from '@/services/axios'

const initialForm = {
  informerName: '',
  isActive: true,
  startDate: null,
  endDate: null,
  informerTag: null,
  informerDisplays: [4],
  informerApps: [],
}
const initialRedirect = {
  buttonName: '',
  buttonColor: '',
  fontColor: '',
  linkType: '',
  linkName: '',
  sportId: '',
  link: '',
  id: '',
}

const defaultInformerImg = {
  id: null,
  url: '',
  file: null,
  loading: false,
}
const defaultInformerInfo = {
  title: '',
  description: '',
}

function getImageDimensions(file) {
  return new Promise((resolve) => {
    const img = document.createElement('img')
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      })
    }
  })
}

function getBase64(img) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.readAsDataURL(img)
  })
}

export default {
  name: 'informersForm',
  components: { AppsModal, NewIcon, PromoIcon, TopIcon, TechIcon, MaintenanceIcon },
  data() {
    return {
      form: { ...initialForm },
      redirect: { ...initialRedirect }, // TODO []
      content: {
        ru: {
          informerImg: {
            ...defaultInformerImg,
          },
          informerInfo: {
            ...defaultInformerInfo,
          },
        },
      },
      errors: {
        isRedirectValid: false,
        redirectColorLength: false,
      },
      tags: [
        { name: 'new', icon: 'NewIcon' },
        { name: 'promo', icon: 'PromoIcon' },
        { name: 'top', icon: 'TopIcon' },
        { name: 'tech', icon: 'TechIcon' },
        { name: 'maintenance', icon: 'MaintenanceIcon' },
        { name: 'no tag' },
      ],
      displays: [
        { label: 'Live', value: 1 },
        { label: 'Promo', value: 3 },
        { label: 'Main', value: 4 },
      ],
      loading: false,
      addAppsModal: false,
      languages: ['ru'],
      selectedLanguage: 'ru',
      linkTemplates: [],
      sports: [],
      addTemplateForm: {
        name: '',
        link: '',
      },
      isRedirectAvailable: false,
      checkAllLangs: false,
      addLinkTemplateModal: false,
      informerToEdit: null,
      viewscreenName: '',
      rules: {
        informerName: [
          {
            required: true,
            message: 'Informer name is required',
            trigger: 'blur',
          },
        ],
        startDate: [
          {
            required: true,
            message: 'Please pick start date',
            trigger: 'change',
          },
        ],
        informerTag: [
          {
            required: true,
            message: 'Informer tag is required',
            trigger: 'blur',
          },
        ],
        informerDisplays: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one section',
            trigger: 'change',
          },
        ],
        informerApps: [
          {
            type: 'array',
            required: true,
            message: 'Applications are required',
            trigger: 'change',
          },
        ],
      },
      templateRules: {
        name: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
        link: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
      },
      modalAppsData: null,
    }
  },
  computed: {
    isEditMode() {
      return !!this.informerToEdit
    },
    routeTitle() {
      return this.isViewscreen ? 'Main' : 'Informers'
    },
    informerTitle() {
      return this.isEditMode ? 'Edit informer' : 'New informer'
    },
    selectedContent() {
      return this.content[this.selectedLanguage]
    },
    selectedInfo() {
      return this.selectedContent.informerInfo
    },
    selectedTitleLength() {
      if (this.selectedInfo.title) {
        return this.selectedInfo.title.length
      }
      return 0
    },
    selectedDescriptionLength() {
      if (this.selectedInfo.description) {
        return this.selectedInfo.description.length
      }
      return 0
    },
    selectedImage () {
      return this.selectedContent.informerImg
    },
    isApllyToAllAvailable() {
      if (this.checkIsLangEmpty(this.selectedContent)) {
        return false
      }

      const nonSelectedLanguages = Object.keys(this.content).filter(
        (language) => language !== this.selectedLanguage,
      )
      const emptyLanguagesCount = nonSelectedLanguages.filter((currentLang) =>
        this.checkIsLangEmpty(currentLang),
      ).length
      return emptyLanguagesCount === this.languages.length - 1
    },
    isViewscreen() {
      if (this.$route.params.viewscreenId || this.$route.params.sectionId) {
        return true
      }
      return false
    },
  },
  async created() {
    await this.fetchLocalizations()
    await this.fetchSports()
    await this.fetchLinkTemplates()

    if (this.$route.params.id) {
      this.informerToEdit = await this.getInformer()
      this.fillForm()
    }

    if (this.isViewscreen) {
      this.fetchViewscreenTab()
    }

    if (!this.informerToEdit) {
      this.selectedLanguage = this.languages[0].name
      this.initializeContent()
    }
  },
  methods: {
    async save() {
      const isValid = await this.validateForm()
      if (!isValid) {
        return false
      }
      if (!this.isEditMode) {
        return this.createInformer()
      }
      this.updateInformer()
    },
    async validateForm() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })

      let atLeastOneValid = false
      let allAreValid = true
      let onlyImage = false

      for (const lang in this.content) {
        const currentLang = this.content[lang]
        const isImgEmpty = currentLang.informerImg.url === ''
        const isTitleEmpty = currentLang.informerInfo.title === ''
        const isDescEmpty = currentLang.informerInfo.description === ''

        // Only image
        if (!isImgEmpty && isTitleEmpty && isDescEmpty) {
          onlyImage = true
        }

        // Check if there is any invalid tab (either title or description is missing but not both)
        if ((!isTitleEmpty && isDescEmpty) || (isTitleEmpty && !isDescEmpty)) {
          allAreValid = false
        }

        // Check if at least one language has both title and description
        if (!isTitleEmpty && !isDescEmpty) {
          atLeastOneValid = true
        }
      }

      let isRedirectValid = true
      let isInvalidColorLength = false

      if (this.isRedirectAvailable) {
        const { buttonName, buttonColor, fontColor, link, linkType, sportId } = this.redirect
        const fieldsToValidate = {
          buttonName,
          ...(fontColor.length && { fontColor }),
          ...(buttonColor.length && { buttonColor }),
          ...(linkType !== 'sport' && { link }),
          ...(linkType === 'sport' && { sportId }),
        }
        const errorResults = Object.values(fieldsToValidate)
        isRedirectValid = !errorResults.includes('') && !errorResults.includes(undefined)
        if (fieldsToValidate.buttonColor || fieldsToValidate.fontColor) {
          isInvalidColorLength = buttonColor.length > 7 || fontColor.length > 7
        }
      }
      const errors = {
        oneFullInformer: !atLeastOneValid,
        bothTitleAndDescription: !allAreValid,
        onlyImage: onlyImage,
        isRedirectValid: !isRedirectValid,
        redirectColorLength: isInvalidColorLength,
      }
      this.errors = { ...errors }
      if (!isValid || Object.values(this.errors).includes(true)) {
        return false
      }
      return true
    },
    async validateUploadedImage(file) {
      const isImage = file.type.split('/')[0] === 'image'
      const fileExtension = file.type.split('/')[1]

      if (!isImage) {
        this.$notification.error({
          message: 'You can only upload WEBP / PNG file!',
        })
        return false
      }

      const isLt5M = file.size / 1024 / 1024 < 10

      if (!isLt5M) {
        this.$notification.error({
          message: 'Informer image must be smaller than 10MB!',
        })
        return false
      }

      if (isImage) {
        const { width, height } = await getImageDimensions(file)
        if (width !== 1160 || height !== 414) {
          this.$notification.error({
            message: 'Informer image must be 1160 x 414px!',
          })
          return false
        }
        if (fileExtension !== 'png' && fileExtension !== 'webp') {
          this.$notification.error({
            message: 'Informer image must have PNG / WEBP extension!',
          })
          return false
        }
      }

      return file
    },
    addToAllLangs() {
      for (const language in this.content) {
        const currentLang = this.content[language]
        if (language !== this.selectedLanguage) {
          currentLang.informerInfo = { ...this.selectedContent.informerInfo }
          currentLang.informerImg = { ...this.selectedContent.informerImg }
        }
      }
    },
    checkIsLangEmpty(currentLang) {
      const currentContent = this.content[currentLang]
      if (typeof currentContent === 'undefined') {
        return false
      }
      const currentLangEmpty = currentContent.informerImg.id === null && currentContent.informerInfo.description === '' && currentContent.informerInfo.title === ''

      return currentLangEmpty
    },
    initializeContent() {
      this.content = this.languages.reduce((acc, language) => {
        return {
          ...acc,
          [language.name]: {
            informerImg: { ...defaultInformerImg },
            informerInfo: { ...defaultInformerInfo },
          },
        }
      }, {})
    },
    handleTabChange(selectedLangKey) {
      if (this.loading) {
        return
      }
      this.selectedLanguage = selectedLangKey
    },
    handleAddRedirect() {
      this.isRedirectAvailable = true
    },
    handleClearRedirect() {
      this.isRedirectAvailable = false
      Object.keys(this.redirect).forEach(key => {
        this.redirect[key] = ''
      })
    },
    changeRedirectTemplate(linkId) {
      const searchTemplate = this.linkTemplates.find((template) => {
        return template.id === linkId
      })

      this.redirect.link = searchTemplate.link
      this.redirect.name = searchTemplate.name
    },

    async handleImageUpload(event) {
      if (event.file.status === 'uploading') {
        return
      }

      const file = event.file

      const isFileValid = await this.validateUploadedImage(
        event.file.originFileObj,
      )

      if (!isFileValid) {
        return
      }

      const fileId = file.uid

      this.content[this.selectedLanguage].informerImg = {
        ...this.selectedImage,
        loading: true,
        id: fileId,
        file,
      }

      const formData = new FormData()

      formData.append('cover', file.originFileObj)

      try {
        const informerResponse = await informerService.uploadInformerImage(file)
        const previewId = informerResponse.data?.data?.id
        if (!previewId) {
          const errorMessage =
          informerResponse.data?.error?.message ||
            'Something went wrong while uploading informer image'
          this.content[this.selectedLanguage].informerImg = {
            ...defaultInformerImg,
          }
          this.$notification.error({
            message: errorMessage,
          })
        }
        this.content[this.selectedLanguage].informerImg.id = previewId
        this.content[this.selectedLanguage].informerImg.url = await getBase64(
          event.file.originFileObj,
        )
        this.content[this.selectedLanguage].informerImg.loading = false
      } catch (e) {
        const errorMessage =
          e.response.data?.error?.message ||
          'Something went wrong while uploading the preview'
        this.content[this.selectedLanguage].informerImg = {
          ...defaultInformerImg,
        }
        this.$notification.error({
          message: errorMessage,
        })
      }
    },
    fillForm() {
      this.form.informerName = this.informerToEdit.name
      this.form.informerApps = this.informerToEdit.apps
      this.form.informerTag = this.informerToEdit.tag === 'no_tag' ? 'no tag' : this.informerToEdit.tag
      this.modalAppsData = this.informerToEdit.apps
      this.form.startDate = this.$moment(this.informerToEdit.start_date).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss')
      this.informerToEdit.end_date ? this.form.endDate = this.$moment(this.informerToEdit.end_date).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss') : this.form.end_date = null
      this.form.isActive = this.informerToEdit.is_active
      this.form.informerDisplays = this.informerToEdit.displays.map((display) => {
        return display.id
      })
      if (this.informerToEdit.redirect) {
        this.isRedirectAvailable = true
        this.redirect.buttonName = this.informerToEdit.button_name
        this.redirect.buttonColor = this.informerToEdit.button_color
        this.redirect.fontColor = this.informerToEdit.button_font_color
        this.redirect.linkType = this.informerToEdit.redirect.type
        if (this.informerToEdit.redirect.type === 'custom') {
          this.redirect.id = this.informerToEdit.redirect.id
          this.redirect.link = this.informerToEdit.redirect.link
          this.redirect.linkName = this.informerToEdit.redirect.link
        }
        if (this.informerToEdit.redirect.type === 'match') {
          this.redirect.link = this.informerToEdit.redirect.link
        }
        if (this.informerToEdit.redirect.type === 'sport') {
          this.redirect.sportId = this.informerToEdit.redirect.sport.sport_id
        }
      }
      const informerLanguages = Object.keys(this.informerToEdit.localizations)
      this.selectedLanguage = informerLanguages[0]
      const informerContent = informerLanguages.reduce((acc, language) => {
        return {
          ...acc,
          [language]: {
            informerImg: {
              id: this.informerToEdit.localizations[language].cover_id,
              url: this.informerToEdit.localizations[language].path,
            },
            informerInfo: {
              title: this.informerToEdit.localizations[language].title,
              description: this.informerToEdit.localizations[language].description,
            },
          },
        }
      }, {})
      const defaultContent = this.languages.reduce((acc, language) => {
        return {
          ...acc,
          [language.name]: {
            informerImg: { ...defaultInformerImg },
            informerInfo: { ...defaultInformerInfo },
          },
        }
      }, {})

      this.content = {
        ...defaultContent,
        ...informerContent,
      }
    },
    async addInformerSectionItem (informerId) {
      this.loading = true
      try {
        const response = await ViewscreenService.addViewscreenSectionItem(this.$route.params.sectionId, informerId)
        if (response.data.data) {
          this.$notification.success({
            message: 'Informer has been successfully added to the viewscreen section',
          })
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while adding informer to viewscreen section',
          description: e.response.data.error.message,
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    getInformerContentByLanguage() {
      return Object.entries(this.content).reduce((acc, [language, content]) => {
        const { title, description } = content.informerInfo
        const informerInfo = {
          title: title,
          description: description,
          cover_id: content.informerImg.id,
        }
        if (title !== '' && description !== '') {
          return {
            ...acc,
            [language]: informerInfo,
          }
        }
        return acc
      }, {})
    },
    getInformerPayload() {
      const informerPayload = {
        name: this.form.informerName,
        start_date: this.$moment(this.form.startDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss'),
        displays: this.form.informerDisplays,
        is_active: this.form.isActive,
        applications: this.getApplications(),
        button_name: this.redirect.buttonName,
        ...(this.form.informerTag !== 'no tag' && { tag: this.form.informerTag }),
        ...(this.redirect.fontColor && { button_font_color: this.redirect.fontColor }),
        ...(this.redirect.buttonColor && { button_color: this.redirect.buttonColor }),
        ...(this.form.endDate && { end_date: this.$moment(this.form.endDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss') }),
      }
      informerPayload.localizations = this.getInformerContentByLanguage()
      if (this.isRedirectAvailable) {
        informerPayload.redirect = {
          type: this.redirect.linkType,
          ...(this.redirect.linkType !== 'sport' && { link: this.redirect.link }),
          ...(this.redirect.linkType === 'sport' && { sport_id: this.redirect.sportId }),
        }
      }
      return informerPayload
    },
    clearForm() {
      this.form = { ...initialForm }
      this.redirect = { ...initialRedirect }
      this.initializeContent()
    },
    getApplications() {
      return this.form.informerApps.map((app) => {
        return {
          application_id: app.application_id,
          min_app_version: app.min_app_version,
          max_app_version: app.max_app_version,
        }
      })
    },
    async validateApps() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validateField('informerApps', resolve)
      })
      return isValid
    },
    openAddAppModal() {
      if (this.loading) {
        return
      }
      this.addAppsModal = true
    },
    closeAddAppsModal() {
      this.addAppsModal = false
    },
    changeSelectedApps(apps) {
      this.closeAddAppsModal()
      this.form.informerApps = apps
      this.modalAppsData = apps
      this.validateApps()
    },
    openAddLinkTemplatesModal() {
      if (this.loading) {
        return
      }
      this.addLinkTemplateModal = true
    },
    closeAddLinkTemplatesModal() {
      this.addLinkTemplateModal = false
    },
    redirectToRoute() {
      if (this.isViewscreen) {
        return this.redirectToViewscreen()
      }
      return this.redirectToInformers()
    },
    redirectToViewscreen() {
      this.$router.push(`/main/${this.$route.params.viewscreenId}`)
    },
    redirectToInformers() {
      this.$router.push('/informers/informers-list')
    },
    async getInformer() {
      try {
        this.loading = true
        const informerResponse = await informerService.getInformer(this.$route.params.id)
        this.loading = false
        return informerResponse.data.data
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      }
    },
    async createInformer() {
      try {
        this.loading = true
        const informerPayload = this.getInformerPayload()
        const informerResponse = await informerService.createInformer(informerPayload)
        if (informerResponse.data.data) {
          this.$notification.success({
            message: 'Informer successfully created',
          })
          if (this.isViewscreen) {
            await this.addInformerSectionItem(informerResponse.data.data.id)
          }
          this.redirectToRoute()
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      }
    },
    async updateInformer() {
      try {
        this.loading = true
        const informerPayload = this.getInformerPayload()
        const informerResponse = await informerService.updateInformer(this.informerToEdit.id, informerPayload)
        if (informerResponse.data.data) {
          this.$notification.success({
            message: 'Informer successfully updated',
          })
          this.redirectToRoute()
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      }
    },
    async deleteInformer() {
      try {
        const response = await informerService.deleteInformer(this.informerToEdit.id)
        if (response.data.data) {
          this.$notification.success({
            message: 'Informer successfully deleted',
          })
          this.redirectToRoute()
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
      }
    },
    async handleAddTemplatesModal() {
      try {
        this.loading = true
        const templateResponse = await apiClient.post(
          '/admin/links',
          this.addTemplateForm,
        )
        this.linkTemplates.push(templateResponse.data.data)
        this.closeAddLinkTemplatesModal()
        this.$refs.templateForm.resetFields()
        if (templateResponse.data.error) {
          this.loading = false
          this.$notification.error({
            message: 'Error while adding templates',
            description: templateResponse.data.error.message,
          })
          return
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while adding templates',
          description: e.response.data.error.message,
        })
        this.loading = false
      }
      this.loading = false
    },
    async fetchLocalizations() {
      this.languagesLoading = true
      const url = '/admin/localizations'
      try {
        const response = await apiClient.get(url)
        this.languages = response.data.data
      } catch (error) {
        console.log('Error while trying to get locales', error)
      } finally {
        this.languagesLoading = false
      }
    },
    async fetchSports() {
      this.loading = true
      const url = '/admin/sports?results=1000'
      try {
        const response = await apiClient.get(url)
        this.sports = response.data.data
      } catch (error) {
        console.log('Error while trying to get sports', error)
      } finally {
        this.loading = false
      }
    },
    async fetchLinkTemplates() {
      this.loading = true
      const url = '/admin/links?type=custom&results=1000'
      try {
        const response = await apiClient.get(url)
        this.linkTemplates = response.data.data
      } catch (error) {
        console.log('Error while trying to get templates', error)
      } finally {
        this.loading = false
      }
    },
    async fetchViewscreenTab() {
      this.loading = true
      try {
        const response = await ViewscreenService.getViewscreenTab(this.$route.params.viewscreenId, '0')
        if (response.data.data) {
          this.viewscreenName = response.data.data.name
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">

.informer-form {
  gap: 1rem;

  .text {
    color: #8C8C8C;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .images {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
  }

  .uploader {
    border-radius: 2px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: auto;
    position: relative;

    &.main .ant-upload-list-item,
    &.main .ant-upload-list-picture-card-container,
    &.main .ant-upload-select-picture-card {
      width: 328px !important;
      height: 128px !important;
      margin: 0 !important;

      &>span {
        padding: 0;

        &>img {
          width: 374px !important;
          height: 132px !important;
        }
      }
    }
  }

  .informer-image {
    width: 328px;
    height: 128px;

    &>img {
      width: 328px;
      height: 128px;
    }
  }
}
  &__tag-select{
    display: flex;
    gap: .5rem;
    align-items: center;
  }
}

.redirect-list {
  gap: 1rem;

  .ant-form-item {
    min-width: 200px
  }
}
.modal-title {
  font-size: 16px;
  font-weight: bold;
}

.required-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222e;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.text-count {
  color: #BFBFBF;
  text-align: end;
}
</style>
