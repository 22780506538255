<template>
  <draggable
    tag="tbody"
    v-bind="dragOptions"
    :list="data.dataSource"
    @start="drag = true"
    handle=".handle"
    @end="end"
  >
    <slot></slot>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  inject: ['data'],
  data: () => ({
    drag: false,
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: !this.data,
        ghostClass: 'ghost',
      }
    },
  },
  methods: {
    end() {
      this.$root.$emit('endDraggable')
    },
  },
}
</script>
