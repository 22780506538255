<template>
  <a-form-model>
    <!--a-row>
      <a-col :span="24" class="pr-1">
        <a-form-model-item label="Select section">
          <a-select
            v-model="section"
            placeholder="Select section"
            showArrow
          >
            <a-select-option v-for="section in sections" :key="section.id" @click="selectChange(section)">
              {{ section.localizations[0].title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row-->

    <a-row>
      <a-col :span="8" class="pr-1">
        <a-form-model-item label="Champ ID">
          <a-input :disabled="true" v-model="record.champ_id"></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="16" class="pr-1">
        <a-form-model-item label="Champ Name">
          <a-input :disabled="true" v-model="record.champ_name"></a-input>
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="8" class="pr-1">
        <a-form-model-item label="Sport ID">
          <a-input :disabled="true" v-model="record.sport_id"></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="16" class="pr-1">
        <a-form-model-item label="Sport Name">
          <a-input :disabled="true" v-model="record.sport_name"></a-input>
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="8" class="pr-1">
        <a-form-model-item label="Match ID">
          <a-input :disabled="true" v-model="record.match_id"></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="16" class="pr-1">
        <a-form-model-item label="Match Name">
          <a-input :disabled="true" v-model="record.match_name"></a-input>
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="8" class="pr-1">
        <a-form-model-item label="Points">
          <a-input :disabled="true" v-model="record.weight"></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="16" class="pr-1">
        <a-form-model-item label="Weight">
          <a-input v-model="weight"></a-input>
        </a-form-model-item>
      </a-col>
    </a-row>

    <hr>

    <a-row>
      <a-col class="text-right" :span="24">
        <a-button @click="closeModal">
          Cancel
        </a-button>
        <a-button :loading="submitLoading" type="primary" @click="onSubmit" class="ml-2">
          Add
        </a-button>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import apiClient from '@/services/axios'
export default {
  name: 'matchModal',
  props: ['record', 'sections'],
  data() {
    return {
      weight: 0,
      submitLoading: false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    /* selectChange(section) {
      this.section = section
    }, */
    onSubmit() {
      if (!this.sections || !this.record) {
        return
      }
      this.submitLoading = true
      const url = '/admin/top/' + this.sections.id + '/matches/store'
      apiClient.post(url, {
        weight: this.weight ?? 0,
        sport_id: this.record.sport_id ?? null,
        champ_id: this.record.champ_id ?? null,
        match_id: this.record.match_id ?? null,
        is_live: this.record.is_live ?? null,
      }).then((response) => {
        console.log(response)
        this.submitLoading = false
        this.$notification.success({
          message: 'The match has been added to the top',
          description: '',
        })
        this.$emit('closeModalFunction')
      }).catch(error => {
        console.log(error)
        this.submitLoading = false
        this.$notification.error({
          message: 'Error when adding a match to the top',
          description: error.message,
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
