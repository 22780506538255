<template>
  <div class="user-transactions-table">
      <div>
        <a-table :columns="columns" :data-source="userTransactions" :rowKey="(record, index) => index" :loading="loading"
          :pagination="pagination" @change="changePagination" class="prisma-users__table">
          <template slot="id" slot-scope="text, record">
            <div>
              <span class="mr-2">{{ record.id }}</span>
              <a-tooltip title="Copy to clipboard" placement="right">
                <a href="javascript:;"><a-icon type="copy" class="copy-icon"
                    @click="clipboardHandle(record.id)" /></a>
              </a-tooltip>
            </div>
          </template>
          <template slot="date" slot-scope="text, record">
            <span>{{ formatDate(record.date) }}</span>
          </template>
          <template slot="credited" slot-scope="text, record">
            <span>{{ record.credited ? 'Counted' : 'Not counted' }}</span>
          </template>
        </a-table>
      </div>
  </div>
</template>
<script>

import PrismaService from '@/services/api/apiPrismaService'
import debounce from 'lodash.debounce'

export default {
  name: 'userTransactions',
  props: ['userUUID'],
  components: { },
  data: () => ({
    loading: false,
    userTransactions: null,
    pagination: {
      pageSize: 20,
      current: 1,
      total: 0,
    },
    columns: [
      {
        title: 'Deposit id',
        dataIndex: 'id',
        width: '25%',
        scopedSlots: { customRender: 'id' },
      },
      {
        title: 'Amount',
        dataIndex: 'sum',
        width: '10%',
      },
      {
        title: 'Date',
        dataIndex: 'date',
        scopedSlots: { customRender: 'date' },
        width: '25%',
      },
      {
        title: 'Counted',
        dataIndex: 'credited',
        width: '10%',
        scopedSlots: { customRender: 'credited' },
      },
    ],
  }),
  computed: {
  },
  created() {
    this.getTransactions()
  },
  methods: {
    async getTransactions() {
      this.loading = true

      const { pageSize, current } = this.pagination
      const searchParams = new URLSearchParams()
      searchParams.append('per_page', pageSize)
      searchParams.append('page', current)
      try {
        const response = await PrismaService.getUserTransactions(this.userUUID, searchParams)
        if (response.data.data) {
          this.userTransactions = response.data.data
          this.pagination.total = response.data.meta.total
        }
      } catch (err) {
        this.$notification.error({
          message: 'Something went wrong',
          description: err.response.data.data.message,
        })
      } finally {
        this.loading = false
      }
    },
    tableUpdate: debounce(function() {
      this.pagination.current = 1
      this.getTransactions()
    }, 300),
    changePagination(pagination) {
      this.pagination = pagination
      this.getTransactions()
    },
    clipboardHandle(id) {
      this.$clipboard(id)
      this.$notification.success({
        message: 'Transaction id successfully copied to clipboard',
        description: id.slice(0, 11) + '... ',
      })
    },
    formatDate: function (date) {
      return this.$moment(date).tz('Etc/GMT-3').format('DD.MM.YY HH:mm')
    },
  },
}
</script>
  <style lang="scss">
  </style>
