<template>
  <a-card class="">
    <a-row type="flex" class="olimp-admin-auth pb-4">
      <a-col flex="auto">
        <a-input-search
          v-model="searchValue"
          size="large"
          placeholder="input search text"
          :disabled="!usersDataC.length && !searchValue.length"
          enter-button
          @search="onSearch"
        >
          <a-icon
            v-show="searchValue.length"
            type="close-circle"
            class="close-circle-search"
            slot="suffix"
            @click="clearSearchValue"
          />
        </a-input-search>
      </a-col>
    </a-row>
    <a-table
      :columns="userColumns"
      :data-source="usersDataC"
      :pagination="pagination"
      :row-key="record => record.id"
      :row-selection="{ selectedRowKeys: selectedUserKeys, onChange: onSelectUserChange }"
      :loading="loading"
      @change="handleTableChange"
      class="users-table"
    >
      <span slot="platform" slot-scope="platform, record">
        <a-icon v-if="record.type.toLowerCase() === 'android'" type="android" class="text-success" />
        <a-icon v-if="record.type.toLowerCase() === 'ios'" type="apple" class="text-primary" />
        <a-icon v-if="record.type.toLowerCase() === 'web'" type="global" class="text-default" />
        <a-icon v-else type="minus" />
      </span>
      <span slot="type" slot-scope="type">
        <a-icon v-if="type.toLowerCase() === 'android'" type="android" class="text-success" />
        <a-icon v-if="type.toLowerCase() === 'ios'" type="apple" class="text-primary" />
        <a-icon v-if="type.toLowerCase() === 'web'" type="global" class="text-default" />
        <a-icon v-else type="minus" />
      </span>
      <span slot="phone" slot-scope="phone, record">
        <span v-if="phone && record.phone_last4">
          <span v-if="record.phone_decrypted === true">
            {{ record.phone }}
            <a-tooltip title="Hide phone" placement="bottom">
              <a href="javascript:;">
                <a-icon type="eye" class="text-primary" @click="encryptPhone(record.id)"  />
              </a>
            </a-tooltip>
          </span>
          <span v-else>
            *{{ record.phone_last4 }}
            <a-tooltip title="Show phone" placement="bottom" v-if="decryptionKey">
              <a href="javascript:;" v-if="!record.phone_decrypted">
                <a-icon type="eye-invisible" class="text-primary" @click="decryptPhone(record.id, record.phone)"  />
              </a>
            </a-tooltip>
          </span>
        </span>
        <span v-else class="text-gray-4">Unavailable</span>
      </span>
      <template slot="operation" slot-scope="text, record">
        <!-- <a-tooltip title="Delete user" placement="left"> -->
          <a-popconfirm
            placement="left"
            title="Sure to delete this user?"
            @confirm="() => onDelete(record.id)"
          >
            <a class="font-size-18 text-danger" href="javascript:;"><a-icon type="delete" /></a>
          </a-popconfirm>
        <!-- </a-tooltip> -->
      </template>
    </a-table>
    <div
      class="footer-actions"
      :class="!usersDataC ? 'mt-3' : 'footer-actions__mt'"
    >
      <div class="footer-actions__container">
        <a-button
          size="large"
          :loading="false"
          :disabled="!hasSelectedUsers"
          class="mr-2"
          :class="{'text-primary' : hasSelectedUsers}"
          @click="transferToAccepted"
        >
          <a-icon type="user-add" />
          <span :class="{'text-gray-6': hasSelectedUsers}">Transfer to Whitelist</span>
        </a-button>
        <a-popconfirm
          placement="top"
          title="Sure to delete this user?"
          @confirm="() => onMassDelete()"
        >
          <a-button
            size="large"
            :loading="false"
            :disabled="!hasSelectedUsers"
            :class="{'text-danger' : hasSelectedUsers}"
          >
            <a-icon type="delete" />
            <span :class="{'text-gray-6': hasSelectedUsers}">Delete</span>
          </a-button>
        </a-popconfirm>
      </div>
    </div>
  </a-card>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'

const userColumns = [
  {
    title: 'Phone',
    dataIndex: 'phone',
    width: '30%',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    width: '30%',
    scopedSlots: { customRender: 'platform' },
  },
  {
    title: 'Bundle ID',
    dataIndex: 'bundle_id',
    width: '30%',
    scopedSlots: { customRender: 'bundle_id' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    key: 'operation',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  components: {},
  mixins: [accessMix],
  watch: {
    pagination(paginationData) {
      this.$emit('totalUsersRequests', paginationData.total)
    },
    searchValue(newVal, oldVal) {
      if (!newVal.length && oldVal.length) {
        this.fetch({ phone: this.searchValue })
      }
    },
  },
  computed: {
    ...mapState(['user']),
    usersDataC() {
      return this.usersData
    },
    hasSelectedUsers() {
      return this.selectedUserKeys.length > 0
    },
    decryptionKey() {
      return !!sessionStorage.getItem('app.encryption.privatekey')
    },
  },
  data() {
    return {
      usersData: [],
      pagination: { pageSize: 25 },
      loading: false,
      userColumns,
      selectedUserKeys: [],
      searchValue: '',
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    onSelectUserChange(selectedUserKeys) {
      this.selectedUserKeys = selectedUserKeys
    },
    clipboardMessage(field, v) {
      this.$notification.success({
        message: field + ' successfully copied to clipboard',
        description: v,
      })
    },
    clipboardFcmHandle(v) {
      this.$clipboard(v)
      this.clipboardMessage('FCM Token', v.slice(0, 8) + '...')
    },
    clipboardCustomerHandle(v) {
      this.$clipboard(v)
      this.clipboardMessage('Customer ID', v.slice(0, 8) + '...')
    },
    onSearch() {
      const customFieldSearchLength = 2
      if (this.searchValue.length < customFieldSearchLength) {
        this.$message.error(`Search value of this field must contain at least ${customFieldSearchLength} characters`)
      } else {
        this.fetch({ phone: this.searchValue })
      }
    },
    clearSearchValue() {
      this.searchValue = ''
      this.fetch({ phone: this.searchValue })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        phone: this.searchValue,
      })
    },
    fetch(params = {}) {
      this.loading = true
      const reqData = { results: 25, ...params }
      const url = '/admin/accounts/requested'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = Number(response.data.meta.total)
        this.loading = false
        this.usersData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    decryptPhone(key, value) {
      const decKey = sessionStorage.getItem('app.encryption.privatekey')
      if (!decKey) {
        this.$message.error('Decryption key not found in session storage')
        return
      }
      const target = this.usersData.filter(item => key === item.id)[0]
      const dec = this.$decryptData(decKey, value)
      if (dec) {
        target.phone_decrypted = true
        target.phone_orig = value
        target.phone = dec
      } else {
        this.$message.error('Data decryption failed due to invalid key or data content')
      }
    },
    encryptPhone(key) {
      const target = this.usersData.filter(item => key === item.id)[0]
      target.phone = target.phone_orig
      target.phone_decrypted = false
    },
    clearSelectedUserKeys() {
      this.selectedUserKeys = []
    },
    transferToAccepted() {
      const url = '/admin/accounts/requested/mass'
      apiClient.post(url, this.selectedUserKeys).then((response) => {
        this.$notification.success({
          message: 'Request successfully accepted',
          description: '',
        })
        this.clearSelectedUserKeys()
        this.fetch()
        this.$emit('getWhitelist')
      }).catch(error => {
        this.$notification.error({
          message: 'Error while accepted request',
          description: error.message,
        })
      })
    },
    onDelete(key) {
      const url = `/admin/accounts/requested/${key}`
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'Request successfully deleted',
          description: '',
        })
        this.usersData.splice(this.usersData.findIndex(user => user.id === key), 1)
        this.clearSelectedUserKeys()
        this.pagination.total -= 1
        this.$emit('totalUsersRequests', this.pagination.total)
      }).catch(error => {
        this.$notification.error({
          message: 'Error while deleting request',
          description: error.message,
        })
      })
    },
    onMassDelete() {
      const url = '/admin/accounts/requested/mass'
      apiClient.delete(url, { params: this.selectedUserKeys }).then((response) => {
        this.$notification.success({
          message: 'Request successfully deleted',
          description: '',
        })
        this.clearSelectedUserKeys()
        this.fetch()
      }).catch(error => {
        this.$notification.error({
          message: 'Error while deleting request',
          description: error.message,
        })
      })
    },
  },
}
</script>

<style lang="scss">
@media (max-width: 1600px) {
  .users-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.9em;
    }
  }
}
@media (max-width: 1500px) {
  .users-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.8em;
    }
  }
}

.users-content .ant-select-selection__placeholder {
  opacity: .25;
}
</style>
