<template>
  <div>
    <div v-if="show" class="readonly">
      <span v-if="title" class="readonly__title" :class="{'required' : required}">{{ title }}</span>
      <span v-if="getValue" class="readonly__description">{{ getValue }}</span>
    </div>
    <slot v-else />
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isDate: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String, Object, null],
      default: null,
    },
  },
  computed: {
    getValue() {
      if (this.isDate) {
        return this.value.format('YYYY-MM-DD HH:mm:ss')
      }
      return this.value
    },
  },
}
</script>

<style lang="scss">
.readonly {
  display: flex;
  flex-direction: column;
  gap: 14px;
  &__title {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #262626;
  }
  &__description {
    color: #9e9e9e;
    font-size: 16px;
  }
}
</style>
