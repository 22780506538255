import apiClient from '@/services/axios'
import axios from 'axios'

const prismaURL = 'https://prisma.olimp.work'
const prismaAuth = process.env.VUE_APP_PRISMA_API_AUTH

const prismaApi = axios.create({
  baseURL: prismaURL,
  headers: { authorization: prismaAuth },
})

class PrismaService {
  async getPrismaUsers(searchParams) {
    const url = `/admin/prisma/users-info?${searchParams}`
    return apiClient.get(url)
  }

  async getUserTransactions(uuid, searchParams) {
    const url = `/admin/prisma/users-info/${uuid}/deposits?${searchParams}`
    return apiClient.get(url)
  }

  async getUserBets(uuid, searchParams) {
    const url = `/admin/prisma/users-info/${uuid}/bets?${searchParams}`
    return apiClient.get(url)
  }

  async getUserTasks(uuid, searchParams) {
    const url = `/admin/prisma/users-info/${uuid}/tasks?${searchParams}`
    return apiClient.get(url)
  }

  async getUserTasksDetails(uuid, userTaskId, taskId, searchParams) {
    const url = `/admin/prisma/users-info/${uuid}/tasks/${userTaskId}/details/${taskId}?${searchParams}`
    return apiClient.get(url)
  }

  async getUserPrizes(searchParams) {
    const url = `/api/admin/prizes/spec?${searchParams}`
    return prismaApi.get(url)
  }

  async transferPrize(prizeId, data) {
    const url = `${prismaURL}/api/admin/prizes/spec/${prizeId}/transfer`
    return prismaApi.patch(url, data)
  }

  async cancelPrize(prizeId, data) {
    const url = `${prismaURL}/api/admin/prizes/spec/${prizeId}/cancel`
    return prismaApi.patch(url, data)
  }

  async getPrizesLogs(prizeId) {
    const url = `${prismaURL}/api/admin/prizes/${prizeId}/history`
    return prismaApi.get(url)
  }
}
export default new PrismaService()
