import apiClient from '@/services/axios'

class TopMatchesService {
  async getMatches() {
    const url = '/admin/top/matches?pretty=true'
    return apiClient.get(url)
  }

  async getLogs(recordId) {
    const url = `/admin/top/matches/${recordId}/logs`
    return apiClient.get(url)
  }

  async createMatch(data) {
    const url = '/admin/top/matches/store-url'
    return apiClient.post(url, data)
  }

  async updateMatch(recordId, data) {
    const url = '/admin/top/matches/' + recordId
    return apiClient.patch(url, data)
  }

  async deleteMatch(recordId) {
    const url = '/admin/top/matches/' + recordId
    return apiClient.delete(url)
  }

  async parseMatchUrl(matchUrl) {
    const url = '/admin/top/matches/check-url'
    const data = { url: matchUrl }
    return apiClient.post(url, data)
  }

  async deleteOldMatches() {
    const url = '/admin/top/matches/old'
    return apiClient.delete(url)
  }
}
export default new TopMatchesService()
