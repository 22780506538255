<template>
  <div class="olimp-admin-form accountModal">
    <div class="account-info" v-if="accountObj">
      <div>
        <h6 class="text-gray-5 font-weight-bold mb-3">Personal information</h6>
        <a-row>
          <a-col :span="8">
            <div>
              <label class="d-block">Login</label>
              <span>{{ accountObj.login }}</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              <label class="d-block">Phone</label>
              <span v-if="accountObj.phone && accountObj.phone_last4">
                <span v-if="decryptedData.phone">
                  {{ decryptedData.phone }}
                  <a href="javascript:;">
                    <a-icon type="eye" class="text-primary" @click="encryptField('phone')"  />
                  </a>
                  <a-tooltip title="Copy to clipboard" placement="bottom">
                    <a href="javascript:;" class="ml-2"><a-icon type="copy" class="text-primary" @click="clipboardHandle(decryptedData.phone, 'Phone')" /></a>
                  </a-tooltip>
                </span>
                <span v-else>
                  *{{ accountObj.phone_last4 }}
                  <a href="javascript:;" v-if="decryptionKey && !decryptedData.phone">
                    <a-icon type="eye-invisible" class="text-primary" @click="decryptField('phone', accountObj.phone)"  />
                  </a>
                </span>
              </span>
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              <label class="d-block">Email</label>
              <span v-if="accountObj.email">
                <span v-if="decryptedData.email">
                  {{ decryptedData.email }}
                  <a href="javascript:;">
                    <a-icon type="eye" class="text-primary" @click="encryptField('email')"  />
                  </a>
                  <a-tooltip title="Copy to clipboard" placement="bottom">
                    <a href="javascript:;" class="ml-2"><a-icon type="copy" class="text-primary" @click="clipboardHandle(decryptedData.email, 'Email')" /></a>
                  </a-tooltip>
                </span>
                <span v-else>
                  {{ accountObj.email.slice(0,24) }}...
                  <a href="javascript:;" v-if="decryptionKey && !decryptedData.email">
                    <a-icon type="eye-invisible" class="text-primary" @click="decryptField('email', accountObj.email)"  />
                  </a>
                </span>
              </span>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="mt-4">
        <h6 class="text-gray-5 font-weight-bold mb-3">App information</h6>
        <a-row>
          <a-col :span="8">
            <div>
              <label class="d-block">Platform</label>
              <span>
                <a-icon v-if="accountObj.type.toLowerCase() === 'android'" type="android" class="text-success" />
                <a-icon v-if="accountObj.type.toLowerCase() === 'android'" type="android" class="text-primaryt" />
                <a-icon v-if="accountObj.type.toLowerCase() === 'web'" type="global" class="text-default" />
                {{ accountObj.type }}
              </span>
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              <label class="d-block">Bundle ID</label>
              <span>{{ accountObj.bundle_id }}</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              <label class="d-block">App Version</label>
              <span v-if="accountObj.app_v">{{ accountObj.app_v }}</span>
            </div>
          </a-col>
        </a-row>
        <a-row class="mt-3">
          <a-col :span="8">
            <div>
              <label class="d-block">Language</label>
              <span v-if="accountObj.lang">
                <flag :iso="accountObj.lang.short" />
                {{ accountObj.lang.name }}
              </span>
              <span v-else>
                {{ accountObj.lang_id }}
              </span>
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              <label class="d-block">FCM Token</label>
              <span v-if="accountObj.fcm_token && accountObj.fcm_token !== '0'">
                {{ accountObj.fcm_token.slice(0,24) }}...
                <a-tooltip title="Copy to clipboard" placement="bottom">
                  <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardHandle(accountObj.fcm_token, 'FCM Token')" /></a>
                </a-tooltip>
              </span>
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              <label class="d-block">Last Updated</label>
              <span>
                <a-icon type="calendar" />
                {{ $moment(accountObj.updated_at).format('DD.MM.YY HH:mm') }}
              </span>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <hr>
    <div>
      <div class="d-flex">
        <div class="mr-auto">
          <!-- empty -->
        </div>
        <div>
          <a-button v-if="0 === 0" key="submit" type="primary" @click="closeModal">
            OK
          </a-button>
        </div>
      </div>
    </div>
    <div v-if="loading" class="banners-loading-spinner text-center">
      <!--        <a-spin size="large" />-->
      <a-icon type="loading" class="text-primary" />
    </div>
  </div>
</template>

<script>
// import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix'
import apiClient from '@/services/axios'

export default {
  name: 'accountModal',
  props: ['accountLogin'],
  mixins: [accessMix],
  computed: {
    // ---
    decryptionKey() {
      return !!sessionStorage.getItem('app.encryption.privatekey')
    },
  },
  data() {
    return {
      loading: false,
      accountObj: undefined,
      // decryptionKey: !!sessionStorage.getItem('app.encryption.privatekey'),
      decryptedData: {
        phone: null,
        email: null,
      },
    }
  },
  async mounted () {
    await this.getAccount(this.accountLogin)
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    async getAccount(login) {
      this.loading = true
      const url = `/admin/accounts/${login}`
      try {
        const response = await apiClient.get(url)
        this.accountObj = response.data.data
      } catch (error) {
        console.log('Error while trying to get user info', error)
      } finally {
        this.loading = false
      }
    },
    clipboardHandle(v, field) {
      this.$clipboard(v)
      this.$notification.success({
        message: field + ' copied to clipboard',
        description: v.slice(0, 8) + '...',
      })
    },
    decryptField(field, value) {
      const decKey = sessionStorage.getItem('app.encryption.privatekey')
      if (!decKey) {
        this.$message.error('Decryption key not found in session storage')
        return
      }
      const dec = this.$decryptData(decKey, value)
      if (dec) {
        this.decryptedData[field] = dec
      } else {
        this.$message.error('Data decryption failed due to invalid key or data content')
      }
    },
    encryptField(field) {
      this.decryptedData[field] = null
    },
  },
}
</script>
<style lang="scss">
</style>
<style scoped>

</style>
