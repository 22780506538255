import Vue from 'vue'
import Vuex from 'vuex'
// import router from '@/router'
import apiClient from '@/services/axios'
import { notification } from 'ant-design-vue'
// import handlerLangsData from '@/services/langs/HandlerLangsData'

Vue.use(Vuex)

export default {
  // namespaced: true,
  state: {
    langData: [],
    cacheLangData: [],
    url: '/admin/localizations',
  },
  mutations: {
    SET_LANG_DATA(state, payload) {
      state.langData = payload
    },
    SET_CACHE_LANG(state) {
      state.cacheLangData = state.langData.map(item => ({ ...item }))
    },
    SET_ADD_LANG(state, payload) {
      state.langData = [...state.langData, payload]
    },
    SET_DELETE_LANG(state, payload) {
      console.log(state.langData, 'before delete')
      state.langData = state.langData.filter(item => item.id !== payload)
      console.log(state.langData, 'after delete')
    },
    SET_LANGS(state, payload) {
      state.langData = payload
    },
    SET_CACHE_LANGS(state, payload) {
      state.langData = payload
    },
  },
  actions: {
    GET_LANGS({ commit, state }) {
      apiClient.get(state.url).then((response) => {
        commit('SET_LANG_DATA', response.data.data)
        commit('SET_CACHE_LANG')
      }).catch(error => { console.log(error) })
    },
    ADD_LANG({ commit, state }, payload) {
      apiClient.post(state.url, payload).then((response) => {
        notification.success({
          message: '"' + response.data.data.desc + '" language successfully created',
          description: '',
        })
        commit('SET_ADD_LANG', response.data.data)
        commit('SET_CACHE_LANG')
      }).catch(error => {
        console.log(error)
        notification.error({
          message: 'Error while creating new language',
          description: error.message,
        })
      })
    },
    DELETE_LANG({ commit, state }, payload) {
      const dataSource = [...state.langData]
      const target = dataSource.filter(item => payload === item.id)[0]
      const url = state.url + '/' + payload
      apiClient.delete(url).then(() => {
        notification.success({
          message: 'Language "' + target.desc + '" successfully deleted',
          description: '',
        })
        commit('SET_DELETE_LANG', payload)
        commit('SET_CACHE_LANG')
      }).catch(error => {
        console.log(error)
        notification.error({
          message: 'Error while deleting language',
          description: error.message,
        })
      })
    },
    SAVE_LANG({ commit, state }, payload) {
      const newData = [...state.langData]
      const newCacheData = [...state.cacheLangData]
      const target = newData.filter(item => payload === item.id)[0]
      const targetCache = newCacheData.filter(item => payload === item.id)[0]
      if (target && targetCache) {
        const url = state.url + '/' + target.id
        return apiClient.patch(url, target).then((response) => {
          notification.success({
            message: '"' + response.data.data.desc + '" language successfully updated',
            description: '',
          })
          delete target.editable
          commit('SET_LANGS', newData)
          Object.assign(targetCache, target)
          commit('SET_CACHE_LANGS', newCacheData)
        }).catch(error => {
          console.log(error)
          notification.error({
            message: 'Error while updating language',
            description: error.message,
          })
        })
      }
    },
    EDIT_LANG({ commit, state }, payload) {
      const newData = [...state.langData]
      const target = newData.filter(item => payload === item.id)[0]
      if (target) {
        target.editable = true
        commit('SET_LANGS', newData)
      }
    },
    CANCEL_LANG({ commit, state }, payload) {
      const newData = [...state.langData]
      const target = newData.filter(item => payload === item.id)[0]
      if (target) {
        Object.assign(target, state.cacheLangData.filter(item => payload === item.id)[0])
        delete target.editable
        commit('SET_LANGS', newData)
      }
    },
  },
  getters: {
    langs: state => state,
    cacheLangData: state => state,
  },
}
