<template>
  <div>
    <div>Host: {{ rHost }}</div>
    <div>Path: {{ rPath }}</div>
    <div>Method: {{ rMethod.toUpperCase() }}</div>
    <div>Query: {{ rQuery }}</div>
    <div>
      Headers:
      <vue-json-pretty :data="JSON.parse(rHeaders)" virtual="true" deep="2" height="250" style="font-size: 12px;" />
    </div>
    <div class="mt-2">
      Content:
      <vue-json-pretty :data="rContent" virtual="true" height="250" style="font-size: 12px;" />
    </div>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: 'modelLogRequestModal',
  props: ['requestInfo'],
  components: {
    VueJsonPretty,
  },
  computed: {
    rContent() {
      try {
        return JSON.parse(JSON.parse(this.requestInfo.content))
      } catch (e) {
        return ''
      }
    },
  },
  data() {
    return {
      rMethod: this.requestInfo.req_method,
      rPath: this.requestInfo.req_url,
      rQuery: this.requestInfo.query,
      rHeaders: this.requestInfo.headers,
      // rContent: this.requestInfo.content,
      rHost: this.requestInfo.host,
    }
  },
}
</script>

<style scoped>

</style>
