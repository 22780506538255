<template>
  <a-row ref="head-section" class="head-section">
    <a-col v-if="title || breadcrumbs.length" :span="10">
      <a-breadcrumb v-if="breadcrumbs.length">
        <a-breadcrumb-item v-for="breadcrumb in breadcrumbs" :key="breadcrumb.name">
          <a
            v-if="breadcrumb.link"
            :class="viewPermissions ? 'head-section__breadcrumb__active' : 'head-section__breadcrumb'"
            @click="breadcrumbClick(breadcrumb.link)"
          >
            {{ breadcrumb.name }}
          </a>
          <span v-else class="head-section__breadcrumb">{{ breadcrumb.name }}</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
      <h3 v-if="title" class="head-section__title">{{ title }}</h3>
    </a-col>
    <slot v-if="editPermissions" name="actions" />
  </a-row>
</template>

<script>
import { mapState } from 'vuex'
import useCheckPermissions from '@/services/useCheckPermissions.js'

export default {
  mixins: [useCheckPermissions],
  props: {
    title: {
      type: String,
      default: '',
    },
    breadcrumbs: {
      type: [Array, Boolean],
      default: () => [],
    },
    permissionOfSection: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['user']),
    viewPermissions() {
      return !this.permissionOfSection || this.checkPermissions(this.user.permissions, this.permissionOfSection, 'view')
    },
    editPermissions() {
      return !this.permissionOfSection || this.checkPermissions(this.user.permissions, this.permissionOfSection, 'edit')
    },
  },
  methods: {
    breadcrumbClick(link) {
      if (!this.viewPermissions) {
        return
      }
      this.$router.push(link)
    },
    checkScrollWindow() {
      const scrollPageValue = window?.scrollY
      if (scrollPageValue > 0) {
        this.$refs['head-section'].$el.classList.add('head-section__scroll')
      } else {
        this.$refs['head-section'].$el.classList.remove('head-section__scroll')
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.checkScrollWindow)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.checkScrollWindow)
  },
}
</script>

<style lang="scss">
.head-section {
  position: sticky !important;
  top: 64px;
  z-index: 3;
  display: flex !important;
  align-items: center;
  margin: -30px -30px 1.5rem -30px !important;
  padding: 22px 30px 22px 30px;
  background-color: #fff;
  border-top: 1px solid #f0f2f6;
  border-bottom: 1px solid #f0f2f6;
  transition: all .5s ease-out;
  &__scroll {
    padding: 16px 30px;
  }
  &__title {
    font-size: 20px;
    margin-top: 6px;
    margin-bottom: 0;
  }
  &__breadcrumb {
    color: #00000073;
    &:hover {
      cursor: default;
    }
    &__active {
      color: #1890FF !important;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
