<template>
  <div>
    <a-dropdown :trigger="['click']" placement="bottomLeft">
      <div :class="$style.dropdown">
        <a-badge>
          <a-avatar icon="user" :class="$style.avatar" class="mr-1" :src="user.avatar" />
          {{ user.email || '—' }}
        </a-badge>
      </div>
      <a-menu slot="overlay" style="z-index: 1;">
        <a-menu-item>
          <div>
            <strong>{{ $t('topBar.profileMenu.hello') }}, {{ user.name || 'Anonymous' }}</strong>
          </div>
          <div>
            <strong class="mr-1">{{ $t('topBar.profileMenu.role') }}:</strong>
            {{ user && user.roles && user.roles[0] && user.roles[0].name ? user.roles[0].name : '—' }}
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <div>
            <strong class="mr-1">{{ $t('topBar.profileMenu.email') }}:</strong>
            {{ user.email || '—' }}
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <a href="javascript: void(0);" @click="showProfileModal">
            <i class="fe fe-user mr-2"></i>
            {{ $t('topBar.profileMenu.editProfile') }}
          </a>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <a href="javascript: void(0);" @click="logout">
            <i class="fe fe-log-out mr-2"></i>
            {{ $t('topBar.profileMenu.logout') }}
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-modal
      v-model="profileModal"
      title="User Profile Info"
      @ok="handleSubmitProfile"
      :footer="null"
      class="profile-modal"
      width="600px"
      :destroyOnClose="true"
    >
      <menu-profile-update
        :user-info="user"
        @closeModalFunction="handleCloseProfile"
        @submitModalFunction="handleSubmitProfile"
      />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import menuProfileUpdate from '@/views/apps/users/menuProfile.vue'

export default {
  components: {
    menuProfileUpdate,
  },
  computed: {
    ...mapState(['user']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      profileModal: false,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/LOGOUT')
    },
    showProfileModal() {
      this.profileModal = true
      console.log('shoe modal')
    },
    handleSubmitProfile(e) {
      this.user.name = e.name
      this.user.email = e.email
      this.profileModal = false
    },
    handleCloseProfile(e) {
      this.profileModal = false
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
