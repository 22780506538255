<template>
  <div class="user-prizes-table">
      <div>
        <div class="d-flex align-items-center justify-content-between mb-4">
          <a-input-search size="large" v-model="emailSearch" placeholder="Enter email" enter-button
            @change="tableUpdate" :disabled="loading" class="" />
        </div>
        <a-table :columns="columns" :data-source="userPrizes" :rowKey="(record, index) => index" :loading="loading"
          :pagination="pagination" @change="changePagination" class="prisma-users__table">
          <template slot="id" slot-scope="text, record">
            <a-button type="link" @click="openLogs(record.id)">{{ record.id }}</a-button>
          </template>
          <template slot="updated_at" slot-scope="text, record">
            <span>{{ formatDate(record.updated_at) }}</span>
          </template>
          <template slot="status" slot-scope="text, record">
            <VerificationStatus :status="record.status" />
          </template>
          <template slot="verification" slot-scope="text, record">
            <a-button v-if="record.status === 'PENDING'" :disabled="!paymentsEdit" type="link"
              @click="openPaymentModal(record.id, record.amount)">Confirm</a-button>
            <span v-else>Confirmed</span>
          </template>
        </a-table>
      </div>
    <a-modal v-model="isVerificationModalOpen" title="Confirm payment" :footer="null" width="250px" :destroyOnClose="true">
      <VerificationModal :prizeId="selectedPrizeId" :amount="amountToVerificate" :email="user.email" :permissionToConfirm="paymentsEdit" @close="closeModal" @confirm="paymentConfirmed"/>
    </a-modal>
  </div>
</template>
<script>
import VerificationStatus from '@/views/apps/prisma/components/core/verificationStatus.vue'
import VerificationModal from '@/views/apps/prisma/components/modals/verificationModal.vue'
import PrismaService from '@/services/api/apiPrismaService'
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  name: 'userPrizes',
  props: ['userLogin', 'paymentsEdit', 'refreshPrizes'],
  components: { VerificationStatus, VerificationModal },
  data: () => ({
    loading: false,
    userPrizes: null,
    emailSearch: '',
    isVerificationModalOpen: false,
    amountToVerificate: null,
    selectedPrizeId: null,
    pagination: {
      pageSize: 20,
      current: 1,
      total: 0,
    },
    columns: [
      {
        title: 'Prize id',
        dataIndex: 'id',
        width: '18%',
        scopedSlots: { customRender: 'id' },
      },
      {
        title: 'Prize type',
        dataIndex: 'type',
        width: '12%',
      },
      {
        title: 'Date',
        dataIndex: 'updated_at',
        scopedSlots: { customRender: 'updated_at' },
        width: '25%',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: '12%',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '18%',
        scopedSlots: { customRender: 'status' },
      },
      {
        title: 'Verification',
        width: '25%',
        scopedSlots: { customRender: 'verification' },
      },
    ],
  }),
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.getPrizes()
  },
  watch: {
    refreshPrizes(value) {
      if (value) {
        this.getPrizes()
        this.$emit('prizesRefreshed')
      }
    },
  },
  methods: {
    async getPrizes() {
      this.loading = true

      const { pageSize, current } = this.pagination
      const searchParams = new URLSearchParams()
      searchParams.append('perPage', pageSize)
      searchParams.append('page', current)
      searchParams.append('login', this.userLogin)
      if (this.emailSearch.length) {
        searchParams.append('initiator', this.emailSearch)
      }
      try {
        const response = await PrismaService.getUserPrizes(searchParams)
        if (response.data.data) {
          this.userPrizes = response.data.data.items
          this.pagination.total = response.data.data.meta.total
        }
      } catch (err) {
        this.$notification.error({
          message: 'Something went wrong',
          description: err.response.data.data.message,
        })
      } finally {
        this.loading = false
      }
    },
    tableUpdate: debounce(function() {
      this.pagination.current = 1
      this.getPrizes()
    }, 1500),
    changePagination(pagination) {
      this.pagination = pagination
      this.getPrizes()
    },
    formatDate: function (date) {
      return this.$moment(date).tz('Etc/GMT-3').format('DD.MM.YY HH:mm')
    },
    openPaymentModal(prizeId, prizeAmount) {
      this.selectedPrizeId = prizeId
      this.amountToVerificate = prizeAmount
      this.isVerificationModalOpen = true
    },
    closeModal() {
      this.selectedPrizeId = null
      this.amountToVerificate = null
      this.isVerificationModalOpen = false
    },
    paymentConfirmed() {
      this.getPrizes()
      this.closeModal()
    },
    openLogs(prizeId) {
      this.$router.push(`/prisma/${this.userLogin}/${prizeId}/prize-logs`)
    },
  },
}
</script>
  <style lang="scss">
  </style>
