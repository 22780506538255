<template>
  <div class="feedbacks-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
      <a-col :span="18">
        <h3 class="my-2" style="font-size: 20px;">Reviews</h3>
      </a-col>
      <a-col :span="6">
        <a-button class="ml-auto d-block" type="primary" @click="exportReviews">
          <a-icon v-if="isExporting" type="loading" />
          <a-icon v-else type="upload" />
          Export
        </a-button>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div class="d-flex align-items-center">
          <span>Filters:</span>
          <a-select
            v-model="filters.platform.value"
            placeholder="Select platform"
            class="ml-2"
            style="width: 150px;"
            :options="filters.platform.list"
            @change="changePlatform"
          ></a-select>
          <a-select
            v-model="filters.app.value"
            placeholder="Select app"
            class="ml-2"
            style="width: 150px;"
            :options="appList"
            @change="changeApp"
          ></a-select>
          <a-select
            v-model="filters.type.value"
            placeholder="Select type"
            class="ml-2 mr-2"
            style="width: 150px;"
            :options="filters.type.list"
            @change="changeType"
          ></a-select>
          <a-range-picker v-model="filters.date" :ranges="dateRanges" @change="changeDate">
            <a-input placeholder="Select date" :value="filterDate">
              <template #suffix>
                <a-icon style="color: #BFBFBF;" type="calendar" />
              </template>
            </a-input>
          </a-range-picker>
        </div>
        <div>
          <a-input-search
            v-model="search"
            placeholder="input search text"
            style="max-width: 243px;"
            enter-button
          />
        </div>
      </div>
      <a-table
        :columns="feedbacksColumns"
        :row-key="record => record.id"
        :data-source="feedbacksData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        class="feedbacks-table"
      >
        <span slot="created_at" slot-scope="created_at">
          <div>{{ $moment(created_at). format('DD.MM.YY')}}</div>
          <div :style="{color: '#595959'}">{{ $moment(created_at). format('HH:mm')}}</div>
        </span>
        <span slot="login" slot-scope="login, record">
          {{ (!login && !record.name) ? record.device_id.substr(0,4) + "..." + record.device_id.substr(record.device_id.length -4, record.device_id.length) : ''}}
          {{ login }}
          <span class="d-block" :style="{color: '#595959'}">{{ record.name }}</span>
        </span>
        <span slot="comment" slot-scope="comment, record">
          {{ comment.substring(0, 400) }} <span v-if="comment.length > 400">...</span>
          <a-tooltip v-if="comment.length > 400" title="Click to open comment" placement="left">
            <a href="javascript:;" class="text-primary" @click="showFeedback(record)">more</a>
          </a-tooltip>
        </span>
        <span slot="messenger_login" slot-scope="messenger_login, record">
          <span v-if="record.messenger" class="font-size-18 pr-1">
            <a-tooltip :title="record.messenger" placement="bottom" >
              <span v-if="record.messenger === 'skype'"><font-awesome-icon :icon="['fab', 'skype']" class="text-primary" /></span>
              <span v-if="record.messenger === 'telegram'"><font-awesome-icon :icon="['fab', 'telegram']" class="text-primary" /></span>
              <span v-if="record.messenger === 'viber'"><font-awesome-icon :icon="['fab', 'viber']" class="text-pink" /></span>
              <span v-if="record.messenger === 'whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp-square']" class="text-success" /></span>
              <span v-if="record.messenger === 'email'"><a-icon type="mail" class="text-gray-8" /></span>
            </a-tooltip>
          </span>
          <span v-if="record.contact_decrypted === true">
            <span v-if="record.messenger === 'telegram'"><a :href="`https://t.me/${record.messenger_login.replace('@','')}`" target="_blank">{{ record.messenger_login }}</a></span>
            <span v-if="record.messenger === 'whatsapp'"><a :href="`https://wa.me/${record.messenger_login}`" target="_blank">{{ record.messenger_login }}</a></span>
            <span v-if="record.messenger === 'skype'"><a :href="`skype:${record.messenger_login}?userinfo`" target="_blank">{{ record.messenger_login }}</a></span>
            <span v-if="['viber', 'email'].includes(record.messenger)">{{ record.messenger_login }}</span>
            <a-tooltip title="Hide contact information" placement="bottom">
              <a href="javascript:;" class="pl-1">
                <a-icon type="eye" class="text-primary" @click="encryptValue(record.id)"  />
              </a>
            </a-tooltip>
          </span>
          <span v-else>
            {{ record.messenger_login.substring(0,10) }}...
            <a-tooltip title="Show contact information" placement="bottom" v-if="decryptionKey && record.app_v">
              <a href="javascript:;" v-if="!record.contact_decrypted">
                <a-icon type="eye-invisible" class="text-primary" @click="decryptValue(record.id, record.messenger_login)"  />
              </a>
            </a-tooltip>
          </span>
        </span>
       <!-- <span slot="messenger" slot-scope="messenger">
         <div v-if="messenger" class="font-size-18">
           <a-tooltip :title="messenger" placement="bottom" >
             <span v-if="messenger === 'skype'"><font-awesome-icon :icon="['fab', 'skype']" class="text-primary" /></span>
             <span v-if="messenger === 'telegram'"><font-awesome-icon :icon="['fab', 'telegram']" class="text-primary" /></span>
             <span v-if="messenger === 'viber'"><font-awesome-icon :icon="['fab', 'viber']" class="text-pink" /></span>
             <span v-if="messenger === 'whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp-square']" class="text-success" /></span>
             <span v-if="messenger === 'email'"><a-icon type="mail" class="text-gray-8" /></span>
           </a-tooltip>
         </div>
       </span> -->
        <span slot="app" slot-scope="app, record">
          <span v-if="app || record.bundle_id">
            <span v-if="app">{{ app.name }}</span>
            <span v-else>{{ record.bundle_id }}</span>
          </span>
          <div v-if="record.app_v">
            <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="text-success" />
            <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
            <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="text-default" />
            <small>v{{ record.app_v }}</small>
          </div>
        </span>
        <!-- <span slot="platform" slot-scope="platform" class="font-size-18">
          <span v-if="platform">
            <a-icon v-if="platform.toLowerCase() === 'android'" type="android" class="text-success" />
            <a-icon v-else type="apple" class="text-primary" />
          </span>
        </span> -->
        <span slot="is_responded" slot-scope="is_responded">
          <a-badge v-if="is_responded" color="grey" />
          <a-badge v-else color="blue" />
        </span>
        <span slot="rating" slot-scope="rating, record">
          <span :class="record.type === 'positive' ? 'positive-color' : 'negative-color'">
            {{ record.type === 'positive' ? 'Positive' : 'Negative' }}
          </span>
          <span v-if="record.clarity !== undefined" class="ratings">{{ `Clarity: ${record.clarity}` }}</span>
          <span v-if="record.prizes !== undefined" class="ratings">{{ `Prizes: ${record.prizes}` }}</span>
          <span v-if="record.design !== undefined" class="ratings">{{ `Design: ${record.design}` }}</span>

          <!-- <a-icon v-else type="close-circle" theme="filled" :style="{color: '#FF4D4F', fontSize: '18px'}" /> -->
          <!-- <span v-if="type === 'positive'">👍</span>
          <span v-else>👎</span> -->
        </span>
        <template slot="operation" slot-scope="text, record">
          <a-tooltip title="Click to open comment" placement="left">
            <a href="javascript:;" class="text-primary" @click="showFeedback(record)">More<a-icon type="right" /></a>
          </a-tooltip>
        </template>
      </a-table>
    </a-card>
    <!-- <a-modal v-model="feedbackModal"
      class="olimp-admin-modal"
      width="520px"
      :destroyOnClose="true"
    >
      <feedbackModal
        @closeModalFunction="closeFeedbackModal"
        :currentFeedback="currentFeedback"
        :feedbacksColumns="feedbacksColumns"
      />
    </a-modal> -->
    <a-modal v-model="feedbackModal"
      class="olimp-admin-modal"
      width="520px"
      :destroyOnClose="true"
      :closable="false"
    >
      <!-- <div>
        Date
        <div class="text-gray-8 mt-1"><a-icon type="calendar"/> {{ $moment(currentFeedback.created_at).format('DD.MM.YY HH:mm') }}</div>
      </div>
      <div class="mt-3">
        Email
        <div>
          <span v-if="currentFeedback.contact_decrypted === true">
            <span v-if="currentFeedback.messenger === 'telegram'"><a :href="`https://t.me/${currentFeedback.messenger_login.replace('@','')}`" target="_blank">{{ currentFeedback.messenger_login }}</a></span>
            <span v-if="currentFeedback.messenger === 'whatsapp'"><a :href="`https://wa.me/${currentFeedback.messenger_login}`" target="_blank">{{ currentFeedback.messenger_login }}</a></span>
            <span v-if="currentFeedback.messenger === 'skype'"><a :href="`skype:${currentFeedback.messenger_login}?userinfo`" target="_blank">{{ currentFeedback.messenger_login }}</a></span>
            <span v-if="['viber', 'email'].includes(currentFeedback.messenger)">{{ currentFeedback.messenger_login }}</span>
            <a-tooltip title="Hide contact information" placement="bottom">
              <a href="javascript:;" class="pl-1">
                <a-icon type="eye" class="text-primary" @click="encryptValue(currentFeedback.id)" />
              </a>
            </a-tooltip>
          </span>
          <span v-else>
            <span ref="mesLog" v-if="feedbackModal">{{ showKey(currentFeedback.messenger_login) }}
              <a href="javascript:;" class="three-dots" @click="showInFull">...</a>
            </span>
            <span v-else>{{ currentFeedback.messenger_login }}</span>
            <a-tooltip title="Show contact information" placement="bottom" v-if="decryptionKey && currentFeedback.app_v">
              <a href="javascript:;" v-if="!currentFeedback.contact_decrypted">
                <a-icon type="eye-invisible" class="text-primary" @click="decryptValue(currentFeedback.id, currentFeedback.messenger_login)"  />
              </a>
            </a-tooltip>
          </span>
          </div>
      </div>
      <div class="mt-3">
        Login
        <div class="text-gray-8 mt-1"><a-icon type="user"/> {{ currentFeedback.login }}</div>
      </div> -->
      <div>
        <div class="comment-title">Comment</div>
        <!-- <a-tooltip title="Copy to clipboard" placement="right">
          <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardCommentHandle(currentFeedback.body)" /></a>
        </a-tooltip> -->
        <div class="comment text-gray-8 mt-1">{{ currentFeedback.body }}</div>
      </div>
      <template slot="title">
        <div class="d-inline-block mt-1">
          Review
        </div>
        <!-- <div class="d-inline-block mt-1">
          {{ (currentFeedback.login) ? 'Review ' + currentFeedback.login : 'Review' }}
        </div>
        <div class="d-inline-block ml-2 font-weight-normal">
          <a-tag v-if="currentFeedback.is_responded">Responded</a-tag>
          <a-tag v-else color="#1890ff">New</a-tag>
        </div> -->
      </template>
      <template slot="footer">
        <a-row>
          <a-col class="text-right" :span="24">
            <!-- <a-button @click="closeFeedbackModal">
              Cancel
            </a-button> -->
            <a-button type="primary" @click="closeFeedbackModal">
              Close
            </a-button>
            <!-- <CustomButton key="back" @click="closeFeedbackModal">
              Cancel
            </CustomButton>
            <a-button type="primary" @click="showReplyModal">
              Respond
            </a-button> -->
          </a-col>
        </a-row>
      </template>
    </a-modal>
    <a-modal v-model="replyModal"
             title="Reply to review"
             class="olimp-admin-modal"
             width="520px"
             :destroyOnClose="true"
    >
      <a-form-model
        ref="respondForm"
        :model="respondForm"
        :rules="respondFormRules"
      >
        <a-row>
          <a-col class="text-left" :span="6">
            Date
            <div class="text-gray-8">{{ $moment(currentFeedback.created_at).format('DD.MM.YY HH:mm') }}</div>
          </a-col>
          <a-col class="text-left" :span="12">
            Email
            <div class="text-gray-8">{{ currentFeedback.email }}</div>
          </a-col>
          <a-col class="text-left" :span="6">
            Login
            <div class="text-gray-8">{{ currentFeedback.login }}</div>
          </a-col>
        </a-row>
        <div class="mt-4">
          Your comment
          <a-form-model-item ref="comment" prop="comment">
            <a-textarea v-model="respondForm.comment" allow-clear placeholder="Input comment here" />
          </a-form-model-item>
        </div>
      </a-form-model>
      <template slot="footer">
        <a-row>
          <a-col class="text-right" :span="24">
            <!-- <a-button @click="closeReplyModal">
              Cancel
            </a-button> -->
            <CustomButton key="back" @click="closeReplyModal">
              Cancel
            </CustomButton>
            <a-button type="primary">
              Send
            </a-button>
          </a-col>
        </a-row>
      </template>
    </a-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import CustomButton from '@/components/custom/button/CustomButton'
// import feedbackModal from '@/views/feedbacks/feedbackModal.vue'

const feedbacksColumns = [
  // {
  //   title: '',
  //   dataIndex: 'is_responded',
  //   key: 'is_responded',
  //   width: '3%',
  //   scopedSlots: { customRender: 'is_responded' },
  // },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '8.5%',
    sorter: (a, b) => a.created_at - b.created_at,
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'User',
    dataIndex: 'login',
    key: 'login',
    width: '9%',
    scopedSlots: { customRender: 'login' },
  },
  // {
  //   title: 'Name',
  //   dataIndex: 'name',
  //   key: 'name',
  //   width: '10%',
  //   scopedSlots: { customRender: 'name' },
  // },
  // {
  //   title: 'Contact Information',
  //   dataIndex: 'messenger_login',
  //   key: 'messenger_login',
  //   width: '17%',
  //   scopedSlots: { customRender: 'messenger_login' },
  // },
  // {
  //   title: 'Service',
  //   dataIndex: 'messenger',
  //   key: 'messenger',
  //   align: 'center',
  //   width: '10%',
  //   scopedSlots: { customRender: 'messenger' },
  // },
  {
    title: 'App',
    dataIndex: 'app',
    key: 'app',
    align: 'left',
    width: '10%',
    scopedSlots: { customRender: 'app' },
  },
  // {
  //   title: 'Platform',
  //   dataIndex: 'platform',
  //   key: 'platform',
  //   filters: [
  //     { text: 'iOS', value: 'IOS' },
  //     { text: 'Android', value: 'ANDROID' },
  //   ],
  //   width: '9%',
  //   scopedSlots: { customRender: 'platform' },
  // },
  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
    // filters: [
    //   { text: 'Positive', value: 'positive' },
    //   { text: 'Negative', value: 'negative' },
    // ],
    align: 'left',
    width: '7.5%',
    scopedSlots: { customRender: 'rating' },
  },
  {
    title: 'Comment',
    dataIndex: 'body',
    key: 'body',
    width: '35%',
    scopedSlots: { customRender: 'comment' },
  },
  // {
  //   title: '',
  //   dataIndex: 'operation',
  //   align: 'right',
  //   width: '7.5%',
  //   scopedSlots: { customRender: 'operation' },
  // },
]
export default {
  name: 'index',
  components: {
    CustomButton,
    // 'feedbackModal': () => import('@/views/feedbacks/feedbackModal.vue'),
    // feedbackModal,
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    appList() {
      if (!this.applications.length) return []
      else {
        if (this.filters.platform.value === 'ios') {
          return this.applications.filter(app => app.platform === 'ios').map(app => {
            return {
              value: app.bundle_name,
              label: app.name,
            }
          })
        } else if (this.filters.platform.value === 'android') {
          return this.applications.filter(app => app.platform === 'android').map(app => {
            return {
              value: app.bundle_name,
              label: app.name,
            }
          })
        } else if (this.filters.platform.value === 'web') {
          return this.applications.filter(app => app.platform === 'web').map(app => {
            return {
              value: app.bundle_name,
              label: app.name,
            }
          })
        } else {
          return this.applications.map(app => {
            return {
              value: app.bundle_name,
              label: `${app.name} (${app.platform.toUpperCase()})`,
            }
          })
        }
      }
    },
    filterDate() {
      if (!this.filters.date) return null
      else {
        return this.$moment(this.filters.date[0]._d).format('DD.MM.YYYY') + ' - ' + this.$moment(this.filters.date[1]._d).format('DD.MM.YYYY')
      }
    },
  },
  data() {
    return {
      isExporting: false,
      accessModule: 'feedbacks',
      feedbacksData: [],
      currentFeedback: [],
      pagination: { pageSize: 20 },
      tableFilters: {},
      tableSorter: {},
      tableDateFilter: null,
      loading: false,
      feedbacksColumns,
      searchField: 'login',
      searchFields: ['login', 'name', 'body'],
      searchValue: '',
      applications: [],
      filteredApps: [],
      feedbackModal: false,
      replyModal: false,
      fullKey: false,
      search: null,
      dateRanges: {
        Today: [dayjs(), dayjs()],
      },
      filters: {
        date: null,
        app: {
          value: 'All',
        },
        platform: {
          value: 'all',
          list: [
            {
              value: 'ios',
              label: 'iOS',
            },
            {
              value: 'android',
              label: 'Android',
            },
            {
              value: 'web',
              label: 'Web',
            },
            {
              value: 'all',
              label: 'All',
            },
          ],
        },
        type: {
          value: 'all',
          list: [
            {
              value: 'positive',
              label: 'Positive',
            },
            {
              value: 'negative',
              label: 'Negative',
            },
            {
              value: 'all',
              label: 'All',
            },
          ],
        },
      },
      respondForm: {
        email: '',
        comment: '',
      },
      respondFormRules: {
        email: [{ required: true, message: 'Please input email', trigger: 'change' }],
        comment: [{ required: true, message: 'Please input comment', trigger: 'change' }],
      },
      decryptionKey: !!sessionStorage.getItem('app.encryption.privatekey'),
    }
  },
  mounted () {
    this.fetch()
  },
  created() {
    // this.makeApplicationFilter()
    this.getApplications()
  },
  methods: {
    changePlatform(value) {
      if (value === 'all' && this.tableFilters.platform) delete this.tableFilters.platform
      else {
        this.tableFilters.platform = [value]
      }
      this.handleTableChange(this.pagination, this.tableFilters, this.tableSorter)
    },
    changeApp(value) {
      if (value === 'all' && this.tableFilters.app) delete this.tableFilters.app
      else {
        this.tableFilters.app = [value]
      }
      this.handleTableChange(this.pagination, this.tableFilters, this.tableSorter)
    },
    changeType(value) {
      if (value === 'all' && this.tableFilters.type) delete this.tableFilters.type
      else {
        this.tableFilters.type = [value]
      }
      this.handleTableChange(this.pagination, this.tableFilters, this.tableSorter)
    },
    changeDate(value) {
      const fromDate = `${this.$moment(value[0]._d).format('YYYY-MM-DD')}T00:00:00.000000Z`
      const toDate = `${this.$moment(value[1]._d).format('YYYY-MM-DD')}T23:59:59.000000Z`
      this.tableDateFilter = { fromDate, toDate }
      this.handleTableChange(this.pagination, this.tableFilters, this.tableSorter)
    },
    exportReviews() {
      if (this.isExporting) return
      this.isExporting = true
      const url = '/admin/reviews/export'
      apiClient.get(url, { responseType: 'blob' }).then((response) => {
        const filename = 'reviews_export.xlsx'
        const element = document.createElement('a')
        console.log(response.responseText)
        element.href = window.URL.createObjectURL(response.data)
        element.download = filename
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
        this.isExporting = false
      }).catch(error => {
        console.log(error)
      })
    },
    showKey(current) {
      if (this.fullKey) {
        return current
      } else {
        return current.substring(0, 10)
      }
    },
    showInFull() {
      this.fullKey = !this.fullKey
    },
    showFeedback(obj) {
      console.log(obj, 'FEED')
      this.currentFeedback = obj
      this.feedbackModal = true
    },
    showReplyModal() {
      this.replyModal = true
    },
    closeFeedbackModal() {
      this.feedbackModal = false
    },
    closeReplyModal() {
      this.replyModal = false
    },
    handleSearchField() {
      console.log(this.searchField)
    },
    onSearch() {
      if ((this.searchField !== 'login') && (this.searchValue.length < 6)) {
        this.$message.error('Search value of this field must contain at least 6 characters')
      } else {
        this.fetch({ searchField: this.searchField, searchValue: this.searchValue })
      }
    },
    handleTableChange(pagination, filters, sorter) {
      console.log('asfdfsafsadsadf', pagination, filters, sorter)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.tableSorter = sorter
      const data = {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...this.tableFilters,
        searchField: this.searchField,
        searchValue: this.searchValue,
      }
      if (this.tableDateFilter) {
        data.fromDate = this.tableDateFilter.fromDate
        data.toDate = this.tableDateFilter.toDate
      }
      this.fetch(data)
      // console.log(filters)
      // console.log(this.filteredApps)
    },
    fetch(params = {}) {
      console.log('params:', params)
      this.loading = true
      const reqData = { results: 25, ...params }
      console.log(reqData)
      const url = '/admin/reviews'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.loading = false
        this.feedbacksData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    clipboardMessage(field, v) {
      this.$notification.success({
        message: field + ' copied to clipboard',
        description: v,
      })
    },
    clipboardCommentHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.clipboardMessage('Comment', v.slice(0, 8) + '...')
    },
    decryptValue(key, value) {
      console.log(key, 'KEY')
      console.log(value, 'VALUE')
      const decKey = sessionStorage.getItem('app.encryption.privatekey')
      if (!decKey) {
        this.$message.error('Decryption key not found in session storage')
        return
      }
      const target = this.feedbacksData.filter(item => key === item.id)[0]
      const dec = this.$decryptData(decKey, value)
      if (dec) {
        target.contact_decrypted = true
        target.messenger_login_orig = value
        target.messenger_login = dec
        console.log(dec, 'DEC')
      } else {
        this.$message.error('Data decryption failed due to invalid key or data content')
      }
    },
    encryptValue(key) {
      const target = this.feedbacksData.filter(item => key === item.id)[0]
      target.messenger_login = target.messenger_login_orig
      target.contact_decrypted = false
    },
    async getApplications() {
      const url = '/admin/applications'
      return apiClient.get(url).then((response) => {
        this.applications = response.data.data
      }).catch(error => { console.log(error) })
    },
    async makeApplicationFilter() {
      const target = this.feedbacksColumns.filter(item => item.key === 'app')[0]
      await this.getApplications().then((apps) => {
        target.filters = this.applications.map(function(app) {
          return { text: `${app.name} (${app.platform.toUpperCase()})`, value: app.bundle_name }
        })
      })
    },
    toggleSelectedApps(oldApps, app, checked) {
      let newApps = [...oldApps]
      if (checked) {
        newApps.push(app)
      } else {
        newApps = newApps.filter(t => t !== app)
      }
      console.log('new apps', newApps)
      return newApps
    },
    handleConfirmAppFilter(confirm) {
      const appFilter = {
        app: this.filteredApps.map(function(obj) {
          return obj.name
        }),
      }
      confirm()
      console.log(appFilter)
    },
    handleClearAppFilter(clearFilters) {
      const appFilter = {
        app: this.filteredApps.map(function(obj) {
          return obj.name
        }),
      }
      clearFilters()
      console.log(appFilter)
    },
  },
}
</script>
<style lang="scss">
// @media (max-width: 1600px) {
//   .feedbacks-table {
//     .ant-table-thead, .ant-table-tbody  {
//       font-size: 1em;
//     }
//   }
// }
// @media (max-width: 1500px) {
//   .feedbacks-table {
//     .ant-table-thead, .ant-table-tbody  {
//       font-size: 0.9em;
//     }
//   }
// }
td{
  white-space: pre-line
}
.feedbacks-table.ant-table-wrapper .ant-table-content {
  color: #000;
  font-size: 14px;
  .ant-table-column-title {
    font-weight: 600;
  }
}
.olimp-admin-modal .comment-title {
  font-size: 14px;
  font-weight: 500;
}
.olimp-admin-modal .comment {
  padding: 8px 12px;
  background: #F5F5F5;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  font-size: 14px;
  color: #262626;
}
.three-dots {
  margin-left: 2px;
  margin-right: 7px;
}

.positive-color {
  color: #81D458,
}

.negative-color {
  color: #FF4D4F,
}
.ratings {
  display: block;
}
</style>
