<template>
  <div>
    <a-row :gutter="[16, 16]">
      <a-col :span="24">
        <label for="name" style="display: block; margin-bottom: 7.5px; line-height: 1.2 !important;">
          User
        </label>
        <a-input
          v-model="login"
          id="name"
          placeholder="User"
        />
      </a-col>
      <!-- <a-col :span="6" style="padding-top:36px;">
        <a-switch
          v-model="is_active"
        />
        Active
      </a-col>
      <a-col :span="24">
        <label style="display: block; margin-bottom: 7.5px; line-height: 1.2 !important;">
          Select betatest
        </label>
        <a-select
          v-model="betatest"
          placeholder="Select betatest"
          style="width: 100%;"
        >
          <a-select-option
            v-for="beta in betatests"
            :key="beta.id"
          >
            {{ beta.name }}
          </a-select-option>
        </a-select>
      </a-col> -->
    </a-row>
    <hr>
    <div class="d-flex align-items-center justify-content-end">
        <CustomButton @click="handleCancel">
          Cancel
        </CustomButton>
        <a-button style="margin-left:8px;" type="primary" @click="handleSubmit">
          Add
        </a-button>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from '@/services/axios'
import CustomButton from '@/components/custom/button/CustomButton'

export default {
props: {
  betatests: {
    type: Array,
    required: true,
    default: () => [],
  }
},
components: {
  CustomButton,
},
data: () => ({
  login: '',
  is_active: true,
  betatest: undefined,
  visible: false,
  user: '',
  loading: false,
  title: "Add user",
}),
methods: {
  async handleSubmit(e) {
    e.preventDefault()
    await this.addUser({
      login: this.login,
    })
  },
  async addUser(payload) {
    try {
      this.loading = true
      await apiClient.post('admin/beta/users/subscribe', { ...payload })
      this.$notification.success({
        message: 'Success',
        description: 'User added successfully',
        duration: 2,
      })
      this.$emit('update')
      this.$emit('cancel')
      this.visible = false
    } catch (e) {
      this.$notification.error({
        message: 'Error',
        description: e.message,
      })
    } finally {
      this.loading = false
    }
  },
  handleCancel() {
    this.$emit('cancel');
    this.form.resetFields();
    this.visible = false;
  },
},
}
</script>

<style lang="scss">
.create-code--modal {
.generate-code--btn {
  padding: 0px;
  width: 100%;
}
}
.ant-select {
  .ant-select-selection {
    &__placeholder {
      color: #BFBFBF;
    }
  }
}
</style>
