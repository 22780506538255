<template>
  <div class="backups-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
      <a-col :span="18">
        <h3 class="my-2" style="font-size: 20px;">Backup</h3>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <a-table
        :columns="backupColumns"
        :row-key="record => record.id"
        :data-source="backupsData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        class="backup-table"
      >
      <template slot="created_at" slot-scope="text">
        {{ $moment(text).format('DD.MM.YY, h:mm') }}
      </template>
      <template slot="location" slot-scope="text">
        <a-tooltip placement="top">
        <template slot="title">
          {{text}}
        </template>
        <a-icon v-if="text === 'local'" type="hdd"  style="font-size:18px" />
        <a-icon v-else type="cloud-server"  style="font-size:18px" />
      </a-tooltip>
      </template>
      <template slot="filesize" slot-scope="text">
        {{ Math.round(Number(text) / 100000) / 10 }}
      </template>
      <template slot="status" slot-scope="text">
        <a-tooltip placement="top">
          <template slot="title">
            {{text}}
          </template>
          <a-icon v-if="text === 'healthy'" type="check-circle" style="color: green; font-size:18px" />
          <a-icon v-else type="close-circle" style="color: red; font-size:18px" />
        </a-tooltip>
      </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'

const backupColumns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '20%',
    // sorter: (a, b) => a.created_at - b.created_at,
    scopedSlots: { customRender: 'created_at' },
  },
  // {
  //   title: 'Name',
  //   dataIndex: 'name',
  //   key: 'name',
  //   width: '20%',
  //   scopedSlots: { customRender: 'name' },
  // },
  {
    title: 'Location',
    dataIndex: 'disk',
    key: 'location',
    width: '20%',
    scopedSlots: { customRender: 'location' },
  },
  {
    title: 'Size, Mb',
    dataIndex: 'file_size',
    key: 'filesize',
    width: '20%',
    scopedSlots: { customRender: 'filesize' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '20%',
    scopedSlots: { customRender: 'status' },
  },
  // {
  //   title: 'Actions',
  //   dataIndex: 'operation',
  //   align: 'right',
  //   width: '10%',
  //   scopedSlots: { customRender: 'operation' },
  // },
]
export default {
  name: 'index',
  components: {
    //
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      accessModule: 'backup',
      backupsData: [],
      currentBackup: [],
      pagination: { pageSize: 20 },
      loading: false,
      backupColumns,
      backupModal: false,
    }
  },
  mounted () {
    this.fetch()
    const url = '/admin/server/backups'
    apiClient.get(url).then((response) => {
      this.backupsData = response.data.data
      console.log(this.updates)
    }).catch(error => { console.log(error) })
  },
  created() {
    //
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
      })
    },
    fetch(params = {}) {
      console.log('params:', params)
      this.loading = true
      const reqData = { results: 25, ...params }
      console.log(reqData)
      const url = '/admin/backups'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.loading = false
        this.backupsData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
  },
}
</script>
<style lang="scss">
@media (max-width: 1600px) {
  .backup-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.9em;
    }
  }
}
@media (max-width: 1500px) {
  .backup-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.8em;
    }
  }
}
</style>
