<template>
  <div class="d-flex flex-column">
    <span v-if="!hideTitle" class="font-weight-bold">Application</span>
    <a-select
      :value="value"
      :size="size"
      :disabled="disabled"
      @change="handleChange"
    >
    <template #placeholder><span style="color: #a1a1c2;">Select app</span></template>
      <a-select-option
        v-for="app in apps"
        :key="app.application_id"
      >
      <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="text-success" />
      <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
      <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="text-default" />
        {{ app.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'appSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    apps: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: undefined,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'large',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>
