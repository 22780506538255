import apiClient from '@/services/axios'

var appDataDef = []
function getApplications() {
  const url = '/admin/applications'
  apiClient.get(url).then((response) => {
    appDataDef = response.data.data
  }).catch(error => { console.log(error) })
}

getApplications()

export default {
  data () {
    return {
      apps: appDataDef,
    }
  },
  created: function () {
    // console.log('created banner Mixin')
  },
  mounted () {
    // console.log('Mounted banner Mixin')
  },
  computed: {
    // ---
  },
  methods: {
    // getApplications() {
    //   const url = '/admin/applications'
    //   return apiClient.get(url).then((response) => {
    //     // console.log(response.data.data)
    //     return response.data.data
    //   }).catch(error => { console.log(error) })
    // },
  },
}
