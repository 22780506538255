<template>
  <div class="user-tasks-table">
    <div>
      <div class="d-flex align-items-end mb-4">
        <UniversalSelect class="mr-3" v-model="filters.active" style="width: 180px;" :options="activeOptions"
          title="Active" placeholder="Select status" :disabled="loading" @change="updateFilters" />
        <UniversalSelect class="mr-3" v-model="filters.status" style="width: 180px;" :options="completedOptions"
          title="Status" placeholder="Select status" :disabled="loading" @change="updateFilters" />
        <a-input-search size="large" v-model="filters.name" placeholder="Enter task name" @change="tableUpdate"
          enter-button :disabled="loading" />
      </div>
      <a-table :columns="columns" :data-source="userTasks" :rowKey="(record, index) => index" :loading="loading"
        :pagination="pagination" @change="changePagination" class="prisma-users__table">
        <template slot="task_id" slot-scope="text, record">
          <div class="d-flex flex-column">
            <a class="mb-1 prisma-users__link" href="javascript:" @click="openTaskDetails(record.task_id, record.id)">{{ record.task_id }}</a>
            <div style="color: #8C8C8C">
              <span class="mr-1">{{ record.name }}</span>
              <a-tooltip title="Copy to clipboard" placement="right">
                <a href="javascript:;"><a-icon type="copy" class="copy-icon"
                    @click="clipboardHandle(record.name)" /></a>
              </a-tooltip>
            </div>
          </div>
        </template>
        <template slot="description" slot-scope="text, record">
          <span>{{ record.description }}</span>
        </template>
        <template slot="status" slot-scope="text, record">
          <span>{{ record.status.replace(/_/g, ' ').replace(/^./, char => char.toUpperCase()) }}</span>
        </template>
        <template slot="started_at" slot-scope="text, record">
          <span v-if="record.started_at">{{ formatDate(record.started_at) }}</span>
          <span v-else> - </span>
        </template>
        <template slot="completed_at" slot-scope="text, record">
          <span v-if="record.completed_at">{{ formatDate(record.completed_at) }}</span>
          <span v-else> - </span>
        </template>
        <template slot="progress" slot-scope="text, record">
          <div v-if="record.progress.second !== null">
            <span class="d-block">{{ `${record.progress.first.completed_steps} / ${record.progress.first.total_steps}`}}</span>
            <span>{{ `${record.progress.second.completed_steps} / ${record.progress.second.total_steps}` }}</span>
          </div>
          <span v-else>{{ `${record.progress.first.completed_steps} / ${record.progress.first.total_steps}` }}</span>
        </template>
        <template slot="is_cancelled" slot-scope="text, record">
          <span>{{ record.is_cancelled ? 'Active' : 'Inactive' }}</span>
        </template>
        <template slot="created_at" slot-scope="text, record">
          <span v-if="record.created_at">{{ formatDate(record.created_at) }}</span>
          <span v-else> - </span>
        </template>
        <template slot="reward_points" slot-scope="text, record">
          <span>{{ record.reward_points }}</span>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import PrismaService from '@/services/api/apiPrismaService'
import debounce from 'lodash.debounce'
import UniversalSelect from '@/components/custom/filters/universalSelect.vue'

export default {
  name: 'userTasks',
  props: ['userLogin', 'userUUID'],
  components: { UniversalSelect },
  data: () => ({
    loading: false,
    userTasks: null,
    pagination: {
      pageSize: 20,
      current: 1,
      total: 0,
    },
    filters: {
      active: undefined,
      status: undefined,
      name: undefined,
    },
    activeOptions: [
      { name: 'Active', value: true },
      { name: 'Inactive', value: false },
    ],
    completedOptions: [
      { name: 'Completed', value: 'completed' },
      { name: 'Incompleted', value: 'incompleted' },
      { name: 'In progress', value: 'in_progress' },
    ],
    columns: [
      {
        title: 'Task ID',
        dataIndex: 'task_id',
        width: '15%',
        scopedSlots: { customRender: 'task_id' },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: '15%',
        scopedSlots: { customRender: 'description' },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '5%',
        scopedSlots: { customRender: 'status' },
      },
      {
        title: 'Start Date',
        dataIndex: 'started_at',
        width: '5%',
        scopedSlots: { customRender: 'started_at' },
      },
      {
        title: 'Completion Date',
        dataIndex: 'completed_at',
        width: '5%',
        scopedSlots: { customRender: 'completed_at' },
      },
      {
        title: 'Progress',
        dataIndex: 'progress',
        width: '5%',
        scopedSlots: { customRender: 'progress' },
      },
      {
        title: 'Active',
        dataIndex: 'is_cancelled',
        width: '5%',
        scopedSlots: { customRender: 'is_cancelled' },
      },
      {
        title: 'Display Date',
        dataIndex: 'created_at',
        width: '5%',
        scopedSlots: { customRender: 'created_at' },
      },
      {
        title: 'Points',
        dataIndex: 'reward_points',
        width: '5%',
        scopedSlots: { customRender: 'points' },
      },
    ],
  }),
  computed: {
  },
  created() {
    this.getTasks()
  },
  methods: {
    async getTasks() {
      this.loading = true

      const { pageSize, current } = this.pagination
      const { active, status, name } = this.filters

      const searchParams = new URLSearchParams()

      searchParams.append('per_page', pageSize)
      searchParams.append('page', current)
      if (typeof active !== 'undefined') {
        searchParams.append('is_cancelled', active)
      }

      if (typeof status !== 'undefined') {
        searchParams.append('status', status)
      }
      if (typeof name !== 'undefined') {
        searchParams.append('name', name)
      }
      try {
        const response = await PrismaService.getUserTasks(this.userUUID, searchParams)
        if (response.data.data) {
          this.userTasks = response.data.data
          this.pagination.total = response.data.meta.total
        }
      } catch (err) {
        console.log(err)
        this.$notification.error({
          message: 'Something went wrong',
          description: err.response.data.data.message,
        })
      } finally {
        this.loading = false
      }
    },
    tableUpdate: debounce(function() {
      this.updateFilters()
    }, 1500),
    updateFilters() {
      this.pagination.current = 1
      this.getTasks()
    },
    changePagination(pagination) {
      this.pagination = pagination
      this.getTasks()
    },
    formatDate: function (date) {
      return this.$moment(date).tz('Etc/GMT-3').format('DD.MM.YY HH:mm')
    },
    clipboardHandle(name) {
      this.$clipboard(name)
      this.$notification.success({
        message: 'Task name successfully copied to clipboard',
        description: name,
      })
    },
    openTaskDetails(taskId, id) {
      this.$router.push(`/prisma/${this.userLogin}/${this.userUUID}/${taskId}/${id}/task-details`)
    },
  },
}
</script>
  <style lang="scss">
  </style>
