<template>
  <a href="#" class="font-size-24 cloud-upload">
    <router-link target="_blank" to="/cloud-store">
      <a-icon type="cloud-upload" v-add-class-hover="'text-primary'" />
    </router-link>
  </a>
</template>

<script>
export default {
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
