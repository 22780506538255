<template>
  <div style="padding: 0; height: 3.5rem;">
    <a href="javascript:" class="font-size-24" @click="showKeyModal">
      <!-- <a-icon type="key" :class="decryptionKey ? 'text-primary' : 'text-gray-8'" v-add-class-hover="'text-primary'" /> -->
      <a-icon type="key" v-add-class-hover="'text-primary'" />
    </a>
    <a-modal
      v-model="keyModal"
      title="Enter Decryption Key"
      :footer="null"
      class="profile-modal"
      width="600px"
      :destroyOnClose="true"
    >
      <decryption-key-modal
        @closeModalFunction="handleCloseModal"
        @submitModalFunction="handleSubmitModal"
        @deleteModalFunction="handleDeleteModal"
      />
    </a-modal>
  </div>
</template>

<script>
import decryptionKeyModal from '@/views/encryption/privateKeyModal'

export default {
  components: {
    decryptionKeyModal,
  },
  data: function () {
    return {
      keyModal: false,
      decryptionKey: !!sessionStorage.getItem('app.encryption.privatekey'),
    }
  },
  methods: {
    showKeyModal() {
      this.keyModal = true
    },
    handleCloseModal() {
      this.keyModal = false
    },
    handleSubmitModal(e) {
      this.keyModal = false
      this.decryptionKey = !!e
    },
    handleDeleteModal(e) {
      this.decryptionKey = !!e
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
