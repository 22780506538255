<template>
  <div class="segments-modal">
    <transition
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
      enter-active-class="modal-transition"
      leave-active-class="modal-transition"
      mode="out-in"
    >
      <component :is="currentComponent" @selectSegmentType="handleSelectType" @createSegment="createSegment" @closeModal="closeModal" />
    </transition>
  </div>
</template>
<script>

import ViewscreenService from '@/services/api/apiViewscreenService'
import UserTypeScreen from '@/components/custom/modals/segmentsModal/components/userTypeScreen.vue'
import MainSegmentScreen from '@/components/custom/modals/segmentsModal/components/mainSegmentScreen.vue'

export default {
  name: 'segmentsModal',
  props: [],
  components: { UserTypeScreen, MainSegmentScreen },
  data: () => ({
    loading: false,
    currentComponent: 'UserTypeScreen',
    selectedUserType: '',
  }),
  computed: {
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    handleSelectType(userType) {
      this.selectedUserType = userType
      this.currentComponent = 'MainSegmentScreen'
    },
    async createSegment(name, importFile) {
      this.loading = true

      const formData = new FormData()
      formData.append('name', name)
      formData.append('visibility', this.selectedUserType)
      if (importFile) {
        const file = importFile
        formData.append('accounts', file)
      }
      try {
        const segmentsResponse = await ViewscreenService.createSegment(formData)

        if (segmentsResponse.data) {
          const { total_user_count, count } = segmentsResponse.data.data

          this.$notification.success({
            message: 'Segment has been successfully imported',
            ...(total_user_count > 0 && {
              description: `The number of successfully loaded users is ${count} / ${total_user_count}`,
            }),
          })

          this.submitSegmentModal(segmentsResponse.data.data)
        }
      } catch (err) {
        this.$notification.error({
          message: 'Something went wrong',
          description: err.response.data.error.message,
        })
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    submitSegmentModal(newSegment) {
      this.$emit('addNewSegment', newSegment)
    },
  },
  async created() {
  },
}
</script>

<style lang="scss">
.promo-export {
  &__radio {
    display: block !important;
    height: 30px;
    line-height: 30px;
  }
}
</style>
