<template>
  <div class="dinamic-page">
    <a-layout>
      <a-layout>
        <a-layout-header>
          <div class="inner-header">
            <a href="javascript:;"></a>
            <div class="select-language-block" v-if="default_language">
              <a-select :default-value="default_language" @change="handleSelectlanguage">
                <a-select-option value="ru">
                    RU
                </a-select-option>
                <a-select-option value="kz">
                    KZ
                </a-select-option>
                <a-select-option value="en">
                    EN
                </a-select-option>
              </a-select>
            </div>
          </div>
        </a-layout-header>
        <a-layout-content style="position: relative; background: rgba(240,242,245,1);">
          <a-card>
            <div class="content" v-if="page">
              <div v-html="html" />
            </div>
            <div class="dimmy" v-else>
              <a-skeleton :paragraph="{ rows: 2 }" active />
              <a-skeleton :paragraph="{ rows: 4 }" active />
              <a-skeleton :paragraph="{ rows: 12 }" active />
            </div>
          </a-card>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from '@/services/axios'

export default {
  data: () => ({
    default_language: null,
    html: "",
    page: null,
    event: null,
  }),
  async created() {
    try {
      console.log(this.$route.params)

      await this.fetchEvent(this.$route.params.event)
      await this.fetchPage(this.$route.params.page)
      this.insertStyle()
      this.default_language = this.page.default_language
      this.handleSelectlanguage(this.page.default_language)
    } catch (e) {
      this.$notification.error({
        message: 'Error',
        description: 'Ошибка при загрузке страницы',
      })
    }
  },
  methods: {
    insertStyle() {
      if (!this.page) {
        return false
      }

      const css = this.page.style
      const head = document.head || document.getElementsByTagName('head')[0]
      const style = document.createElement('style')

      head.appendChild(style);

      style.type = 'text/css';
      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    },
    handleSelectlanguage(value) {
      switch (value) {
        case 'ru':
          this.html = this.page.html_ru;
          break;
        case 'kz':
          this.html = this.page.html_kz;
          break;
        case 'en':
          this.html = this.page.html_en;
          break;
        default:
          break;
      }
    },
    fetchEvent(eventUrlName) {
      const _this = this
      return new Promise((resolve, reject) => {
        try {
          
          apiClient.get(`/admin/events/event?url_name=${eventUrlName}`)
            .then(({ data }) => {
              setTimeout(() => {
                _this.event = data.data
                resolve(data.data)
              }, 314)
            }).catch((error) => {
              reject(error)
            })
        } catch (e) {
          reject(e)
        }
      })
    },
    fetchPage(pageUrlName) {
      const _this = this
      return new Promise((resolve, reject) => {
        try {
          apiClient.get(`admin/events/${_this.event.id}/pages/page?page_url=${pageUrlName}`)
            .then(({ data }) => {
              setTimeout(() => {
                _this.page = data.data
                resolve(data.data)
              }, 314)
            }).catch((error) => {
              reject(error)
            })
        } catch (e) {
          reject(e)
        }
      })
    },
  },
}
</script>

<style lang="scss">
  .dinamic-page {
    .ant-card-body {
      padding: 0px !important;
    }
    .inner-header {
      display: flex;
      justify-content: space-between;
      padding: 0px 16px;
    }
    .dimmy {
      padding: 0px 16px;
    }
    .select-language-block {
      
    }
  }
</style>
