<template>
  <a-button
    @mouseover="over"
    @mouseleave="leave"
    @click="clickEvent"
    :size="size"
    :disabled="disabled"
  >
    <slot></slot>
  </a-button>
</template>

<script>
export default {
  props: ['size', 'disabled'],
  methods: {
    over(e) {
      e.target.classList.add('ant-btn-danger', 'ant-btn-background-ghost')
    },
    leave(e) {
      e.target.classList.remove('ant-btn-danger', 'ant-btn-background-ghost')
    },
    clickEvent() {
      this.$emit('click')
    },
  },
  mounted() {
  },
}
</script>

<style>
/* [data-kit-theme="default"] .ant-btn:active, [data-kit-theme="default"] .ant-btn.active {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
[data-kit-theme="default"] .ant-btn:focus {
  color: #ff4d4f;
  border-color: #ff4d4f;
} */
</style>
