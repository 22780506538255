import { JSEncrypt } from 'jsencrypt'

export default {

  install(Vue, options) {
    Vue.mixin({
      created() {
        // console.log('RSACrypt mounted')
      },
    })

    Vue.prototype.$encryptData = function(publicKey, data) {
      const encrypt = new JSEncrypt()
      encrypt.setPublicKey(publicKey)
      return encrypt.encrypt(data)
    }
    Vue.prototype.$decryptData = function(privateKey, data) {
      const decrypt = new JSEncrypt()
      decrypt.setPrivateKey(privateKey)
      return decrypt.decrypt(data)
    }
  },
}
