<template>
  <a-modal
      v-model="visible"
      :title="title"
      class="add-login--modal"
      @ok="handleSubmit"
      @cancel="handleCancel"
      :ok-button-props="{ props: { loading: loading } }"
    >
      <a-form :form="form" v-if="data">
        <a-form-item label="Login">
            <a-row :gutter="0">
              <a-col :span="24">
                <a-input
                  :max-length="10"
                  placeholder="Enter login"
                  v-decorator="[
                    'data.login',
                    {
                      initialValue: data.code,
                      rules: [
                        { required: true, message: 'Login is required!' },
                        { min: 7, message: 'At least 7 characters' },
                      ]
                    }
                  ]"
                >
                </a-input>
              </a-col>
            </a-row>
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
/* eslint-disable */
import apiClient from '@/services/axios';

const templateLogin = {
  login: '',
}

export default {
  props: [ 'codeInfo' ],
  data() {
    return {
      data: null,
      visible: false,
      form: this.$form.createForm(this, {
        name: 'code',
      }),
      loading: false,
      title: "Add login",
    }
  },
  computed: {
    checkDisabledCodeInput() {
      if (this.data.id) {
        return true;
      }
      return false;
    }
  },
  methods: {
    generateCode() {
      function makeid(length) {
          var result           = '';
          var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          var charactersLength = characters.length;
          for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() *  charactersLength));
        }
        return result;
      }
      const code = makeid(5);
      this.form.resetFields(['data.code']);
      this.data.code = code;
    },
    async handleSubmit(e) {
      e.preventDefault()
      const _this = this;
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await this.addLogin({
            login: values.data.login,
            code: _this.codeInfo.code,
          })
        }
      })
    },
    async addLogin(payload) {
      try {
        this.loading = true
        await apiClient.post('external/affiliates/join', { ...payload })
        this.$notification.success({
          message: 'Success',
          description: 'User added successfully',
          duration: 2,
        });
        this.$emit('update');
        this.visible = false;
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        });
      } finally {
        this.loading = false;
      }
    },
    showModal() {
      this.loading = false
      this.data = {...templateLogin};
      this.form.resetFields()
      this.visible = true
    },
    handleCancel() {
      this.visible = false;
    },
  },
}
</script>

<style lang="scss">
</style>
