<template>
  <div class="olimp-admin-form admin-gifs">
    <a-form-model
      ref="gifForm"
      :model="gifForm"
      :rules="gifRules"
    >
      <a-row>
        <a-col :span="12" class="pr-2">
          <a-tabs v-model="activeTab" @change="onTabChange">
            <a-tab-pane key="pc" tab="Import from PC" class="gif-tab-upload" force-render>
              <div style="position:absolute; display: inline-block; margin: 0 0 0 60px">
                <a-tooltip :title="`File Type: gif \n Ratio: 1.2 - 2.5 : 1 \n File Size: up to ${maxUploadSize}MB`" placement="right" :overlayStyle="{ whiteSpace: 'pre-line' }">
                  <a href="javascript:"><a-icon class="text-primary" type="info-circle" /></a>
                </a-tooltip>
              </div>
              <div class="d-block font-weight-bold mb-2">Gif File:</div>
              <a-upload
                name="gif-upload"
                action="#"
                list-type="picture"
                class="gif-uploader"
                accept=".png,.jpg,.gif,.webp"
                :preview-file="previewFile"
                :before-upload="beforeUpload"
                :defaultFileList="activeObj.img_path ? [{ uid: '1', name: activeObj.img_path.substring(activeObj.img_path.lastIndexOf('/') + 1), status: 'done', url: activeObj.img_path, thumbUrl: activeObj.img_path }] : []"
                @change="fileChange"
                :remove="fileRemove"
              >
                <div v-if="gifForm.uploadVisible" style="margin-top: 6px !important;">
                  <a-button class="mt-0" > <a-icon type="upload" /> Upload </a-button>
                </div>
              </a-upload>
            </a-tab-pane>
            <a-tab-pane key="url" tab="Import from URL" class="gif-tab-upload" force-render>
                <div style="position:absolute; display: inline-block; margin: 0 0 0 96px">
                  <a-tooltip :title="`File Type: gif \n Ratio: 1.2 - 2.5 : 1 \n File Size: up to ${maxUploadSize}MB`" placement="right" :overlayStyle="{ whiteSpace: 'pre-line' }">
                  <a href="javascript:"><a-icon class="text-primary" type="info-circle" /></a>
                  </a-tooltip>
                </div>
              <div class="d-block font-weight-bold">URL Source:</div>
              <div class="d-flex">
                <a-form-model-item ref="url_source" prop="url_source" class="mt-1">
                  <a-input v-model="urlSource" placeholder="Input File Url" size="large" class="input-url-media" />
                </a-form-model-item>
                <a-tooltip title="Download & preview" placement="bottom">
                  <a-button @click="getExternalImageSrc" class="extImportBtn ml-auto text-right" size="large">
                    <a-icon type="cloud-download" class="font-size-18 text-primary"/>
                  </a-button>
                </a-tooltip>
              </div>
            </a-tab-pane>
          </a-tabs>
          <a-form-model-item label="Emotions" prop="emotion_id" class="mt-4">
            <a-radio-group v-model="gifForm.emotion_id">
              <a-radio value="2">
                Win
              </a-radio>
              <a-radio value="3">
                Fail
              </a-radio>
              <a-radio value="1">
                Waiting
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-2 text-left">
          <p style="margin-top: 10px">Preview:</p>
          <div class="gif-preview">
            <div v-if="extPreviewLoading" class="gif-preview-loading"><a-icon type="loading" class="icn-loading" /></div>
            <img v-if="((previewSrc || activeObj.img_path) && (activeTab === 'pc'))" :src="activeObj.img_path || previewSrc" class="gif-preview-img" />
            <img v-if="((extPreviewSrc) && (activeTab === 'url'))" :src="extPreviewSrc" class="gif-preview-img" />
          </div>
          <div v-if="activeTab === 'pc' && pcFile.file && !gifForm.uploadVisible" class="gif-info-area">
            <span v-if="pcFile.width">DIMS: {{ pcFile.width }} x {{ pcFile.height }} px |</span>
            Size: {{ pcFile.file.size ? (pcFile.file.size / (1024 * 1024)).toFixed(2) : 0 }} MB |
            Type: {{ pcFile.file.type ? pcFile.file.type : '' }}
            <div v-if="activeObj.preview_path || activeObj.mp4_path" class="mt-1">
              <span v-if="activeObj.preview_path">
                <a-tooltip title="Open JPEG Preview" placement="bottom">
                  <a :href="activeObj.preview_path" target="_blank" class="ml-2"><a-icon type="file-jpg" /></a>
                </a-tooltip>
              </span>
              <span v-if="activeObj.mp4_path">
                <a-tooltip title="Open MP4 Video File" placement="bottom">
                  <a :href="activeObj.mp4_path" target="_blank" class="ml-2"><a-icon type="video-camera" /></a>
                </a-tooltip>
              </span>
            </div>
          </div>
          <div v-if="activeTab === 'url' && extFile.file" class="gif-info-area">
            <span v-if="extFile.width">DIMS: {{ extFile.width }} x {{ extFile.height }} px |</span>
            Size: {{ extFile.file.size ? (extFile.file.size / (1024 * 1024)).toFixed(2) : 0 }} MB |
            Type: {{ extFile.file.type ? extFile.file.type : '' }}
          </div>
<!--          <img id="imageExternal" :src="urlSource" style="max-width: 200px" />-->
        </a-col>
      </a-row>
      <hr>
      <div class="mt-1">
        <div class="d-flex">
          <div class="mr-auto">
            <!-- <a-button v-if="!activeObj.id" key="back" @click="closeModal">
              Cancel
            </a-button> -->
            <CustomButton v-if="!activeObj.id" key="back" @click="closeModal">
              Cancel
            </CustomButton>
            <a-popconfirm v-else
              title="Sure to delete?"
              @confirm="deleteObj"
            >
              <!-- <a-button>Delete</a-button> -->
              <CustomButton>Delete</CustomButton>
            </a-popconfirm>
          </div>
          <div v-if="loading" class="processing-info">
            <span v-if="submitLoading.record">Processing record ...</span>
            <span v-if="submitLoading.upload">Uploading file ...</span>
            <span v-if="submitLoading.watermark">Processing image. Adding watermark ...</span>
          </div>
          <div>
            <a-button key="submit" type="primary" :loading="loading" @click="onSubmit" class="auto" :disabled="submitDisabled">
              {{ activeObj.id ? 'Save' : 'Create' }}
            </a-button>
          </div>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix'
import CustomButton from '@/components/custom/button/CustomButton'

export default {
  name: 'gifModal',
  props: ['activeObj'],
  mixins: [accessMix],
  components: {
    CustomButton,
  },
  computed: {
    submitDisabled() {
      return !this.activeObj.id && ((!this.previewSrc && this.activeTab === 'pc') || (!this.extPreviewSrc && this.activeTab === 'url'))
    },
  },
  data() {
    return {
      loading: false,
      urlSource: '',
      activeTab: 'pc',
      previewSrc: undefined,
      extPreviewSrc: undefined,
      extPreviewLoading: false,
      externalFile: { uid: '161563164156', name: 'file', status: 'done', url: undefined, thumbUrl: undefined, originFileObj: undefined },
      uploadFileList: [],
      uploadWithWatermark: false,
      submitLoading: { record: false, upload: false, watermark: false },
      maxUploadSize: 10, // in MB
      pcFile: {
        file: this.activeObj.img_path ? {
          uid: '1',
          name: this.activeObj.img_path.substring(this.activeObj.img_path.lastIndexOf('/') + 1),
          status: 'done',
          url: this.activeObj.img_path,
          thumbUrl: this.activeObj.img_path,
          size: this.activeObj.img_fs,
          type: this.activeObj.img_ft,
        } : null,
        width: this.activeObj ? this.activeObj.img_width : null,
        height: this.activeObj ? this.activeObj.img_height : null,
      },
      extFile: { file: null, fileSize: null, fileType: null, width: null, height: null },
      gifForm: {
        // name: this.gifObj.name,
        emotion_id: this.activeObj ? `${this.activeObj.emotion_id}` : '2',
        uploadVisible: !this.activeObj.img_path,
        file: null,
        updateFile: false,
        deleteFile: false,
      },
      gifRules: {
        emotion_id: [{ required: true, message: 'Please check emotion', trigger: 'change' }],
      },
    }
  },
  methods: {
    closeModal() {
      console.log('closeEditModal')
      this.$emit('closeModalFunction')
    },
    onTabChange(key) {
      if (key === 'pc') { this.gifForm.file = this.pcFile.file } else { this.gifForm.file = this.extFile.file }
    },
    onSubmit() {
      this.$refs.gifForm.validate(valid => {
        if (valid) {
          this.activeObj.id ? this.updateObj() : this.createObj()
        } else {
          console.log('error update submit!!')
          return false
        }
      })
    },
    createObj() {
      this.loading = true
      this.submitLoading.record = true
      const url = '/admin/gifs'
      return apiClient.post(url, this.gifForm).then((response) => {
        const obj = response.data.data
        this.$notification.success({
          message: 'Gif successfully created',
          description: '',
        })
        this.submitLoading.record = false
        if (this.gifForm.updateFile) {
          this.submitLoading.upload = true
          this.uploadMedia(obj).then(result => {
            this.submitLoading.upload = false
            if (this.uploadWithWatermark === true) {
              this.submitLoading.watermark = true
              this.watermarkMedia(obj).then(result => {
                this.submitLoading.watermark = false
                this.$emit('changeGif', obj)
              })
            } else {
              this.$emit('changeGif', obj)
            }
          })
        } else {
          this.$emit('createGif', obj)
          this.loading = false
        }
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while updating Gif',
          description: error.message,
        })
        this.loading = false
        this.submitLoading.record = false
      })
    },
    updateObj() {
      this.loading = true
      this.submitLoading.record = true
      const url = '/admin/gifs/' + this.activeObj.id
      return apiClient.patch(url, this.gifForm).then((response) => {
        const obj = response.data.data
        this.$notification.success({
          message: 'Gif successfully updated',
          description: '',
        })
        this.submitLoading.record = false
        if (this.gifForm.updateFile) {
          this.submitLoading.upload = true
          this.uploadMedia(obj).then(result => {
            this.submitLoading.upload = false
            if (this.uploadWithWatermark === true) {
              this.submitLoading.watermark = true
              this.watermarkMedia(obj).then(result => {
                this.submitLoading.watermark = false
                this.$emit('changeGif', obj)
              })
            } else {
              this.$emit('changeGif', obj)
            }
          })
        } else {
          if (this.gifForm.deleteFile) {
            this.deleteMedia(obj).then(result => { this.$emit('changeGif', obj) })
          } else { this.$emit('changeGif', obj) }
        }
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while updating Gif',
          description: error.message,
        })
        this.loading = false
        this.submitLoading.record = false
      })
    },
    watermarkMedia(obj) {
      const url = '/admin/gifs/' + obj.id + '/watermark'
      return apiClient.post(url).then((response) => {
        this.$notification.success({
          message: 'Watermark added successfully',
          description: '',
        })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while adding watermark',
          description: error.message,
        })
        this.loading = false
        this.submitLoading.watermark = false
      })
    },
    uploadMedia(obj) {
      const url = '/admin/gifs/' + obj.id + '/upload'
      const formData = new FormData()
      formData.append('gif', this.gifForm.file)
      return apiClient.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.$notification.success({
          message: 'Gif file uploaded',
          description: '',
        })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while uploading file',
          description: error.message,
        })
        this.loading = false
        this.submitLoading.upload = false
      })
    },
    deleteMedia(obj) {
      const url = '/admin/gifs/' + obj.id + '/file'
      return apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'File successfully deleted',
          description: '',
        })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting file',
          description: error.message,
        })
        this.loading = false
      })
    },
    deleteObj() {
      const url = '/admin/gifs/' + this.activeObj.id
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'Gif successfully deleted',
          description: '',
        })
        this.$emit('deleteGif', this.activeObj)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting Gif',
          description: error.message,
        })
      })
    },
    async previewFile(file) {
      console.log('Your upload file:', file)
      const base64 = await this.toBase64(file)
      // this.form.stories[this.activeKey].file = file
      this.previewSrc = base64
      console.log('Your upload base64 file:', base64)
      // Your process logic. Here we just mock to the same file
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    getBase64ImageFromUrl(url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function (e) {
        console.log(this.response)
        const reader = new FileReader()
        reader.onload = function(event) {
          const res = event.target.result
          console.log('ext image src:', res)
          callback(event.target.result, file)
        }
        reader.onerror = () => {
          this.extPreviewLoading = false
          reader.abort()
        }
        const file = this.response
        reader.readAsDataURL(file)
      }
      xhr.onerror = () => { this.extPreviewLoading = false }
      xhr.send()
    },
    getExternalImageSrc() {
      if (!this.isValidUrl(this.urlSource)) {
        this.$message.error('Not valid URL')
        return false
      }
      const self = this
      this.extPreviewLoading = true
      this.getBase64ImageFromUrl(this.urlSource, function(img64, file) {
        return new Promise((resolve, reject) => {
          self.beforeUpload(file).then(result => {
            console.log('imgFile', file)
            self.extFile.file = file
            self.extFile.width = result.width
            self.extFile.height = result.height
            self.gifForm.file = self.extFile.file
            self.gifForm.updateFile = true
            self.extPreviewSrc = img64
            self.extPreviewLoading = false
            resolve(file)
          })
            .catch(err => { console.log('reject promise', err); self.extPreviewLoading = false })
        })
      })
    },
    fileChange(info) {
      this.pcFile.file = info.file.originFileObj
      this.gifForm.file = this.pcFile.file
      if (info.fileList.length > 0) {
        this.gifForm.updateFile = true
        this.gifForm.uploadVisible = false
      } else {
        this.activeObj.img_path = null
        this.gifForm.updateFile = false
        this.gifForm.uploadVisible = true
      }
      console.log('file: ', this.gifForm.file)
      console.log('to update: ' + this.gifForm.updateFile)
      console.log('to delete: ' + this.gifForm.deleteFile)
      console.log('upload btn visible: ' + this.gifForm.uploadVisible)
    },
    fileRemove(info) {
      this.gifForm.deleteFile = true
      this.gifForm.uploadVisible = true
      this.pcFile.file = null
      this.previewSrc = null
      console.log('after delete image -> file', this.pcFile.file)
      console.log('deleteFile = true')
    },
    beforeUpload(file) {
      const strictType = ['image/gif']
      const isType = strictType.includes(file.type)
      const isLtMB = file.size / 1024 / 1024 < this.maxUploadSize
      // const mustWidth = 60
      if (!isType) {
        this.$message.error('You can only upload gif type file!')
      }
      if (!isLtMB) {
        this.$message.error(`File size must be smaller than ${this.maxUploadSize}MB!`)
      }
      return new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = (err) => {
          const inProportions = Math.round(10 * (img.width / img.height)) / 10 >= 1.2 && Math.round(10 * (img.width / img.height)) / 10 <= 2.5
          // const inWidth = img.width === mustWidth
          // if (!inWidth) {
          //   this.$message.error(`Image dimensions must be ${mustWidth}х${mustWidth} px`)
          // }
          console.log('width + height', img.width + 'x' + img.height)
          if (!inProportions) {
            this.$message.error('Gif must be in correct proportions. 1.2 - 2.5 : 1 (e.g. 480 x 340 px)')
          }
          if (inProportions && isLtMB && isType) {
            resolve(img)
            if (this.activeTab === 'pc') {
              this.pcFile.width = img.width
              this.pcFile.height = img.height
            }
          } else {
            reject(err)
          }
        }
      })
    },
    isValidUrl(url) {
      try {
        return new URL(url)
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
}
</script>
<style lang="scss">
@import '@/components/mixins.scss';
//.color-picker {
  //  .ant-input { width: 100px !important; }
  //}
  // .color-picker-container { background: transparent; border: 1px  }
  .admin-gifs {
    .ant-upload {
      margin-top: -12px;
      button {
        padding: 0 16px !important;
      }
      .anticon {
        margin-right: 4px !important;
      }
    }
    .gif-tab-upload {
      height: 104px;
      max-height: 104px;
    }
    .gif-info-area {
      margin: 4px 2px 0 0;
      text-align: right;
      font-size: 0.75rem;
      color: $gray-8;
    }
    .extImportBtn {
      //display: inline-block !important;
      margin: 4px 0 0 0 !important;
    }
    .input-url-media {
      width: 274px;
    }
    .gif-uploader {
      //margin-top: 0 !important;
    }
    .processing-info {
      font-size: 0.8rem;
      padding: 0 8px 0 0;
      margin-top: 6px;
      color: $gray-8;
    }
    .gif-preview {
      width: 324px;
      height: 200px;
      border: 2px dashed #e4e9f0;
      text-align: center;
      .gif-preview-loading {
        position: absolute;
        text-align: center;
        width: 80px;
        height: 80px;
        background: transparent;
        margin: calc((200px - 80px) / 2) 0 0 calc((324px - 80px) / 2);
        .icn-loading {
          font-size: 80px;
          color: $primary;
        }
      }
      img.gif-preview-img {
        margin: 6px auto;
        max-width: 320px;
        max-height: 185px;
      }
    }
  }
</style>
<style scoped>

</style>
