<template>
  <div>
    <a-row class="d-flex align-items-center mb-4 justify-items-center d-flex sticky-top"
      style="margin: -30px; padding: 22px 30px; background: #fff">
      <a-col :span="18">
        <a-breadcrumb>
            <a-breadcrumb-item><a style="color: #1890FF" @click="redirectToRoute">{{breadcrumbRouteTitle}}</a></a-breadcrumb-item>
            <a-breadcrumb-item><a style="color: rgba(0, 0, 0, 0.45)" href="javascript:;">{{breadcrumbChildTitle}}</a></a-breadcrumb-item>
          </a-breadcrumb>
        <div class="d-flex align-items-end mt-2">
          <h3 class="mb-0 mr-4" style="font-size: 20px">{{ promoTitle }}</h3>
          <a-switch v-model="form.is_active" />
        </div>
      </a-col>
      <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end">
        <div v-if="isEditMode">
          <a-popconfirm title="Sure to delete?" placement="bottom" @confirm.stop="preformDeletePromo">
            <a-button type="danger"> Delete </a-button>
          </a-popconfirm>
          <a-button type="primary" class="ml-4" @click="update">
            Update
          </a-button>
        </div>
        <div v-else>
          <a-button type="primary" class="ml-4" @click="save">
            Create
          </a-button>
        </div>
      </a-col>
    </a-row>
    <div style="padding: 24px; background-color: #fff">
      <a-form-model :rules="rules" :model="form" ref="form">
        <div class="d-flex flex-column promo-form">
          <span class="font-weight-bold font-size-16">General</span>
          <a-form-model-item label="Type" prop="promoType" :colon="false">
            <a-radio-group :disabled="isFormClear" name="radioGroup" v-model="promoType" :default-value="promoType">
              <a-radio value="banner"> Banner </a-radio>
              <a-radio value="story"> Story </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="Name" prop="promoName" :colon="false" style="max-width: 270px">
            <a-input v-model="form.promoName" placeholder="Name" size="large" :disabled="loading" />
          </a-form-model-item>
          <div class="d-flex flex-row align-items-start" style="gap: 1rem">
            <a-form-model-item label="Start date" prop="startDate" :colon="false" class="d-inline-block pr-1">
              <a-date-picker v-model="form.startDate" show-time format="YYYY-MM-DD HH:mm:ss" type="date" size="large"
                placeholder="Select date" :disabled="loading" />
            </a-form-model-item>
            <a-form-model-item label="End date" prop="endDate" :colon="false" class="d-inline-block pr-1">
              <a-date-picker v-model="form.endDate" show-time format="YYYY-MM-DD HH:mm:ss" type="date" size="large"
                placeholder="Select date" :disabled="loading" />
            </a-form-model-item>
          </div>
          <a-form-model-item label="Display section" prop="promoSections" :colon="false">
            <a-select mode="multiple" :disabled="loading || isViewscreen" v-model="form.promoSections" style="max-width: 200px"
              size="large">
              <template #placeholder><span style="color: #a1a1c2">Select display</span></template>
              <a-select-option v-for="display in promoDisplays" :key="display.value" :value="display.value">
                {{ display.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="!isViewscreen" prop="visibility" label="Promo visibility">
            <a-select v-model="form.visibility" size="large" :disabled="loading" allow-clear style="max-width: 350px;">
              <template #placeholder><span style="color: #a1a1c2">Select visibility setting</span></template>
              <a-select-option v-for="visibilityOption in visibilityOptions" :key="visibilityOption.value" :value="visibilityOption.value">
                {{ visibilityOption.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <div>
            <a-form-model-item prop="promoApps" required :colon="false" class="d-block">
              <template #label>
                <span class="mr-4">Apps</span>
                <a @click.stop="openAddAppModal" style="color: rgb(0, 123, 255) !important; font-weight: 400">+ Add</a>
              </template>
              <div v-if="form.promoApps">
                <a-tag style="
                    margin-right: 15px;
                    padding: 1px 8px;
                    border-radius: 2px;
                  " v-for="app in form.promoApps.slice(0, 5)" :key="app.id">
                  {{ app.name }}
                  <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
                  <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
                  <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
                </a-tag>
                <a-tag v-if="form.promoApps.length > 4"> ... </a-tag>
              </div>
            </a-form-model-item>
          </div>
          <a-divider class="m-0 mb-3" />
          <div>
            <!-- TODO DELETE AFTER API UPDATE -->
            <div v-if="promoType === 'story'" class="d-flex flex-row align-items-center justify-content-between mb-2">
              <span class="font-weight-bold font-size-16">Media</span>
              <div class="checkbox-container">
                <a-button type="link" :disabled="!isApllyToAllAvailable" @click="addToAllLangs">
                  Apply to all languages
                </a-button>
              </div>
            </div>
            <div v-if="promoType === 'banner'">
              <div>
                <a-form-model-item style="clear:both;">
                  <template #label><span class="required-label">Languages</span></template>
                  <a-checkbox class="" :indeterminate="indeterminateLang" :checked="checkAllLangs"
                    @change="onCheckAllLangChange" :disabled="loading">
                    Select all
                  </a-checkbox>
                  <a-checkbox-group class="banners_lagnuages checkbox-item grid-langs" v-model="checkedLangList"
                    :options="langOptions" @change="onLangChange" :disabled="loading" />
                </a-form-model-item>
              </div>
              <div class="images">
                <div class="d-flex flex-column">
                  <span class="mb-2 title required-label">Main</span>
                  <div class="main-container">
                    <div>
                      <a-upload list-type="picture-card" class="uploader main" :show-upload-list="false"
                        @change="handleBannerUpload" :disabled="loading">
                        <div>
                          <div v-if="bannerImageUrl" class="banner-image">
                            <img :src="bannerImageUrl" />
                          </div>
                          <template v-else>
                            <a-icon :type="loading ? 'loading' : 'inbox'" class="text-primary"
                              style="font-size: 48px" />
                            <div>
                              Click or drag file to this area to upload<br />
                              <span v-if="isWebAppChosen" class="text">File size 2000px x 640px</span>
                              <span v-else class="text">File size 1160px x 414px</span><br />
                            </div>
                          </template>
                        </div>
                      </a-upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="promoType === 'story'">
              <a-tabs :default-active-key="languages[0].name" :active-key="selectedLanguage" @change="handleTabChange"
                :tabBarStyle="{ border: 'none' }">
                <a-tab-pane v-for="language in languages" :key="language.name" :tab="language.desc" />
              </a-tabs>
              <div class="images">
                <div class="d-flex flex-column">
                  <span class="mb-2 title required-label">Preview</span>
                  <div class="preview-container">
                    <div>
                      <a-upload list-type="picture-card" class="uploader preview" :show-upload-list="false"
                        @change="handlePreviewUpload" :disabled="loading">
                        <div>
                          <div v-if="selectedPreview.url" class="preview-image">
                            <img :src="selectedPreview.url" />
                          </div>
                          <template v-else>
                            <a-icon :type="selectedPreview.loading ? 'loading' : 'plus'" />
                            <div class="text">
                              File size<br />
                              500 x 500 px
                            </div>
                          </template>
                        </div>
                      </a-upload>
                    </div>
                  </div>
                </div>
                <div v-if="type === 'image'" class="d-flex flex-row">
                  <div class="d-flex flex-row" style="gap: 0.5rem">
                    <div>
                      <span class="mb-2 title required-label">Stories</span>
                      <a-upload list-type="picture-card" class="uploader stories" :show-upload-list="false"
                        @change="handleStoryUpload" :disabled="loading">
                        <a-icon type="plus" class="icon" />
                        <div class="text">
                          File size<br />
                          1400 x 2200px
                        </div>
                      </a-upload>
                    </div>
                    <div class="story-list" :class="{ disabled: loading }" ref="storyList">
                      <div v-for="(story, index) in selectedStories" :key="story.id" class="story-item">
                        <template v-if="story.preview">
                          <span class="mb-2 title">{{ story.name }}</span>
                          <a-upload list-type="picture-card" class="uploader stories" :show-upload-list="false"
                            @change="handleStoryUpload" :openFileDialogOnClick="isModalOpenOnClick">
                            <video v-if="story.isVideo" autoplay muted loop :src="story.preview" />
                            <img v-else :src="story.preview" />
                            <div v-if="!loading" class="overlay">
                              <div class="edit-story" @click="editStory(index)">
                                <a-icon type="edit" class="text-white" />
                              </div>
                              <div class="delete-story" @click="deleteStory(index)">
                                <a-icon type="delete" class="text-white" />
                              </div>
                            </div>
                          </a-upload>
                        </template>
                        <template v-else>
                          <div class="loader">
                            <a-icon type="loading" />
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="promoType === 'banner'">
            <div class="d-flex flex-row align-items-center mb-2">
              <span class="font-weight-bold font-size-16 mr-3">Redirect</span>
            </div>
            <div class="mb-4">
              <div class="d-flex flex-column redirect-list">
                <div class="d-flex flex-row align-items-end mb-2 mr-4" style="gap: 1rem">
                  <a-form-model-item>
                    <template #label><span class="required-label">Link type</span></template>
                    <a-select v-model="bannerRedirect.type" size="large" :disabled="loading" allow-clear>
                      <template #placeholder><span style="color: #a1a1c2">Select link type</span></template>
                      <a-select-option value="custom"> Custom </a-select-option>
                      <a-select-option value="sport"> Sport </a-select-option>
                      <a-select-option value="match"> Match </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <div v-if="bannerRedirect.type === 'custom'" class="d-flex flex-ro align-items-end">
                    <a-form-model-item :colon="false" class="mr-3" style="min-width: 416px">
                      <template #label><span class="required-label">Link</span></template>
                      <a-input placeholder="https://www.olimp.bet/line/1/5956496/78487617" :disabled="loading"
                        v-model="bannerRedirect.link" size="large">
                      </a-input>
                    </a-form-model-item>
                    <a-button type="primary" size="large" class="mr-4" :disabled="loading"
                      @click="openAddLinkTemplatesModal">Create new</a-button>
                    <a-form-model-item label="Select template">
                      <a-select v-model="bannerRedirect.id" @change="changeRedirectTemplate(0, bannerRedirect.id)"
                        size="large" :disabled="loading" allow-clear>
                        <template #placeholder><span style="color: #a1a1c2">Template</span></template>
                        <a-select-option v-for="linkTemplate in linkTemplates" :key="linkTemplate.id">
                          {{ linkTemplate.name }}
                          <span style="color: #a1a1c2;">[{{ linkTemplate.link }}]</span>
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                  <a-form-model-item v-if="bannerRedirect.type === 'match'" :colon="false"
                    class="mr-3" style="min-width: 416px">
                    <template #label><span class="required-label">Banner link</span></template>
                    <a-input placeholder="https://www.olimp.bet/line/1/5956496/78487617" :disabled="loading"
                      v-model="bannerRedirect.link" size="large">
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item v-if="bannerRedirect.type === 'sport'">
                    <template #label><span class="required-label">Select sport</span></template>
                    <a-select v-model="bannerRedirect.sportId" size="large" :disabled="loading" allow-clear>
                      <template #placeholder><span style="color: #a1a1c2;">Select sport</span></template>
                      <a-select-option v-for="sport in sports" :key="sport.sport_id">
                        {{ sport.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </div>
              </div>
            </div>
          </div>
          <div v-if="promoType === 'story'">
            <div class="d-flex flex-row align-items-center mb-2">
              <div>
                <span class="font-weight-bold font-size-16 mr-3">Redirect</span>
                <a @click.stop="handleAddRedirect" style="color: rgb(0, 123, 255) !important; font-weight: 400">+
                  Add</a>
              </div>
            </div>
            <div v-for="(redirect, redirectIndex) in selectedRedirects" :key="redirectIndex" class="mb-4">
              <div class="align-items-center mb-4">
                <a-select v-model="selectedRedirects[redirectIndex].storyId" size="large" :disabled="loading"
                  allow-clear style="max-width: 200px">
                  <template #placeholder><span style="color: #a1a1c2">Select story</span></template>
                  <a-select-option v-for="story in filteredStories" :key="story.id" :disabled="story.isChosen">
                    {{ story.name }}
                  </a-select-option>
                </a-select>
                <a-icon type="delete" @click="handleDeleteRedirect(redirectIndex)" style="
                    color: red;
                    cursor: pointer;
                    margin-left: 8px;
                    font-size: 16px;
                  " />
              </div>
              <!-- TODO MAXWIDTH STYLE -->
              <div class="d-flex flex-column redirect-list">
                <div class="d-flex flex-row align-items-center mb-2 mr-4" style="gap: 1rem">
                  <a-form-model-item :colon="false">
                    <template #label><span class="required-label">Name</span></template>
                    <a-input placeholder="Name" :disabled="noCoverRedirect(redirectIndex)"
                      v-model="selectedRedirects[redirectIndex].buttonName" style="max-width: 200px" size="large">
                      <template #placeholder><span style="color: #a1a1c2">Name</span></template>
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item prop="buttonColor" :colon="false">
                    <template #label><span class="required-label">Button color</span></template>
                    <a-input placeholder="#112233" :disabled="noCoverRedirect(redirectIndex)"
                     v-model="selectedRedirects[redirectIndex].fontColor"
                       style="max-width: 200px" size="large">
                    </a-input>
                  </a-form-model-item>
                  <!-- Font color and button color reversed is not a bug -->
                  <a-form-model-item prop="fontColor" :colon="false">
                    <template #label><span class="required-label">Font color</span></template>
                    <a-input placeholder="#112233" :disabled="noCoverRedirect(redirectIndex)"
                    v-model="selectedRedirects[redirectIndex].buttonColor" style="max-width: 200px" size="large">
                    </a-input>
                  </a-form-model-item>
                </div>
                <div class="d-flex flex-row align-items-end mb-2 mr-4" style="gap: 1rem">
                  <a-form-model-item>
                    <template #label><span class="required-label">Link type</span></template>
                    <a-select v-model="selectedRedirects[redirectIndex].linkType" size="large" :disabled="noCoverRedirect(redirectIndex)"
                      allow-clear>
                      <template #placeholder><span style="color: #a1a1c2">Select story</span></template>
                      <a-select-option value="custom"> Custom </a-select-option>
                      <a-select-option value="sport"> Sport </a-select-option>
                      <a-select-option value="match"> Match </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <div v-if="selectedRedirects[redirectIndex].linkType === 'custom'"
                    class="d-flex flex-ro align-items-end">
                    <a-form-model-item :colon="false" class="mr-3" style="min-width: 416px">
                      <template #label><span class="required-label">Link</span></template>
                      <a-input placeholder="https://www.olimp.bet/line/1/5956496/78487617" :disabled="noCoverRedirect(redirectIndex)"
                        v-model="selectedRedirects[redirectIndex].link" size="large">
                      </a-input>
                    </a-form-model-item>
                    <!-- TODO WIDTH -->
                    <a-button type="primary" size="large" class="mr-4" :disabled="noCoverRedirect(redirectIndex)"
                      @click="openAddLinkTemplatesModal">Create new</a-button>
                    <a-form-model-item label="Select template">
                      <a-select v-model="selectedRedirects[redirectIndex].id"
                        @change="changeRedirectTemplate(redirectIndex, selectedRedirects[redirectIndex].id)"
                        size="large" :disabled="noCoverRedirect(redirectIndex)" allow-clear>
                        <template #placeholder><span style="color: #a1a1c2">Template</span></template>
                        <a-select-option v-for="linkTemplate in linkTemplates" :key="linkTemplate.id">
                          {{ linkTemplate.name }}
                          <span style="color: #a1a1c2;">[{{ linkTemplate.link }}]</span>
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                  <a-form-model-item v-if="selectedRedirects[redirectIndex].linkType === 'match'"
                    :colon="false" class="mr-3" style="min-width: 416px">
                    <template #label><span class="required-label">Match link</span></template>
                    <a-input placeholder="https://www.olimp.bet/line/1/5956496/78487617" :disabled="noCoverRedirect(redirectIndex)"
                      v-model="selectedRedirects[redirectIndex].link" size="large">
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item v-if="selectedRedirects[redirectIndex].linkType === 'sport'">
                    <template #label><span class="required-label">Select sport</span></template>
                    <a-select v-model="selectedRedirects[redirectIndex].sportId" size="large" :disabled="noCoverRedirect(redirectIndex)"
                      allow-clear>
                      <template #placeholder><span style="color: #a1a1c2;">Select sport</span></template>
                      <!-- TODO WAIT FOR SPORT INSTRUCTIONS -->
                      <a-select-option v-for="sport in sports" :key="sport.sport_id">
                        {{ sport.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </div>
                <!-- <a-form-model-item label="Event" :colon="false">
                  <a-input placeholder="Text" :disabled="loading" v-model="selectedRedirects[redirectIndex].event"
                    style="max-width: 150px" size="large">
                  </a-input>
                </a-form-model-item> -->
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
      <div v-if="errors.oneFullStory" style="color: red; margin-top: 8px;">At least one tab should have both preview and story</div>
      <div v-if="errors.bothPreviewAndStory" style="color: red; margin-top: 8px">Can't add tab with preview but without story and vice versa</div>
      <div v-if="errors.isRedirectsValid" style="color: red; margin-top: 8px">All redirects should have associated story cover, name, link type and link itself</div>
      <div v-if="errors.redirectColorLength" style="color: red; margin-top: 8px">Redirect color inputs can't be longer than 7 chars</div>
      <div v-if="errors.bannerHasLang" style="color: red; margin-top: 8px">Banner should have at least one language</div>
      <div v-if="errors.bannerIsValid" style="color: red; margin-top: 8px">Banner should have all mandatory fields</div>
    </div>
    <a-modal v-model="addAppsModal" title="Add new apps" :footer="null" width="700px" :destroyOnClose="true">
      <AppsModal @selectApps="changeSelectedApps" @close="closeAddAppsModal" :modalAppsData="modalAppsData"
        @save="handleAddAppsModal" />
    </a-modal>
    <a-modal v-model="addLinkTemplateModal" width="395px" :destroyOnClose="true" @ok="handleAddTemplatesModal">
      <template #title><span class="modal-title">Add new template</span></template>
      <a-form-model :model="addTemplateForm" ref="templateForm" :rules="templateRules">
        <a-form-model-item label="Name" prop="name" :colon="false">
          <a-input placeholder="Name" :disabled="loading" size="large" v-model="addTemplateForm.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Link" prop="link" :colon="false">
          <a-input placeholder="https://www.olimp.bet/line/1/5956496/7848761" :disabled="loading" size="large"
            v-model="addTemplateForm.link"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import ActiveStatus from '@/components/custom/filters/activeStatus.vue'
import CustomButton from '@/components/custom/button/CustomButton.vue'
import AppsModal from '@/components/custom/modals/appsModal.vue'
import apiClient from '@/services/axios'
import ViewscreenService from '@/services/api/apiViewscreenService'

const defaultStoryRedirect = {
  storyId: '',
  buttonName: '',
  buttonColor: '',
  fontColor: '',
  linkType: '',
  linkName: '',
  sportId: '',
  link: '',
  id: '',
  // event: '',
}

const defaultPreview = {
  id: null,
  url: '',
  file: null,
  loading: false,
}

const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

function getBase64(img) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.readAsDataURL(img)
  })
}

function getImageDimensions(file) {
  return new Promise((resolve) => {
    const img = document.createElement('img')
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      })
    }
  })
}

function getVideoDimensions(file) {
  return new Promise((resolve) => {
    const video = document.createElement('video')
    video.src = URL.createObjectURL(file)
    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
      })
    }
  })
}

const generateVideoPreview = (file) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    const video = document.createElement('video')

    video.autoplay = true
    video.muted = true
    video.src = URL.createObjectURL(file)

    video.onloadeddata = () => {
      const ctx = canvas.getContext('2d')

      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      video.pause()
      return resolve(canvas.toDataURL('image/png'))
    }
  })
}

export default {
  name: 'promoForm',
  // eslint-disable-next-line vue/no-unused-components
  components: { CustomButton, ActiveStatus, AppsModal },
  data() {
    return {
      promo: undefined,
      story: undefined,
      bannerCoverUpdated: false,
      languages: ['ru'],
      langOptions: [''],
      linkTemplates: [],
      sports: [],
      form: {
        is_active: true,
        promoName: '',
        startDate: null,
        endDate: null,
        promoSections: [],
        visibility: null,
        promoApps: [],
      },
      // TODO addDEFAULT
      addTemplateForm: {
        name: '',
        link: '',
      },
      active: false,
      type: 'image',
      promoType: 'banner',
      checkedLangList: [],
      indeterminateLang: false,
      checkAllLangs: false,
      selectedLanguage: 'ru',
      content: {
        ru: {
          preview: {
            ...defaultPreview,
          },
          stories: [],
          redirects: [{ ...defaultStoryRedirect }],
        },
      },
      bannerMain: '',
      bannerImageUrl: '',
      banner: {
        bannerLink: '',
        bannerFile: undefined,
      },
      bannerRedirect: {
        type: 'match',
        link: '',
        id: '',
        name: '',
        sportId: '',
      },
      loading: false,
      errors: {
        oneFullStory: false,
        bothPreviewAndStory: false,
        isRedirectsValid: false,
      },
      editableStory: {
        index: null,
        id: null,
      },
      isEditingCover: false,
      isModalOpenOnClick: false,
      promoDisplays: [
        { label: 'Live', value: 1 },
        { label: 'Promo', value: 3 },
        { label: 'Main', value: 4 },
      ],
      visibilityOptions: [
        { label: 'All users', value: 1 },
        { label: 'All except new unauthorized users', value: 2 },
        { label: 'All new users', value: 3 },
        { label: 'All except new users', value: 4 },
        { label: 'All authorized users', value: 5 },
        { label: 'All unauthorized users', value: 6 },
        { label: 'All new authorized users', value: 7 },
        { label: 'All new or unauthorized users', value: 8 },
        { label: 'All authorized except new users', value: 9 },
      ],
      addAppsModal: false,
      addLinkTemplateModal: false,
      templateRules: {
        name: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
        link: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
      },
      rules: {
        promoName: [
          {
            required: true,
            message: 'Promo image name is required',
            trigger: 'blur',
          },
        ],
        startDate: [
          {
            required: true,
            message: 'Please pick start date',
            trigger: 'change',
          },
        ],
        visibility: [
          {
            required: true,
            message: 'Visibility setting is required',
            trigger: 'blur',
          },
        ],
        promoSections: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one section',
            trigger: 'change',
          },
        ],
        promoApps: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one app',
            trigger: 'change',
          },
        ],
      },
      modalAppsData: null,
    }
  },
  computed: {
    isEditMode() {
      return !!this.promo
    },
    isWebAppChosen() {
      if (!this.form.promoApps.length) {
        return false
      }
      return this.form.promoApps.find((app) => app.bundle_name === 'web.olimpbet' || app.bundle_name === 'web.app (test)')
    },
    selectedContent() {
      return this.content[this.selectedLanguage]
    },
    selectedStories() {
      return this.selectedContent.stories
    },
    selectedPreview() {
      return this.selectedContent.preview
    },
    selectedRedirects() {
      return this.selectedContent.redirects
    },
    selectedLanguageId() {
      return this.languages.find((language) => {
        return language.name === this.selectedLanguage
      }).id
    },
    filteredStories() {
      const selectedStoryIds = this.selectedRedirects.map(item => item.storyId)
      return this.selectedStories.map(story => selectedStoryIds.includes(story.id) ? { ...story, isChosen: true } : story)
    },
    promoTitle() {
      return this.isEditMode ? `Item #${this.promo.promoId}` : 'Create new promo'
    },
    isApllyToAllAvailable() {
      if (this.checkIsObjectEmpty(this.selectedContent)) {
        return false
      }

      const nonSelectedLanguages = Object.values(this.content).filter(
        (language) => language !== this.selectedLanguage,
      )

      const emptyLanguagesCount = nonSelectedLanguages.filter((currentLang) =>
        this.checkIsObjectEmpty(currentLang),
      ).length

      return emptyLanguagesCount === this.languages.length - 1
    },
    isFormClear() {
      if (this.isEditMode) {
        return true
      }
      return this.form.promoName !== ''
    },
    isViewscreen() {
      if (this.$route.params.viewscreenId || this.$route.params.sectionId) {
        return true
      }
      return false
    },
    breadcrumbRouteTitle() {
      if (this.isViewscreen) {
        return 'Viewscreen'
      }
      return 'Banners & Stories'
    },
    breadcrumbChildTitle() {
      if (this.isViewscreen) {
        return 'Edit banners & stories'
      }
      return this.isEditMode ? 'Edit promo item' : 'Create promo item'
    },
  },
  async created() {
    await this.fetchSports()
    await this.fetchLocalizations()
    await this.fetchLinkTemplates()
    if (this.isViewscreen) {
      this.form.promoSections = [4]
    }
    if (this.$route.params.type) {
      const { type, id } = this.$route.params
      await this.fetchPromo(type, id)

      if (this.promo.promoType === 'story') {
        this.story = this.promo
      }
      this.type = this.promo.type
      this.promoType = this.promo.promoType
      this.form.promoName = this.promo.name
      this.form.promoApps = this.promo.apps
      this.modalAppsData = this.promo.apps
      this.form.promoSections = this.isViewscreen ? [4] : this.promo.displays.map((display) => {
        return display.id
      })
      if (!this.isViewscreen) {
        this.form.visibility = this.promo.visibility
      }

      if (this.promo.promoType === 'banner') {
        this.form.startDate = this.$moment(this.promo.startsAt).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss')
        this.promo.endsIn ? this.form.endDate = this.$moment(this.promo.endsIn).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss') : this.form.endDate = null

        this.form.is_active = this.promo.isActive
        this.checkedLangList = this.promo.langs.map((lang) => {
          return lang.id
        })
        this.bannerImageUrl = this.promo.imagePath
        if (this.promo.redirect) {
          this.bannerRedirect = this.promo.redirect // For old banner
          if (this.promo.redirect.type === 'sport') {
            this.bannerRedirect.sportId = this.promo.redirect.sport.sport_id
          }
        }
      }
      if (this.story) {
        this.form.startDate = this.$moment(this.promo.start).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss')
        this.promo.end ? this.form.endDate = this.$moment(this.promo.end).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss') : this.form.endDate = null
        this.form.is_active = this.promo.is_active
        const storyLanguages = Object.keys(this.story.preview)
        this.selectedLanguage = storyLanguages[0]
        const storyContent = storyLanguages.reduce((acc, language) => {
          const storyContentData = {}

          storyContentData['stories'] = this.story.images[language].map(
            (image, index) => {
              return {
                id: image.id,
                preview: image.path,
                loading: false,
                name: `Stories ${index + 1}`,
                isVideo: image.info.mime === 'video/mp4',
              }
            },
          )
          const redirects = this.story.images[language]
            .filter(image => image.redirect)
            .map(image => {
              const redirectObj = {
                buttonColor: image.button_color,
                buttonName: image.button_name,
                fontColor: image.font_color,
                link: image.redirect.link,
                linkType: image.redirect.type,
                storyId: image.id,
              }
              if (image.event) {
                redirectObj.event = image.event
              }
              if (redirectObj.linkType === 'custom') {
                redirectObj.id = image.redirect.id
                redirectObj.linkName = image.redirect.name
              }
              if (redirectObj.linkType === 'sport') {
                redirectObj.sportId = image.redirect.sport.sport_id
              }

              return redirectObj
            })
          redirects.length > 0 ? storyContentData['redirects'] = redirects : storyContentData['redirects'] = []

          return {
            ...acc,
            [language]: {
              preview: {
                ...defaultPreview,
                id: this.story.preview[language][0].id,
                url: this.story.preview[language][0].path,
              },
              ...storyContentData,
            },
          }
        }, {})
        const defaultContent = this.languages.reduce((acc, language) => {
          return {
            ...acc,
            [language.name]: {
              redirects: [],
              preview: { ...defaultPreview },
              stories: [],
            },
          }
        }, {})

        this.content = {
          ...defaultContent,
          ...storyContent,
        }
      }
    }
    if (!this.story) {
      this.selectedLanguage = this.languages[0].name
      this.content = this.languages.reduce((acc, language) => {
        return {
          ...acc,
          [language.name]: {
            redirects: [],
            preview: { ...defaultPreview },
            stories: [],
          },
        }
      }, {})
    }
  },
  methods: {
    async validateStoryForm() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })

      let atLeastOneValid = false
      let allAreValid = true
      let allRedirectsValid = true
      let allRedirectsColorValid = true
      for (const lang in this.content) {
        let isNoneValid = false
        let isInvalidTab = false
        let isInvalidRedirectTab = false
        let isInvalidColorLength = false

        const currentTab = this.content[lang]
        const isPreviewEmpty = currentTab.preview.url === ''
        const areStoriesEmpty = !currentTab.stories.length

        isNoneValid = isPreviewEmpty && areStoriesEmpty
        isInvalidTab = (!isPreviewEmpty && areStoriesEmpty) || (isPreviewEmpty && !areStoriesEmpty)

        if (!isNoneValid) {
          atLeastOneValid = true
        }
        if (isInvalidTab) {
          allAreValid = false
        }
        if (currentTab.redirects.length > 0) {
          for (const redirect of currentTab.redirects) {
            const { buttonColor, buttonName, fontColor, link, linkType, sportId, storyId } = redirect
            const fieldsToValidate = { buttonColor, buttonName, fontColor, linkType, storyId }
            if (linkType === 'sport') {
              fieldsToValidate.sportId = sportId
            }
            if (linkType !== 'sport') {
              fieldsToValidate.link = link
            }
            const errorResults = Object.values(fieldsToValidate)

            isInvalidRedirectTab = !errorResults.includes('') && !errorResults.includes(undefined)

            isInvalidColorLength = buttonColor.length > 7 || fontColor.length > 7
          }
          if (!isInvalidRedirectTab) {
            allRedirectsValid = false
          }
          if (isInvalidColorLength) {
            allRedirectsColorValid = false
          }
        }
      }
      const errors = {
        oneFullStory: !atLeastOneValid,
        bothPreviewAndStory: !allAreValid,
        isRedirectsValid: !allRedirectsValid,
        redirectColorLength: !allRedirectsColorValid,
      }
      this.errors = { ...errors }
      return isValid && !Object.values(this.errors).includes(true)
    },
    async validateBannerForm() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
      const { type, link, sportId } = this.bannerRedirect
      const fieldsToValidate = { type }

      fieldsToValidate.bannerFile = this.bannerImageUrl

      if (type === 'sport') {
        fieldsToValidate.sportId = sportId
      }
      if (type !== 'sport') {
        fieldsToValidate.link = link
      }
      const validateValues = Object.values(fieldsToValidate)
      const bannerIsValid = !validateValues.includes('') && !validateValues.includes(undefined)
      const bannerHasLang = this.checkedLangList.length > 0
      const errors = {
        bannerIsValid: !bannerIsValid,
        bannerHasLang: !bannerHasLang,
      }
      this.errors = { ...errors }
      return isValid && !Object.values(this.errors).includes(true)
    },
    async validateApps() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validateField('promoApps', resolve)
      })
      return isValid
    },
    async handleAddTemplatesModal() {
      // TODO FIXLINKS AFTER CREATE
      try {
        this.loading = true
        const templateResponse = await apiClient.post(
          '/admin/links',
          this.addTemplateForm,
        )
        this.linkTemplates.push(templateResponse.data.data)
        this.closeAddLinkTemplatesModal()
        this.$refs.templateForm.resetFields()
        if (templateResponse.data.error) {
          this.loading = false
          this.$notification.error({
            message: 'Error while adding templates',
            description: templateResponse.data.error.message,
          })
          return
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while adding templates',
          description: e.response.data.error.message,
        })
        this.loading = false
      }
      this.loading = false
    },
    async save() {
      this.loading = true

      if (this.promoType === 'story') {
        const isFormValid = await this.validateStoryForm()
        if (!isFormValid) {
          this.loading = false
          return
        }
        return await this.createStory()
      }
      const isFormValid = await this.validateBannerForm()

      if (!isFormValid) {
        this.loading = false
        return
      }
      return await this.createBanner()
    },
    async update() {
      this.loading = true

      if (this.promoType === 'story') {
        const isFormValid = await this.validateStoryForm()

        if (!isFormValid) {
          this.loading = false
          return
        }
        return await this.updateStory()
      }
      const isFormValid = await this.validateBannerForm()

      if (!isFormValid) {
        this.loading = false
        return
      }
      return await this.updateBanner()
    },
    async preformDeletePromo() {
      let url = ''
      const deleteType = this.promo.promoType
      if (deleteType === 'banner') {
        url = 'banners'
      } else {
        url = 'stories'
      }
      try {
        const deleteResponse = await apiClient.delete(
          `/admin/${url}/${this.promo.id}`,
        )
        if (deleteResponse.data.error) {
          this.$notification.error({
            message: 'Error while deleting promo',
            description: deleteResponse.data.error.message,
          })
          return
        }
        this.$notification.success({
          message: `${this.promoType.charAt(0).toUpperCase() + this.promoType.slice(1)} deleted successfully`,
        })
        if (this.isViewscreen) {
          return this.redirectToViewscreen()
        }
        this.redirectToPromo()
      } catch (e) {
        this.$notification.error({
          message: 'Error while deleting promo',
          description: e.response.data.error.message,
        })
      }
    },
    async fetchLocalizations() {
      this.languagesLoading = true
      const url = '/admin/localizations'
      try {
        const response = await apiClient.get(url)
        this.languages = response.data.data
        this.langOptions = this.languages.map((lang) => {
          return {
            label: lang.desc,
            value: lang.id,
          }
        })
      } catch (error) {
        console.log('Error while trying to get locales', error)
      } finally {
        this.languagesLoading = false
      }
    },
    async fetchLinkTemplates() {
      this.loading = true
      const url = '/admin/links?type=custom&results=1000'
      try {
        const response = await apiClient.get(url)
        this.linkTemplates = response.data.data
      } catch (error) {
        console.log('Error while trying to get templates', error)
      } finally {
        this.loading = false
      }
    },
    async fetchSports() {
      this.loading = true
      const url = '/admin/sports?results=1000'
      try {
        const response = await apiClient.get(url)
        this.sports = response.data.data
      } catch (error) {
        console.log('Error while trying to get sports', error)
      } finally {
        this.loading = false
      }
    },
    async fetchPromo(type, promoId) {
      this.loading = true
      try {
        const response = await apiClient.get(`admin/promo/${promoId}`, { params: { type } })
        this.promo = response.data.data
      } catch (error) {
        console.log('Error while trying to fetch promo', error)
      } finally {
        this.loading = false
      }
    },
    getStoryCoversByLanguage() {
      return Object.entries(this.content).reduce((acc, [language, content]) => {
        const storyWithRedirect = content.stories.map((story) => {
          const redirect = content.redirects.find((redirect) => redirect.storyId === story.id)
          let redirectObj
          if (redirect) {
            const { buttonName, buttonColor, fontColor, event, linkType, linkName, link, sportId } = redirect
            redirectObj = {
              id: story.id,
              button_name: buttonName,
              button_color: buttonColor,
              font_color: fontColor,
              redirect: { type: linkType, link },
            }
            if (event) {
              redirectObj.event = event
            }
            if (linkType === 'custom') {
              redirectObj.redirect.name = linkName
            }
            if (linkType === 'sport') {
              redirectObj.redirect.sport_id = sportId
            }
          } else {
            redirectObj = { id: story.id }
          }

          return redirectObj
        })

        return {
          ...acc,
          [language]: storyWithRedirect,
        }
      }, {})
    },
    getPreviewsByLanguage() {
      return Object.entries(this.content).reduce((acc, [language, content]) => {
        return {
          ...acc,
          [language]: content.preview.id ? [content.preview.id] : [],
        }
      }, {})
    },
    getApplications() {
      return this.form.promoApps.map((app) => {
        return {
          application_id: app.application_id,
          min_app_version: app.min_app_version,
          max_app_version: app.max_app_version,
        }
      })
    },
    getStoryPayload() {
      const storyPayload = {
        name: this.form.promoName,
        type: 'image',
        is_active: this.form.is_active,
        displays: this.form.promoSections,
        ...(!this.isViewscreen && { visibility: this.form.visibility }),
        start: this.$moment(this.form.startDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss'),
        applications: this.getApplications(),
        covers: this.getStoryCoversByLanguage(),
        preview: this.getPreviewsByLanguage(),
      }
      this.form.endDate ? storyPayload.end = this.$moment(this.form.endDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss') : storyPayload.end = null

      return storyPayload
    },
    async createStory() {
      try {
        this.loading = true

        const storyPayload = this.getStoryPayload()
        const storyResponse = await apiClient.post(
          '/admin/stories',
          storyPayload,
        )
        if (storyResponse.data.error) {
          this.loading = false
          this.$notification.error({
            message: 'Error while creating story',
            description: storyResponse.data.error.message,
          })
          return
        }
        this.$notification.success({
          message: 'Story have been successfully created',
        })
        if (this.isViewscreen) {
          await this.addViewscreenPromo(storyResponse.data.data.promoId)
          return this.redirectToViewscreen()
        }
        this.redirectToPromo()
      } catch (e) {
        this.$notification.error({
          message: 'Error while creating story',
          description: e.response.data.error.message,
        })
        this.loading = false
      }
    },
    async updateStory() {
      try {
        this.loading = true
        const storyPayload = this.getStoryPayload()
        this.loading = false
        const updateResponse = await apiClient.patch(
          `/admin/stories/${this.story.id}`,
          storyPayload,
        )
        this.$notification.success({
          message: 'Story have been successfully updated',
        })
        if (this.isViewscreen) {
          return this.redirectToViewscreen()
        }
        this.redirectToPromo()
        if (updateResponse.data.error) {
          this.$notification.error({
            message: 'Error while updating story',
            description: updateResponse.data.error.message,
          })
          this.loading = false
          return
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while updating story',
          description: e.response.data.error.message,
        })
        this.loading = false
      }
    },
    async addViewscreenPromo (promoId) {
      this.loading = true
      try {
        const response = await ViewscreenService.addViewscreenSectionItem(this.$route.params.sectionId, promoId)
        if (response.data.data) {
          this.$notification.success({
            message: 'The promo item has been successfully added to the viewscreen section',
          })
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while adding promo to viewscreen',
          description: e.response.data.error.message,
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    getBannerRedirect() {
      const bannerObj = {
        link: this.bannerRedirect.link,
        type: this.bannerRedirect.type,
      }
      if (this.bannerRedirect.type === 'custom') {
        bannerObj.name = this.bannerRedirect.name
      }
      if (this.bannerRedirect.type === 'sport') {
        bannerObj.sport_id = this.bannerRedirect.sportId
      }
      return bannerObj
    },
    getBannerPayload() {
      const bannerPayload = {
        name: this.form.promoName,
        displays: this.form.promoSections,
        ...(!this.isViewscreen && { visibility: this.form.visibility }),
        is_active: this.form.is_active,
        langs: this.checkedLangList,
        starts_at: this.$moment(this.form.startDate).tz('Etc/UTC').format('DD-MM-YYYY HH:mm:ss'),
        applications: this.getApplications(),
        redirect: this.getBannerRedirect(),
      }
      this.form.endDate ? bannerPayload.ends_in = this.$moment(this.form.endDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss') : bannerPayload.ends_in = null
      return bannerPayload
    },
    async createBanner() {
      try {
        this.loading = true

        const bannerPayload = this.getBannerPayload()
        const bannerResponse = await apiClient.post(
          '/admin/banners',
          bannerPayload,
        )
        this.putCover(bannerResponse.data.data.id)

        this.$notification.success({
          message: 'Banner have been successfully created',
        })

        if (this.isViewscreen) {
          await this.addViewscreenPromo(bannerResponse.data.data.promoId)
          return this.redirectToViewscreen()
        }
        this.redirectToPromo()
        if (bannerResponse.data.error) {
          this.loading = false
          this.$notification.error({
            message: 'Error while creating banner',
            description: bannerResponse.data.error.message,
          })
          return
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while creating banner',
          description: e.response.data.error.message,
        })
        this.loading = false
      }
    },
    async updateBanner() {
      try {
        this.loading = true

        const bannerPayload = this.getBannerPayload()
        const bannerResponse = await apiClient.patch(
          `/admin/banners/${this.promo.id}`,
          bannerPayload,
        )
        if (this.bannerCoverUpdated) {
          this.putCover(bannerResponse.data.data.id)
        }
        this.$notification.success({
          message: 'Banner have been successfully updated',
        })
        if (this.isViewscreen) {
          return this.redirectToViewscreen()
        }
        this.redirectToPromo()
        if (bannerResponse.data.error) {
          this.loading = false
          this.$notification.error({
            message: 'Error while updating banner',
            description: bannerResponse.data.error.message,
          })
          return
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while updating banner',
          description: e.response.data.error.message,
        })
        this.loading = false
      }
    },
    async putCover(banId) {
      try {
        const formData = new FormData()
        formData.append('cover', this.banner.bannerFile)

        const bannerCoverResponse = await apiClient.post(
          `/admin/banners/${banId}/cover`,
          formData,
        )
        if (bannerCoverResponse.data.error) {
          this.loading = false
          this.$notification.error({
            message: 'Error while uploading banner cover',
            description: bannerCoverResponse.data.error.message,
          })
          return
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while uploading banner cover',
          description: e.response.data.error.message,
        })
        this.loading = false
      }
    },
    openAddAppModal() {
      if (this.loading) {
        return
      }
      this.addAppsModal = true
    },
    closeAddAppsModal() {
      this.addAppsModal = false
    },
    handleAddAppsModal() {
      console.log('test')
    },
    openAddLinkTemplatesModal() {
      if (this.loading) {
        return
      }
      this.addLinkTemplateModal = true
    },
    closeAddLinkTemplatesModal() {
      this.addLinkTemplateModal = false
    },
    handleTabChange(selectedLangKey) {
      if (this.loading) {
        return
      }
      this.selectedLanguage = selectedLangKey
    },
    async validateUploadedStory(file) {
      const fileType = file.type.split('/')[0]
      const fileExtension = file.type.split('/')[1]
      const isImage = fileType === 'image'
      const isVideo = fileType === 'video'

      if (!isImage && !isVideo) {
        this.$notification.error({
          message: 'You can only upload image or video file!',
        })
        return false
      }

      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isLt10M) {
        this.$notification.error({
          message: 'Story must be smaller than 10MB!',
        })
        return false
      }
      if (isVideo) {
        const { width, height } = await getVideoDimensions(file)
        if (width !== 1400 || height !== 2200) {
          this.$notification.error({
            message: 'Video must be 1400px x 2200px!',
          })
          return false
        }
        if (fileExtension !== 'mp4') {
          this.$notification.error({
            message: 'Video must have MP4 extension!',
          })
          return false
        }
      }

      if (isImage) {
        const { width, height } = await getImageDimensions(file)
        if (width !== 1400 || height !== 2200) {
          this.$notification.error({
            message: 'Image must be 1400px x 2200px!',
          })
          return false
        }
        if (fileExtension !== 'png' && fileExtension !== 'webp' && fileExtension !== 'jpeg') {
          this.$notification.error({
            message: 'Image can be PNG, WEBP or JPG / JPEG extension!',
          })
          return false
        }
      }

      return file
    },
    async handleStoryUpload(event) {
      if (event.file.status === 'uploading') {
        return
      }

      const file = event.file

      const isFileValid = await this.validateUploadedStory(
        event.file.originFileObj,
      )

      if (!isFileValid) {
        return
      }

      const fileType = file.type.split('/')[0]
      const fileId = file.uid

      const story = {
        id: fileId,
        preview: '',
        loading: true,
        file,
      }

      this.isEditingCover ? this.selectedContent.stories.splice(this.editableStory.index, 1, story) : this.selectedContent.stories.push(story)

      const formData = new FormData()

      formData.append('cover', file.originFileObj)
      formData.append('lang_id', this.selectedLanguageId)

      try {
        const response = await apiClient.post('/admin/stories/cover', formData)
        const storyId = response.data?.data?.id
        if (!storyId) {
          const errorMessage =
            response.data?.error?.message ||
            'Something went wrong while uploading the story'
          this.selectedContent.stories.splice(0, 1)
          this.$notification.error({
            message: errorMessage,
          })
          return
        }

        if (fileType === 'image') {
          story.id = storyId
          story.preview = await getBase64(event.file.originFileObj)
          story.name = this.isEditingCover ? `Stories ${this.editableStory.index + 1}` : `Stories ${this.selectedContent.stories.length}`
          story.loading = false
        }

        if (fileType === 'video') {
          story.id = storyId
          story.preview = await generateVideoPreview(event.file.originFileObj)
          story.name = this.isEditingCover ? `Stories ${this.editableStory.index + 1}` : `Stories ${this.selectedContent.stories.length}`
          story.loading = false
        }
      } catch (e) {
        const errorMessage =
          e.response.data?.error?.message ||
          'Something went wrong while uploading the story'
        this.selectedContent.stories.splice(0, 1)
        this.$notification.error({
          message: errorMessage,
        })
      }
      if (this.isEditingCover) {
        this.isModalOpenOnClick = false
        this.isEditingCover = false
        const redirectIndexToEdit = this.selectedContent.redirects.findIndex(redirect => redirect.storyId === this.editableStory.id)
        if (redirectIndexToEdit !== -1) {
          this.selectedContent.redirects[redirectIndexToEdit].storyId = story.id
        }
      }
    },
    deleteStory(index) {
      const deletedStoryId = this.selectedContent.stories[index].id
      this.selectedContent.stories.splice(index, 1)
      this.selectedContent.redirects = this.selectedContent.redirects.filter(redirect => redirect.storyId !== deletedStoryId)
    },
    editStory(index) {
      this.isModalOpenOnClick = true
      this.isEditingCover = true
      this.editableStory.index = index
      this.editableStory.id = this.selectedContent.stories[this.editableStory.index].id
    },
    async validateUploadedPreview(file) {
      const isImage = file.type.split('/')[0] === 'image'
      const fileExtension = file.type.split('/')[1]

      if (!isImage) {
        this.$notification.error({
          message: 'You can only upload image file!',
        })
        return false
      }

      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isLt10M) {
        this.$notification.error({
          message: 'Preview must be smaller than 10MB!',
        })
        return false
      }

      if (isImage) {
        const { width, height } = await getImageDimensions(file)
        if (width !== 500 || height !== 500) {
          this.$notification.error({
            message: 'Preview must be 500px x 500px!',
          })
          return false
        }
        if (fileExtension !== 'png' && fileExtension !== 'webp' && fileExtension !== 'jpeg') {
          this.$notification.error({
            message: 'Image can be PNG, WEBP or JPG / JPEG extension!',
          })
          return false
        }
      }

      return file
    },
    async handlePreviewUpload(event) {
      if (event.file.status === 'uploading') {
        return
      }

      const file = event.file

      const isFileValid = await this.validateUploadedPreview(
        event.file.originFileObj,
      )

      if (!isFileValid) {
        return
      }

      const fileId = file.uid

      this.content[this.selectedLanguage].preview = {
        ...this.selectedPreview,
        loading: true,
        id: fileId,
        file,
      }

      const formData = new FormData()

      formData.append('preview', file.originFileObj)

      try {
        const response = await apiClient.post(
          '/admin/stories/preview',
          formData,
        )
        const previewId = response.data?.data?.id
        if (!previewId) {
          const errorMessage =
            response.data?.error?.message ||
            'Something went wrong while uploading the preview'
          this.content[this.selectedLanguage].preview = {
            ...defaultPreview,
          }
          this.$notification.error({
            message: errorMessage,
          })
        }
        this.content[this.selectedLanguage].preview.id = previewId
        this.content[this.selectedLanguage].preview.url = await getBase64(
          event.file.originFileObj,
        )
        this.content[this.selectedLanguage].preview.loading = false
      } catch (e) {
        const errorMessage =
          e.response.data?.error?.message ||
          'Something went wrong while uploading the preview'
        this.content[this.selectedLanguage].preview = {
          ...defaultPreview,
        }
        this.$notification.error({
          message: errorMessage,
        })
      }
    },
    async validateUploadedBanner(file) {
      const isImage = file.type.split('/')[0] === 'image'
      const fileExtension = file.type.split('/')[1]
      if (!isImage) {
        this.$notification.error({
          message: 'You can only upload JPG/PNG file!',
        })
        return false
      }

      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isLt10M) {
        this.$notification.error({
          message: 'Image must be smaller than 10MB!',
        })
        return false
      }

      if (isImage) {
        const { width, height } = await getImageDimensions(file)

        const rightWidth = this.isWebAppChosen ? 2000 : 1160
        const rightHeight = this.isWebAppChosen ? 640 : 414

        if (width !== rightWidth || height !== rightHeight) {
          this.isWebAppChosen ? this.$notification.error({ message: 'Web app banner image must be 2000px x 640px!' }) : this.$notification.error({ message: 'Banner image must be 1160px x 414px!' })
          return false
        }
        if (fileExtension !== 'png' && fileExtension !== 'webp' && fileExtension !== 'jpeg') {
          this.$notification.error({
            message: 'Image can be PNG, WEBP or JPG / JPEG extension!',
          })
          return false
        }
      }

      return file
    },
    async handleBannerUpload(event) {
      if (event.file.status === 'uploading') {
        return
      }
      // const file = event.file

      const isFileValid = await this.validateUploadedBanner(
        event.file.originFileObj,
      )

      if (!isFileValid) {
        return
      }

      this.banner.bannerFile = event.file.originFileObj
      this.bannerImageUrl = await getBase64(event.file.originFileObj)
      if (this.isEditMode) {
        this.bannerCoverUpdated = true
      }
    },
    handleAddRedirect() {
      this.selectedRedirects.push({ ...defaultStoryRedirect })
    },
    handleDeleteRedirect(redirectIndex) {
      this.selectedRedirects.splice(redirectIndex, 1)
    },
    changeRedirectTemplate(redirectIndex, linkId) {
      const searchTemplate = this.linkTemplates.find((template) => {
        return template.id === linkId
      })

      if (this.promoType === 'story') {
        this.selectedRedirects[redirectIndex].linkName = searchTemplate.name
        this.selectedRedirects[redirectIndex].link = searchTemplate.link
      } else {
        this.bannerRedirect.link = searchTemplate.link
        this.bannerRedirect.name = searchTemplate.name
      }
    },
    checkIsObjectEmpty(currentLang) {
      const currentStoriesEmpty = currentLang.stories.length === 0

      return !currentLang.preview.id && currentStoriesEmpty
    },
    addToAllLangs() {
      for (const language in this.content) {
        const currentLang = this.content[language]
        if (language !== this.selectedLanguage) {
          currentLang.preview = deepClone(this.selectedContent.preview)
          currentLang.stories = deepClone(this.selectedContent.stories)
          currentLang.redirects = deepClone(this.selectedContent.redirects)
        }
      }
    },
    changeSelectedApps(apps) {
      this.closeAddAppsModal()
      // this.clearBannerUrl()
      this.form.promoApps = apps
      this.modalAppsData = apps
      this.validateApps()
    },
    onLangChange(checkedLang) {
      const bannerLangKeys = this.langOptions.map(lang => lang.value)
      this.indeterminateLang = !!checkedLang.length && checkedLang.length < bannerLangKeys.length
      this.checkAllLangs = checkedLang.length === bannerLangKeys.length
      this.checkedLangList = checkedLang
    },
    onCheckAllLangChange(e) {
      Object.assign(this, {
        checkedLangList: e.target.checked ? this.langOptions.map(lang => lang.value) : [],
        indeterminateLang: false,
        checkAllLangs: e.target.checked,
      })
    },
    redirectToPromo() {
      this.$router.push('/promo-images/promo-list')
    },
    redirectToViewscreen() {
      this.$router.push(`/main/${this.$route.params.viewscreenId}`)
    },
    redirectToRoute() {
      if (this.isViewscreen) {
        return this.redirectToViewscreen()
      }
      return this.redirectToPromo()
    },
    noCoverRedirect(redirectIndex) {
      if (this.loading) {
        return true
      } if (this.selectedRedirects[redirectIndex].storyId === undefined) {
        return true
      }
      return this.selectedRedirects[redirectIndex].storyId === ''
    },
    clearBannerUrl() {
      this.bannerImageUrl = ''
      this.banner.bannerFile = undefined
    },
  },
}
</script>

<style lang="scss">
.text {
  color: #8C8C8C;
}

.redirect-list {
  gap: 1rem;

  .ant-form-item {
    min-width: 200px
  }
}

.promo-form {
  gap: 1rem;

  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
}

.images {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
  }

  .uploader {
    border-radius: 2px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: auto;
    position: relative;

    &.stories .ant-upload-list-item,
    &.stories .ant-upload-list-picture-card-container,
    &.stories .ant-upload-select-picture-card {
      width: 120px !important;
      height: 190px !important;
      margin: 0 !important;

      &>span {
        padding: 0;

        &>img {
          width: 120px !important;
          height: 190px !important;
        }
        &>video {
          width: 120px !important;
          height: 190px !important;
        }
      }
    }

    &.main .ant-upload-list-item,
    &.main .ant-upload-list-picture-card-container,
    &.main .ant-upload-select-picture-card {
      width: 374px !important;
      height: 132px !important;
      margin: 0 !important;

      &>span {
        padding: 0;

        &>img {
          width: 374px !important;
          height: 132px !important;
        }
      }
    }
  }

  .story-list {
    width: 450px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;

    &>.story-item {
      position: relative;
      width: 120px;
      height: 190px;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &:hover .overlay {
        opacity: 1;
      }

      .loader {
        width: 120px;
        height: 190px;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .overlay {
        transition: all 0.2s ease-in-out;
        position: absolute;
        display: flex;
        gap: 12px;
        opacity: 0;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.4);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        &>div {
          cursor: pointer;
        }
      }

      &>img {
        width: 120px;
        height: 190px;
      }
    }
  }

  .preview-image {
    width: 110px;
    height: 110px;

    &>img {
      width: 110px;
      height: 110px;
    }
  }

  .banner-image {
    width: 374px;
    height: 132px;

    &>img {
      width: 374px;
      height: 132px;
    }
  }
}

.checkbox-container {
  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-checkbox {
    margin-top: 4px;
  }
}

.grid-langs {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, 120px);
  grid-gap: 12px 0;
  margin: 16px 0 10px !important;
}

.required-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222e;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
</style>
