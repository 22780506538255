import 'ant-design-vue/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import NProgress from 'vue-nprogress'
import VueLayers from 'vuelayers'
import BootstrapVue from 'bootstrap-vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import FlagIcon from 'vue-flag-icon'
import LottieAnimation from 'lottie-web-vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp, faWhatsappSquare, faTelegram, faViber, faSkype } from '@fortawesome/free-brands-svg-icons'
import { faBroom } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Clipboard from 'v-clipboard'
import VueCryptojs from 'vue-cryptojs'
import VueDragscroll from 'vue-dragscroll'
// import { JSEncrypt } from 'jsencrypt'
import RSACrypt from '@/plugins/RSACrypt/RSACrypt'

// import Echo from 'laravel-echo'
// import Pusher from 'pusher-js'

import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './localization'
import './antd'
import './registerServiceWorker'

// mocking api
// import './services/axios/fakeApi'

// (function initPusher() {
//   window.Pusher = Pusher
//   window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'XvAsERTdq8V9K0wp4DpK8LZA',
//     cluster: 'mt1',
//     forceTLS: true,
//     encrypted: true,
//     wsHost: 'api.admin3.olimp.work',
//     wssHost: 'api.admin3.olimp.work',
//     wsPort: 6001,
//     wssPort: 6001,
//     pongTimeout: 500,
//     disableStats: false,
//     enabledTransports: ['ws', 'wss'],
//   })
//   window.Echo.connector.pusher.connection.bind('disconnected', () => {
//     // console.log('pusher disconnected')
//   })
//   window.Echo.connector.pusher.connection.bind('connected', async () => {
//     // console.log('retrieving chat ...')
//   })
// })()

library.add(faWhatsapp, faWhatsappSquare, faTelegram, faViber, faSkype, faBroom)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueMoment, { moment })
Vue.use(BootstrapVue)
Vue.use(VueLayers)
Vue.use(FlagIcon)
Vue.use(Clipboard)
Vue.use(VueCryptojs)
Vue.use(VueDragscroll)
Vue.use(RSACrypt)

Vue.use(NProgress)
Vue.use(VuePageTitle, {
  prefix: 'Olimp.Bet',
  router,
})
Vue.use(LottieAnimation)

Vue.config.productionTip = true
const nprogress = new NProgress({ parent: 'body' })

Vue.directive('add-class-hover', {
  bind(el, binding, vnode) {
    const { value = '' } = binding
    el.addEventListener('mouseenter', () => {
      el.classList.add(value)
    })
    el.addEventListener('mouseleave', () => {
      el.classList.remove(value)
    })
  },
  unbind(el, binding, vnode) {
    el.removeEventListener('mouseenter')
    el.removeEventListener('mouseleave')
  },
})

new Vue({
  router,
  store,
  nprogress,
  i18n,
  render: h => h(App),
}).$mount('#app')
