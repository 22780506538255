<template>
  <a-card>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="h5 mb-0">Apps</span>
      <a-button type="primary" size="large" style="font-size: 16px; font-weight: 300; border-radius: 2px;" @click="showAppModal">
        <a-icon type="plus" /> Add New
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="appData"
    >
      <template
        v-for="col in ['name', 'bundle_name', 'fb_name', 'platform', 'schema', 'sender_id']"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col" v-if="col === 'platform'" style="text-align: center; font-size: 18px">
          <a-icon v-if="record.platform.toLowerCase() === 'android'" type="android" class="text-success" />
          <a-icon v-else-if="record.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
          <a-icon v-else-if="record.platform.toLowerCase() === 'web'" type="global" class="text-default" />
        </div>
        <div :key="col" v-else-if="col === 'bundle_name'">
          <template>
            {{ text }}
            <a-tooltip title="Some banners can be imported from external JSON file" placement="bottom">
              <a-icon type="link" style="float: right"
                      v-if="record.admin_settings && record.admin_settings.banners_model && record.admin_settings.banners_model === 'kz'"
                      @click="editApp(record, 2)"
              />
            </a-tooltip>
          </template>
        </div>
        <div :key="col" v-else-if="col !== 'sender_id'">
          <template>
            {{ text }}
          </template>
        </div>
        <div v-else :key="col">
          <template>
            {{ getSenderById(text) }}
          </template>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <div class="editable-row-operations" v-if="checkPermissions(user.permissions, 'settings', 'edit')">
          <span>
            <a href="javascript:" class="text-primary font-size-18" :disabled="editingKey !== ''" @click="() => editApp(record, 1)">
              <a-icon type="edit" />
            </a>
          </span>
          <span>
            <a-popconfirm
              v-if="appData.length"
              title="Sure to delete?"
              @confirm="() => onDelete(record.application_id)"
            >
              <a href="javascript:;" class="text-danger font-size-18"><a-icon type="delete" /></a>
            </a-popconfirm>
          </span>
          <span class="pl-2" v-if="record.settings.length>0">
            <a-tooltip :title="record.settings.map(item => item['name']).join('\n')" placement="bottom" :overlayStyle="{ whiteSpace: 'pre-line' }" >
              <a class="mr-0" href="javascript:;" @click="editApp(record, 3)">
                <a-icon type="setting"  style="font-size: 18px" />
              </a>
            </a-tooltip>
          </span>
          <span class="pl-2 mr-0" v-else>
            <a-icon type="setting"  style="font-size: 18px" class="text-gray-5" />
          </span>
        </div>
      </template>
    </a-table>
    <a-modal v-model="newAppModal"
              :title="appObj && appObj.application_id ? `Edit application [${appObj.name}]` : 'New application'"
              class="add-app-modal olimp-admin-modal"
              :footer="null"
              :width="appModalWidth"
              :destroyOnClose="true"
    >
      <app-modal
        @refreshApps="getApplications()"
        @createApp="getApplications()"
        @changeApp="getApplications()"
        @deleteApp="onDelete"
        @changeTab="handleAppModalChangeTab"
        @closeModalFunction="newAppModal = false"
        :active-obj="appObj"
        :sender-ids="senderIds"
        :default-tab-key="appModalActiveTabKey"
        ref="appModal"
      />
    </a-modal>
  </a-card>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import appModal from '../appModal.vue'
import banmix from '@/services/bannermix.js'
import accessMix from '@/services/accessmix.js'

const columns = [
  {
    title: 'Platform',
    dataIndex: 'platform',
    width: '9%',
    scopedSlots: { customRender: 'platform' },
  },
  {
    title: 'Bundle ID',
    dataIndex: 'bundle_name',
    width: '22.5%',
    scopedSlots: { customRender: 'bundle_name' },
  },
  {
    title: 'Friendly Name',
    dataIndex: 'name',
    width: '12%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Schema',
    dataIndex: 'schema',
    width: '12%',
    scopedSlots: { customRender: 'schema' },
  },
  {
    title: 'FB Collection',
    dataIndex: 'fb_name',
    width: '12%',
    scopedSlots: { customRender: 'fb_name' },
  },
  {
    title: 'FB Key',
    dataIndex: 'sender_id',
    width: '15%',
    scopedSlots: { customRender: 'sender_id' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    width: '12%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'apps',
  components: {
    appModal,
  },
  mixins: [banmix, accessMix],
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
    appModalWidth() {
      return this.appModalActiveTabKey === 3 ? 720 : 520
    },
  },
  data() {
    return {
      columns,
      editingKey: '',
      newAppModal: false,
      appData: [],
      senderIds: [],
      appModalActiveTabKey: 1,
      addAppForm: {
        name: '',
        fb_name: '',
        bundle_name: '',
        platform: 'ios',
        schema: '',
        sender_id: undefined,
      },
      addAppRules: {
        name: [{ required: true, message: 'Please input name', trigger: 'blur' }],
        bundle_name: [{ required: true, message: 'Please input Bundle ID', trigger: 'blur' }],
        platform: [{ required: true, message: 'Please select platform', trigger: 'change' }],
        schema: [{ required: true, message: 'Please input application schema', trigger: 'blur' }],
        sender_id: [{ required: true, message: 'Please select Sender ID', trigger: 'change' }],
      },
      appObj: undefined,
    }
  },
  mounted () {
    this.getApplications()
    this.getSenderIds()
  },
  methods: {
    getSenderById(id) {
      const sender = this.senderIds.find(obj => {
        return obj.id === id
      })
      console.log(id, sender)
      if (typeof sender !== 'undefined') {
        return sender.name
      } else {
        return 'unset'
      }
    },
    getSenderIds() {
      const url = '/admin/senders'
      apiClient.get(url).then((response) => {
        this.senderIds = response.data.data
        this.senderData = response.data.data
      }).catch(error => { console.log(error) })
    },
    getApplications() {
      const url = '/admin/applications'
      apiClient.get(url).then((response) => {
        this.appData = response.data.data
      }).catch(error => { console.log(error) })
    },
    showAppModal() {
      this.appObj = null
      this.newAppModal = true
    },
    editApp(obj, tabKey) {
      this.appObj = obj
      this.appModalActiveTabKey = tabKey
      this.newAppModal = true
    },
    hideAppModal() {
      this.newAppModal = false
    },
    handleAppModalChangeTab(key) {
      this.appModalActiveTabKey = key
      console.log('emit appModalActiveTabKey', key)
    },
    resetAddAppForm() {
      this.$refs.addAppForm.resetFields()
    },
    addAppSubmit() {
      this.$refs.addAppForm.validate(valid => {
        if (valid) {
          const url = '/admin/applications'
          return apiClient.post(url, this.addAppForm).then((response) => {
            this.$notification.success({
              message: 'Application "' + response.data.data.name + '" successfully created',
              description: '',
            })
            const { appData } = this
            const newApp = {
              application_id: response.data.data.application_id,
              name: response.data.data.name,
              fb_name: response.data.data.fb_name,
              bundle_name: response.data.data.bundle_name,
              platform: response.data.data.platform,
              schema: response.data.data.schema,
              sender_id: response.data.data.sender_id,
              is_default: response.data.data?.isDefault || 0,
              settings: [],
            }
            this.appData = [...appData, newApp]
            this.cacheData = this.appData.map(item => ({ ...item }))
            this.resetAddAppForm()
            this.hideAppModal()
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Error while creating new application',
              description: error.message,
            })
          })
        } else {
          return false
        }
      })
    },
    handleChange(value, key, column) {
      const newData = [...this.appData]
      const target = newData.filter(item => key === item.application_id)[0]
      if (target) {
        target[column] = value
        this.appData = newData
      }
    },
    onDelete(key) {
      const dataSource = [...this.appData]
      const target = dataSource.filter(item => key === item.application_id)[0]
      const url = '/admin/applications/' + key
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'Application "' + target.name + '" successfully deleted',
          description: '',
        })
        this.appData = dataSource.filter(item => item.application_id !== key)
        // this.cacheData = this.appData.map(item => ({ ...item }))
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting app',
          description: error.message,
        })
      })
    },
    edit(key) {
      console.log(key)
      const newData = [...this.appData]
      const target = newData.filter(item => key === item.application_id)[0]
      this.editingKey = key
      if (target) {
        target.editable = true
        this.appData = newData
      }
    },
    save(key) {
      const newData = [...this.appData]
      const newCacheData = [...this.cacheData]
      const target = newData.filter(item => key === item.application_id)[0]
      const targetCache = newCacheData.filter(item => key === item.application_id)[0]
      console.log('targetCache')
      console.log(targetCache)
      if (target && targetCache) {
        const url = '/admin/applications/' + target.application_id
        return apiClient.patch(url, target).then((response) => {
          this.$notification.success({
            message: 'Application "' + response.data.data.name + '" successfully updated',
            description: '',
          })
          delete target.editable
          this.appData = newData
          Object.assign(targetCache, target)
          this.cacheData = newCacheData
          this.editingKey = ''
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: 'Error while updating application',
            description: error.message,
          })
        })
      }
      this.editingKey = ''
    },
    cancel(key) {
      const newData = [...this.appData]
      const target = newData.filter(item => key === item.application_id)[0]
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.filter(item => key === item.application_id)[0])
        delete target.editable
        this.appData = newData
      }
    },
  },
}
</script>
<style scoped>
  .editable-row-operations a {
    margin-right: 8px;
  }
</style>
