<template>
  <div class="sports-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
      <a-col :span="18">
        <h3 class="my-2" style="font-size: 20px;">Icons & Colors</h3>
      </a-col>
      <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end;">
        <a-button type="primary" class="ml-4" size="large" @click="createSportModal">
          <a-icon type="plus" /> Add new
        </a-button>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <a-row type="flex" class="olimp-admin-auth pb-4">
        <a-col flex="250px" class="pr-2" style="box-sizing: border-box !important; font-size: 16px !important">
          <span class="pr-2" style>Search Field:</span>
          <a-select
            v-model="searchField"
            @change="handleSearchField"
            :default-value="searchFields[0]"
            style="width: 140px"
          >
            <a-select-option v-for="sf in searchFields" :key="sf" :value="sf">
              {{ sf[0].toUpperCase() + sf.slice(1).replaceAll('_', ' ') }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col flex="auto">
          <a-input-search size="large" v-model="searchValue" placeholder="Input search text and press Enter" enter-button @search="onSearch" @change="handleSearchChange" />
        </a-col>
      </a-row>
      <a-table
        :columns="sportColumns"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: '3%', fixed: true }"
        :row-key="record => record.id"
        :data-source="sportData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        class="sports-table"
      >
        <template slot="sport_name" slot-scope="text">
          {{ text }}
        </template>
        <template slot="icon" slot-scope="text, record">
          <img v-if="record.colors.icon_path" :src="record.colors.icon_path" width="32" height="32" />
        </template>
        <template slot="light_color" slot-scope="text, record">
          <span class="color-square" :style="'background-color: ' + record.colors.light_color_hex"></span>
          {{ record.colors.light_color_hex }}
          <a-tooltip title="Copy to clipboard" placement="right">
            <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardHandle(record.colors.light_color_hex)" /></a>
          </a-tooltip>
        </template>
        <template slot="dark_color" slot-scope="text, record">
          <span class="color-square" :style="'background-color: ' + record.colors.dark_color_hex"></span>
          {{ record.colors.dark_color_hex }}
          <a-tooltip title="Copy to clipboard" placement="right">
            <a href="javascript:;">
              <a-icon type="copy" class="text-primary" @click="clipboardHandle(record.colors.dark_color_hex)" />
            </a>
          </a-tooltip>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="row-operations" v-if="checkPermissions(user.permissions, 'sports', 'edit')">
            <span>
              <a-tooltip title="Edit sport colors" placement="bottom">
                <a class="font-size-18 text-primary" href="javascript:;" @click="() => editSportModal(record)">
                  <a-icon type="edit" />
                </a>
              </a-tooltip>
            </span>
            <span>
              <a-tooltip title="Delete sport colors" placement="bottom">
                <a-popconfirm
                  v-if="sportData.length"
                  title="Sure to delete this sport colors?"
                  @confirm="() => onDelete(record.id)"
                >
                  <a class="font-size-18 text-danger" href="javascript:;">
                    <a-icon type="delete" />
                  </a>
                </a-popconfirm>
              </a-tooltip>
            </span>
          </div>
        </template>
      </a-table>
      <div v-if="hasSelected" class="table-selected-counter">
        {{ `Selected ${selectedRowKeys.length} items | ` }}
        <a-tooltip :title="`Delete ${selectedRowKeys.length} items?`" placement="bottom">
          <a-popconfirm
            v-if="selectedRowKeys.length"
            title="Sure to delete selected items?"
            @confirm="() => onDeleteMultiple()"
          >
            <a class="font-size-16 text-danger" href="javascript:;"><a-icon type="delete" /></a>
          </a-popconfirm>
        </a-tooltip>
      </div>
      <a-modal
        v-model="sportModal"
        :title="sportObj && sportObj.id ? sportObj.name : 'New sport'"
        :footer="null"
        class="sport-modal"
        width="720px"
        :destroyOnClose="true"
      >
        <sport-modal
          @refreshSports="fetch"
          @createSports="handleCreateSport"
          @changeSports="handleUpdateSport"
          @deleteSport="handleDeleteSport"
          @closeModalFunction="sportModal = false"
          :sport-obj="sportObj"
          ref="sportModal"
        />
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import DEFAULT_SPORT from './default_sport'
import { mapState } from 'vuex'
import sportModal from './sportModal.vue'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'

const sportColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: '5%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Sport ID',
    dataIndex: 'sport_id',
    width: '10%',
    scopedSlots: { customRender: 'sport_id' },
  },
  {
    title: 'Sport Name',
    dataIndex: 'name',
    width: '15%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Icon',
    dataIndex: 'icon_path',
    width: '10%',
    scopedSlots: { customRender: 'icon' },
  },
  {
    title: 'Light Theme Colors',
    dataIndex: 'light_color',
    width: '15%',
    scopedSlots: { customRender: 'light_color' },
  },
  {
    title: 'Dark Theme Colors',
    dataIndex: 'dark_color',
    width: '15%',
    scopedSlots: { customRender: 'dark_color' },
  },
  {
    title: 'Action',
    dataIndex: 'operation',
    align: 'right',
    width: '20%',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  components: {
    sportModal,
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
  },
  data() {
    return {
      accessModule: 'sports',
      sportData: [],
      pagination: { pageSize: 25 },
      loading: false,
      sportColumns,
      sportObj: undefined,
      selectedRowKeys: [],
      searchField: 'name',
      searchFields: ['name', 'sport_id', 'light_color_hex', 'dark_color_hex'],
      searchStrictFields: ['sport_id'],
      searchValue: '',
      sportModal: false,
    }
  },
  mounted () {
    this.fetch()
  },
  created() {
    //
  },
  methods: {
    handleSearchField() {
      console.log(this.searchField)
      this.searchValue = ''
    },
    handleSearchChange(e) {
      if (!e.target.value.length) { this.fetch() }
    },
    createSportModal() {
      this.sportObj = { ...DEFAULT_SPORT }
      this.sportModal = true
    },
    editSportModal(obj) {
      this.sportObj = JSON.parse(JSON.stringify(obj))
      this.sportModal = true
    },
    handleUpdateSport(obj) {
      console.log('successfully updated. updating list...')
      this.sportModal = false
      this.fetch()
      console.log(obj)
    },
    handleCreateSport(obj) {
      console.log('new sport successfully created. updating list...')
      this.sportModal = false
      this.fetch()
      console.log(obj)
    },
    handleDeleteSport(id) {
      this.sportData.splice(this.sportData.findIndex(x => x.id === id), 1)
      this.sportModal = false
    },
    onSearch() {
      // if (!this.searchStrictFields.includes(this.searchField) && (this.searchValue.length < 4)) {
      if ((this.searchValue.length < 2 && this.searchField !== 'sport_id')) {
        this.$message.error('Search value must contain at least 2 characters')
      } else if (this.searchValue.length < 1 && this.searchField === 'sport_id') {
        this.$message.error('Search value must contain at least 1 characters')
      } else {
        this.fetch({ searchField: this.searchField, searchValue: this.searchValue })
      }
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
      })
    },
    fetch(params = {}) {
      console.log('params:', params)
      this.loading = true
      const reqData = { results: 25, ...params }
      console.log(reqData)
      const url = '/admin/sports'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.loading = false
        this.sportData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    onDelete(key) {
      const target = this.sportData.filter(item => key === item.id)[0]
      const url = '/admin/sports/' + key
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'Sport "' + target.name + '" successfully deleted',
          description: '',
        })
        this.sportData.splice(this.sportData.findIndex(x => x.id === target.id), 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting sport colors',
          description: error.message,
        })
      })
    },
    onDeleteMultiple() {
      const url = '/admin/sports'
      const selectedData = { selected: this.selectedRowKeys }
      console.log(selectedData)
      apiClient.delete(url, { params: selectedData }).then((response) => {
        this.$notification.success({
          message: 'Successfully deleted',
          description: `${this.selectedRowKeys.length} sports colors`,
        })
        this.selectedRowKeys = []
        this.fetch()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting sports colors',
          description: error.message,
        })
      })
    },
    clipboardMessage(field, v) {
      this.$notification.success({
        message: field + ' copied to clipboard',
        description: v,
      })
    },
    clipboardHandle(text) {
      this.$clipboard(text)
      this.clipboardMessage('Color', text.slice(0, 6) + '...')
    },
  },
}
</script>

<style lang="scss">
.sports-table {
  .color-square {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin: 0 4px -2px 0;
  }
}
@media (max-width: 1600px) {
  .sports-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.9em;
    }
  }
}
@media (max-width: 1500px) {
  .sports-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.8em;
    }
  }
}
</style>
