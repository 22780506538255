<template>
  <div class="olimp-admin-form sportColors">
    <a-form-model
      ref="sportForm"
      :model="colorsForm"
      :rules="colorsRules"
    >
      <a-row>
        <a-col :span="12" class="pr-2">
          <a-form-model-item ref="sport_id" label="Sport ID" prop="sport_id">
            <a-input v-model="colorsForm.sport_id" placeholder="Input Sport ID" />
          </a-form-model-item>
          <a-row>
            <a-col :span="12" class="pr-1">
              <a-form-model-item ref="light_color_hex" label="Light Theme Color" prop="light_color_hex">
                <input-color-picker :color="colorsForm.light_color_hex" v-model="colorsForm.light_color_hex" with-clipboard="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" class="pl-1">
              <a-form-model-item ref="dark_color_hex" label="Dark Theme Color" prop="dark_color_hex">
                <input-color-picker :color="colorsForm.dark_color_hex" v-model="colorsForm.dark_color_hex" with-clipboard="true" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12" class="pl-2 text-left">
          <a-form-model-item ref="name" label="Sport name" prop="name">
            <a-input v-model="colorsForm.name" placeholder="Input Sport Name" />
          </a-form-model-item>
          <div class="font-weight-bold" style="margin-top: -2px">Icon:</div>
          <a-upload
            name="sport-icon"
            action="#"
            list-type="picture"
            class="sport-icon-uploader"
            accept=".png,.jpg,.gif"
            :preview-file="previewFile"
            :before-upload="beforeUpload"
            :defaultFileList="sportObj.colors.icon_path ? [{ uid: '1', name: sportObj.colors.icon_path.substring(sportObj.colors.icon_path.lastIndexOf('/') + 1), status: 'done', url: sportObj.colors.icon_path, thumbUrl: sportObj.colors.icon_path }] : []"
            @change="fileChange"
            :remove="fileRemove"
          >
            <div v-if="colorsForm.uploadVisible" style="margin-top: 6px !important;">
              <a-button > <a-icon type="upload" /> Upload </a-button>
              <div class="font-size-12 text-gray-8" style="margin-top: 6px !important;"> png, 60 x 60 px</div>
            </div>
            <!--                    <a-button> <a-icon type="upload" /> Upload </a-button>-->
          </a-upload>
        </a-col>
      </a-row>
      <hr>
      <div class="mt-1">
        <div class="d-flex">
          <div class="mr-auto">
            <!-- <a-button v-if="!sportObj.id" key="back" @click="closeModal">
              Cancel
            </a-button> -->
            <CustomButton v-if="!sportObj.id" key="back" @click="closeModal">
              Cancel
            </CustomButton>
            <a-popconfirm v-else
              title="Sure to delete?"
              @confirm="deleteSport"
            >
              <!-- <a-button>Delete</a-button> -->
              <CustomButton>Delete</CustomButton>
            </a-popconfirm>
          </div>
          <div>
            <a-button key="submit" type="primary" :loading="loading" @click="onSubmit" class="auto">
              {{ sportObj.id ? 'Save' : 'Create' }}
            </a-button>
          </div>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix'
import inputColorPicker from '@/components/custom/colorPicker/inputColorPicker'
import CustomButton from '@/components/custom/button/CustomButton'

export default {
  name: 'sportModal',
  props: ['sportObj'],
  mixins: [accessMix],
  components: {
    inputColorPicker,
    CustomButton,
  },
  data() {
    return {
      loading: false,
      colorsForm: {
        name: this.sportObj.name,
        sport_id: this.sportObj.sport_id,
        light_color_hex: this.sportObj.id ? this.sportObj.colors.light_color_hex : '#000000',
        dark_color_hex: this.sportObj.id ? this.sportObj.colors.dark_color_hex : '#000000',
        uploadVisible: !this.sportObj.colors.icon_path,
        icon: null,
        updateFile: false,
        deleteFile: false,
      },
      colorsRules: {
        name: [{ required: true, message: 'Please input sport name', trigger: 'change' }],
        sport_id: [{ required: true, message: 'Please input sport ID', trigger: 'change' }],
      },
    }
  },
  methods: {
    closeModal() {
      console.log('closeEditModal')
      this.$emit('closeModalFunction')
    },
    onSubmit() {
      this.$refs.sportForm.validate(valid => {
        if (valid) {
          this.sportObj.id ? this.updateSport() : this.createSport()
        } else {
          console.log('error update submit!!')
          return false
        }
      })
    },
    createSport() {
      this.loading = true
      const url = '/admin/sports'
      return apiClient.post(url, this.colorsForm).then((response) => {
        const obj = response.data.data
        this.$notification.success({
          message: 'Sport "' + obj.name + '" successfully created',
          description: '',
        })
        if (this.colorsForm.updateFile) {
          this.uploadMedia(obj).then(result => { this.$emit('changeSports', obj) })
        } else {
          this.$emit('createSports', obj)
          this.loading = false
        }
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while updating sport',
          description: error.message,
        })
        this.loading = false
      })
    },
    updateSport() {
      this.loading = true
      const url = '/admin/sports/' + this.sportObj.id
      return apiClient.patch(url, this.colorsForm).then((response) => {
        const obj = response.data.data
        this.$notification.success({
          message: 'Sport "' + obj.name + '" successfully updated',
          description: '',
        })
        if (this.colorsForm.updateFile) {
          this.uploadMedia(obj).then(result => { this.$emit('changeSports', obj) })
        } else {
          if (this.colorsForm.deleteFile) {
            this.deleteMedia(obj).then(result => { this.$emit('changeSports', obj) })
          } else { this.$emit('changeSports', obj) }
        }
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while updating sport',
          description: error.message,
        })
        this.loading = false
      })
    },
    uploadMedia(obj) {
      const url = '/admin/sports/' + obj.id + '/icon'
      const formData = new FormData()
      formData.append('icon', this.colorsForm.icon)
      return apiClient.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.$notification.success({
          message: 'Sport icon updated',
          description: '',
        })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while updating icon',
          description: error.message,
        })
        this.loading = false
      })
    },
    deleteMedia(obj) {
      const url = '/admin/sports/' + obj.id + '/icon'
      return apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'Icon successfully deleted',
          description: '',
        })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting icon',
          description: error.message,
        })
        this.loading = false
      })
    },
    deleteSport() {
      const url = '/admin/sports/' + this.sportObj.id
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'Sport "' + this.sportObj.name + '" successfully deleted',
          description: '',
        })
        this.$emit('deleteSport', this.sportObj.id)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting Sport colors',
          description: error.message,
        })
      })
    },
    async previewFile(file) {
      console.log('Your upload file:', file)
      const base64 = await this.toBase64(file)
      // this.form.stories[this.activeKey].file = file
      // this.form.stories[this.activeKey].previewSrc = base64
      console.log('Your upload base64 file:', base64)
      // Your process logic. Here we just mock to the same file
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    fileChange(info) {
      this.colorsForm.icon = info.file.originFileObj
      if (info.fileList.length > 0) {
        this.colorsForm.updateFile = true
        this.colorsForm.uploadVisible = false
      } else {
        this.colorsForm.updateFile = false
        this.colorsForm.uploadVisible = true
      }
      console.log('icon file: ', this.colorsForm.icon)
      // console.log('previewSrc: ' + this.form.stories[this.activeKey].previewSrc)
      console.log('to update: ' + this.colorsForm.updateFile)
      console.log('to delete: ' + this.colorsForm.deleteFile)
      console.log('upload btn visible: ' + this.colorsForm.uploadVisible)
    },
    fileRemove(info) {
      this.colorsForm.deleteFile = true
      this.colorsForm.uploadVisible = true
      console.log('deleteFile = true')
    },
    beforeUpload(file) {
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      const mustWidth = 60
      if (!isPng) {
        this.$message.error('You can only upload PNG file!')
      }
      if (!isLt2M) {
        this.$message.error('File size must be smaller than 2MB!')
      }
      return new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = (err) => {
          const inProportions = Math.round(10 * (img.width / img.height)) / 10 === 1
          const inWidth = img.width === mustWidth
          if (!inWidth) {
            this.$message.error(`Image dimensions must be ${mustWidth}х${mustWidth} px`)
          }
          if (!inProportions) {
            this.$message.error('Image must be in correct proportions - 1 : 1 (e.g. 60 x 60 px)')
          }
          if (inProportions && inWidth && isPng && isLt2M) {
            resolve()
          } else {
            reject(err)
          }
        }
      })
    },
  },
}
</script>
<style lang="scss">
  //.color-picker {
  //  .ant-input { width: 100px !important; }
  //}
  // .color-picker-container { background: transparent; border: 1px  }
  .sportColors {
    .ant-upload {
      button {
        padding: 0 16px !important;
      }
      .anticon {
        margin-right: 4px !important;
      }
    }
    .sport-icon-uploader {
      //margin-top: 0 !important;
    }
  }
</style>
<style lang="scss">
[data-kit-theme="default"] .ant-upload-list-item-card-actions .anticon {
  &:hover {
    color: #f5222e;
  }
  &:active {
    color: #a71d2a;
  }
}
</style>
