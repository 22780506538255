<template>
  <a-card v-if="checkAdminRole(user.roles) && !settings.bannersOnly">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="h5 mb-0">Firebase keys</span>
      <a-button type="primary" size="large" style="font-size: 16px; font-weight: 300; border-radius: 2px;" @click="showSenderModal">
        <a-icon type="plus" /> Add New
      </a-button>
    </div>
    <a-table :columns="senderColumns" :data-source="senderData">
      <template
        v-for="col in ['name', 'fb_key']"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="e => handleSenderChange(e.target.value, record.id, col)"
          />
          <template v-else>
            <template v-if="text.length > 55">
              <a-tooltip title="Copy to clipboard" placement="bottom">
                <a-icon type="copy" class="text-primary" @click="clipboardHandle(text)" style="cursor: pointer; font-size: 16px; padding: 2px 8px 0 0;" />
              </a-tooltip>
              <span>{{ showFb(text)}}</span>
              <a href="javascript:;" class="three-dots" @click="showKey($event, text)">...</a>
            </template>
            <template v-else>
              {{ text }}
            </template>
          </template>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <div class="editable-row-operations" v-if="checkPermissions(user.permissions, 'settings', 'edit')">
          <span v-if="record.editable">
            <a @click="() => saveSender(record.id)" class="text-success"><a-icon type="save" /> Save</a>
            <br>
            <a-popconfirm title="Sure to cancel?" @confirm="() => cancelSender(record.id)">
              <a class="text-danger"><a-icon type="close" /> Cancel</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a href="javascript:;" class="text-primary font-size-18" :disabled="editingSenderKey !== ''" @click="() => editSender(record.id)">
              <a-icon type="edit" />
            </a>
          </span>
          <span v-if="!record.editable">
            <a-popconfirm
              v-if="senderData.length"
              title="Sure to delete?"
              @confirm="() => onDeleteSender(record.id)"
            >
              <a href="javascript:;" class="text-danger font-size-18">
                <a-icon type="delete" />
              </a>
            </a-popconfirm>
          </span>
        </div>
      </template>
    </a-table>
    <!-- <a-modal v-model="newSenderModal"
      title="Add New Sender ID"
      ok-text="Add"
      cancel-text="Cancel"
      @ok="addSenderSubmit"
      class="add-sender-modal olimp-admin-modal"
      width="520px"
      :destroyOnClose="true"
    > -->
    <a-modal v-model="newSenderModal"
      title="Add New Sender ID"
      class="add-sender-modal olimp-admin-modal"
      width="520px"
      :destroyOnClose="true"
      footer=''
    >
      <a-form-model
        ref="addSenderForm"
        :model="addSenderForm"
        :rules="addSenderRules"
      >
        <a-form-model-item ref="name" label="Name" prop="name">
          <a-input v-model="addSenderForm.name" placeholder="Input name" />
        </a-form-model-item>
        <a-form-model-item ref="link" label="FB Key" prop="link">
          <a-input v-model="addSenderForm.fb_key" placeholder="Input FB KEY" />
        </a-form-model-item>
      </a-form-model>
      <hr>
      <div class="d-flex">
        <a-col class="text-left" :span="12">
          <CustomButton @click="hideSenderModal">
            Cancel
          </CustomButton>
        </a-col>
        <a-col class="text-right" :span="12">
          <a-button type="primary" @click="addSenderSubmit">
            Add
          </a-button>
        </a-col>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import banmix from '@/services/bannermix.js'
import accessMix from '@/services/accessmix.js'
import CustomButton from '@/components/custom/button/CustomButton'

const senderColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '15%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'FB Key',
    dataIndex: 'fb_key',
    width: '55%',
    scopedSlots: { customRender: 'fb_key' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    width: '10%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]
var senderData = []
function getSenderIds() {
  const url = '/admin/senders'
  apiClient.get(url).then((response) => {
    senderData = response.data.data
  }).catch(error => { console.log(error) })
}
getSenderIds()

export default {
  name: 'firebase-keys',
  mixins: [banmix, accessMix],
  components: {
    CustomButton,
  },
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
  },
  data() {
    this.cacheSenderData = senderData.map(item => ({ ...item }))
    return {
      senderData,
      senderColumns,
      editingSenderKey: '',
      newSenderModal: false,
      senderIds: [],
      fullKey: false,
      addSenderForm: {
        name: '',
        fb_key: '',
      },
      addSenderRules: {
        name: [{ required: true, message: 'Please input sender name', trigger: 'change' }],
        fb_key: [{ required: true, message: 'Please input FB key', trigger: 'change' }],
      },
    }
  },
  mounted () {
    this.getSenderIds()
  },
  methods: {
    showKey(current, text) {
      const fbKeyText = current.target.previousSibling.innerText
      if (fbKeyText.length > 55) {
        current.target.previousSibling.innerHTML = text.substring(0, 50)
      } else {
        current.target.previousSibling.innerHTML = text
      }
    },
    showFb(text) {
      const result = (text.length > 55) ? text.substring(0, 50) : text
      return result
    },
    getSenderIds() {
      const url = '/admin/senders'
      apiClient.get(url).then((response) => {
        this.senderIds = response.data.data
        this.senderData = response.data.data
        this.cacheSenderData = this.senderData.map(item => ({ ...item }))
        senderData = this.senderData
      }).catch(error => { console.log(error) })
    },
    onSenderChange() {
      console.log(this.addAppForm.sender_id)
    },
    showSenderModal() {
      this.newSenderModal = true
    },
    hideSenderModal() {
      this.newSenderModal = false
    },
    resetAddSenderForm() {
      this.$refs.addSenderForm.resetFields()
    },
    addSenderSubmit() {
      this.$refs.addSenderForm.validate(valid => {
        if (valid) {
          const url = '/admin/senders'
          return apiClient.post(url, this.addSenderForm).then((response) => {
            this.$notification.success({
              message: '"' + response.data.data.name + '" sender successfully created',
              description: '',
            })
            const { senderData } = this
            const newSender = response.data.data
            this.senderData = [...senderData, newSender]
            this.cacheSenderData = this.senderData.map(item => ({ ...item }))
            this.resetAddSenderForm()
            this.hideSenderModal()
            this.senderIds = this.senderData
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Error while creating new Sender ID',
              description: error.message,
            })
          })
        } else {
          return false
        }
      })
    },
    handleSenderChange(value, key, column) {
      const newData = [...this.senderData]
      const target = newData.filter(item => key === item.id)[0]
      if (target) {
        target[column] = value
        this.senderData = newData
      }
    },
    onDeleteSender(key) {
      const dataSource = [...this.senderData]
      const target = dataSource.filter(item => key === item.id)[0]
      const url = '/admin/senders/' + key
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'Sender ID "' + target.name + '" successfully deleted',
          description: '',
        })
        this.senderData = dataSource.filter(item => item.id !== key)
        this.cacheSenderData = this.senderData.map(item => ({ ...item }))
        this.senderIds = this.senderData
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting Sender ID',
          description: error.message,
        })
      })
    },
    editSender(key) {
      console.log(key)
      const newData = [...this.senderData]
      const target = newData.filter(item => key === item.id)[0]
      this.editingSenderKey = key
      if (target) {
        target.editable = true
        this.senderData = newData
      }
    },
    saveSender(key) {
      const newData = [...this.senderData]
      const newCacheData = [...this.cacheSenderData]
      const target = newData.filter(item => key === item.id)[0]
      const targetCache = newCacheData.filter(item => key === item.id)[0]
      if (target && targetCache) {
        const url = '/admin/senders/' + target.id
        return apiClient.patch(url, target).then((response) => {
          this.$notification.success({
            message: '"' + response.data.data.name + '" sender ID successfully updated',
            description: '',
          })
          delete target.editable
          this.senderData = newData
          this.senderIds = this.senderData
          Object.assign(targetCache, target)
          this.cacheSenderData = newCacheData
          this.editingSenderKey = ''
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: 'Error while updating deeplink',
            description: error.message,
          })
        })
      }
      this.editingSenderKey = ''
    },
    clipboardMessage(field, v) {
      this.$notification.success({
        message: field + ' successfully copied to clipboard',
        description: v,
      })
    },
    clipboardHandle(v) {
      this.$clipboard(v)
      this.clipboardMessage('', v.slice(0, 36) + '...')
    },
    cancelSender(key) {
      const newData = [...this.senderData]
      const target = newData.filter(item => key === item.id)[0]
      this.editingSenderKey = ''
      if (target) {
        Object.assign(target, this.cacheSenderData.filter(item => key === item.id)[0])
        delete target.editable
        this.senderData = newData
      }
    },
  },
}
</script>
<style scoped lang="scss">
  .editable-row-operations a {
    margin-right: 8px;
  }
</style>
