<template>
  <a-card class="accepted-users">
    <a-row type="flex" class="olimp-admin-auth pb-4" style="gap: 10px;">
      <a-col style="box-sizing: border-box !important; font-size: 16px !important">
        <div class="d-flex align-items-center mr-2">
          <span class="pr-2" style>Filters:</span>
          <a-select
            @change="handleSelectPlatform"
            style="min-width: 150px"
            placeholder="Select platform"
            class="mr-2"
          >
            <a-select-option value="All">All</a-select-option>
            <a-select-option v-for="(platform, index) in platforms" :key="index" :value="platform.value">
              {{ platform.name }}
            </a-select-option>
          </a-select>
        </div>
      </a-col>
      <a-col flex="auto">
        <a-input-search
          v-model="searchValue"
          size="large"
          placeholder="input search text"
          enter-button
          @search="onSearch"
        >
          <a-icon
            v-show="searchValue.length"
            type="close-circle"
            class="close-circle-search"
            slot="suffix"
            @click="clearSearchValue"
          />
        </a-input-search>
      </a-col>
    </a-row>
    <a-table
      :columns="userColumns"
      :data-source="usersDataC"
      :row-key="record => record.id"
      :row-selection="{ selectedRowKeys: selectedUserKeys, onChange: onSelectUserChange }"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      class="users-table"
    >
      <!-- <span slot="login" slot-scope="login">
        <a href="javascript:" @click="showUser(login)">{{ login ? login : '-' }}</a>
      </span> -->
      <!-- <span slot="lang_id" slot-scope="lang_id, record">
        <flag :iso="record.lang.short" />
      </span> -->
      <!-- <span slot="fcm_token" slot-scope="fcm_token">
        <span v-if="fcm_token.length>1">
          <a-tooltip title="Copy to clipboard" placement="right">
            <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardFcmHandle(fcm_token)" /></a>
          </a-tooltip>
          {{ fcm_token.slice(0,6) }}...
        </span>
        <span v-else class="text-gray-4">Unavailable</span>
      </span> -->
      <span slot="phone" slot-scope="phone, record">
        <span
          v-if="phone && record.phone_last4"
          class="accepted-users__phone"
          :class="{'accepted-users__phone-hover' : record.platforma && record.bundle_id}"
          @click="showUser(record)"
        >
          <span v-if="record.phone_decrypted === true">
            {{ record.phone }}
            <a-tooltip title="Hide phone" placement="bottom">
              <a href="javascript:;">
                <a-icon type="eye" class="text-primary" @click="encryptPhone(record.id)"  />
              </a>
            </a-tooltip>
          </span>
          <span v-else>
            *{{ record.phone_last4 }}
            <a-tooltip title="Show phone" placement="bottom" v-if="decryptionKey">
              <a href="javascript:;" v-if="!record.phone_decrypted">
                <a-icon type="eye-invisible" class="text-primary" @click="decryptPhone(record.id, record.phone)"  />
              </a>
            </a-tooltip>
          </span>
        </span>
        <span v-else class="text-gray-4">Unavailable</span>
      </span>
      <span slot="type" slot-scope="type">
        <a-icon v-if="type.toLowerCase() === 'android'" type="android" class="text-success" />
        <a-icon v-if="type.toLowerCase() === 'ios'" type="apple" class="text-primary" />
        <a-icon v-if="type.toLowerCase() === 'web'" type="global" class="text-default" />
        <a-icon v-else type="minus" />
      </span>
      <span slot="bundle_id" slot-scope="bundle_id">
        <span v-if="bundle_id">{{ bundle_id }}</span>
        <a-icon v-else type="minus" />
      </span>
      <template slot="operation" slot-scope="text, record">
        <!-- <a-tooltip title="Delete user" placement="left"> -->
          <a-popconfirm
            placement="left"
            title="Sure to delete this user?"
            @confirm="() => onDelete(record.id)"
          >
            <a class="font-size-18 text-danger" href="javascript:;"><a-icon type="delete" /></a>
          </a-popconfirm>
        <!-- </a-tooltip> -->
      </template>
    </a-table>
    <div
      class="footer-actions"
      :class="!usersDataC ? 'mt-3' : 'footer-actions__mt'"
    >
      <div class="footer-actions__container">
        <a-popconfirm
          placement="top"
          title="Sure to delete this user?"
          @confirm="() => onMassDelete()"
        >
          <a-button
            size="large"
            :loading="false"
            :disabled="!hasSelectedUsers"
            :class="{'text-danger' : hasSelectedUsers}"
            >
            <a-icon type="delete" />
            <span :class="{'text-gray-6': hasSelectedUsers}">Delete</span>
          </a-button>
        </a-popconfirm>
      </div>
    </div>
    <a-modal
      v-model="userModal"
      :title="`*${titleUserModal}`"
      class="account-modal"
      width="470px"
      :footer="null"
      :ok-button-props="{ props: { disabled: true } }"
      :destroyOnClose="true"
    >
      <account-modal
        :modalDataUser="modalDataUser"
        @closeModalFunction="hideUser"
      />
    </a-modal>
  </a-card>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import accountModal from '@/views/apps/profiles/whitelist/accountModal'

const userColumns = [
  {
    title: 'Phone',
    dataIndex: 'phone',
    width: '30%',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: 'Platform',
    dataIndex: 'type',
    width: '30%',
    // filters: [
    //   { text: 'iOS', value: 'IOS' },
    //   { text: 'Android', value: 'ANDROID' },
    // ],
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Bundle ID',
    dataIndex: 'bundle_id',
    width: '30%',
    scopedSlots: { customRender: 'bundle_id' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    key: 'operation',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  props: ['addUserEvent'],
  components: {
    accountModal,
  },
  mixins: [accessMix],
  watch: {
    addUserEvent(val) {
      if (val) {
        this.fetch()
      }
    },
    searchValue(newVal, oldVal) {
      if (!newVal.length && oldVal.length) {
        this.fetch(this.parameterGeneration())
      }
    },
  },
  computed: {
    ...mapState(['user']),
    usersDataC() {
      return this.usersData
    },
    hasSelectedUsers() {
      return this.selectedUserKeys.length > 0
    },
    titleUserModal() {
      return this.modalDataUser?.phone_last4 || ''
    },
    decryptionKey() {
      return !!sessionStorage.getItem('app.encryption.privatekey')
    },
  },
  data() {
    return {
      usersData: [],
      selectedUserKeys: [],
      pagination: { pageSize: 25 },
      loading: false,
      userColumns,
      userModal: false,
      apps: [],
      selectedPlatform: 'All',
      platforms: [{ name: 'iOS', value: 'IOS' }, { name: 'Android', value: 'ANDROID' }, { name: 'Web', value: 'WEB' }],
      withPhoneNumber: false,
      searchValue: '',
      modalDataUser: null,
    }
  },
  mounted () {
    this.fetch()
    this.getApplications()
  },
  methods: {
    getApplications() {
      const url = '/admin/applications'
      apiClient.get(url).then((response) => {
        this.apps = response.data.data
      }).catch(error => { console.log(error) })
    },
    handleSelectPlatform(platform) {
      this.selectedPlatform = platform
      this.fetch(this.parameterGeneration())
    },
    onlyWithPhoneNumber(val) {
      this.withPhoneNumber = val.target.checked
      this.fetch(this.parameterGeneration())
    },
    clipboardMessage(field, v) {
      this.$notification.success({
        message: field + ' successfully copied to clipboard',
        description: v,
      })
    },
    clipboardFcmHandle(v) {
      this.$clipboard(v)
      this.clipboardMessage('FCM Token', v.slice(0, 8) + '...')
    },
    clipboardCustomerHandle(v) {
      this.$clipboard(v)
      this.clipboardMessage('Customer ID', v.slice(0, 8) + '...')
    },
    onSearch() {
      const customFieldSearchLength = 2
      if (this.searchValue.length < customFieldSearchLength) {
        this.$message.error(`Search value of this field must contain at least ${customFieldSearchLength} characters`)
      } else {
        this.fetch(this.parameterGeneration())
      }
    },
    clearSearchValue() {
      this.searchValue = ''
      this.fetch(this.parameterGeneration())
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...this.parameterGeneration(),
      })
    },
    parameterGeneration() {
      const params = {}
      if (this.selectedPlatform !== 'All') params.type = this.selectedPlatform
      if (this.withPhoneNumber) params.withPhone = this.withPhoneNumber
      if (this.searchValue.length) {
        params.phone = this.searchValue
      }
      return params
    },
    fetch(params = {}) {
      this.loading = true
      const reqData = { results: 25, ...params }
      const url = '/admin/accounts/whitelisted'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = Number(response.data.meta.total)
        this.loading = false
        this.usersData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    decryptPhone(key, value) {
      const decKey = sessionStorage.getItem('app.encryption.privatekey')
      if (!decKey) {
        this.$message.error('Decryption key not found in session storage')
        return
      }
      const target = this.usersData.filter(item => key === item.id)[0]
      const dec = this.$decryptData(decKey, value)
      if (dec) {
        target.phone_decrypted = true
        target.phone_orig = value
        target.phone = dec
      } else {
        this.$message.error('Data decryption failed due to invalid key or data content')
      }
    },
    encryptPhone(key) {
      const target = this.usersData.filter(item => key === item.id)[0]
      target.phone = target.phone_orig
      target.phone_decrypted = false
    },
    showUser(record) {
      if (record.platforma && record.bundle_id) {
        this.modalDataUser = record
        this.userModal = true
      }
    },
    hideUser() {
      this.userModal = false
    },
    onSelectUserChange(selectedUserKeys) {
      this.selectedUserKeys = selectedUserKeys
    },
    clearSelectedUserKeys() {
      this.selectedUserKeys = []
    },
    onDelete(id) {
      const url = '/admin/accounts/whitelisted/mass'
      apiClient.delete(url, { params: [id] }).then((response) => {
        this.$notification.success({
          message: 'Request successfully deleted',
          description: '',
        })
        this.clearSelectedUserKeys()
        this.fetch()
      }).catch(error => {
        this.$notification.error({
          message: 'Error while deleting user',
          description: error.message,
        })
      })
    },
    onMassDelete() {
      const url = '/admin/accounts/whitelisted/mass'
      apiClient.delete(url, { params: this.selectedUserKeys }).then((response) => {
        this.$notification.success({
          message: 'Request successfully deleted',
          description: '',
        })
        this.clearSelectedUserKeys()
        this.fetch()
      }).catch(error => {
        this.$notification.error({
          message: 'Error while deleting request',
          description: error.message,
        })
      })
    },
  },
}
</script>

<style lang="scss">
@media (max-width: 1600px) {
  .users-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.9em;
    }
  }
}
@media (max-width: 1500px) {
  .users-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.8em;
    }
  }
}

.accepted-users {
  &__phone {
    cursor: default;
  }
  &__phone-hover {
    cursor: pointer;
    &:hover {
      color: var(--kit-color-primary)
    }
  }
}
.accepted-users .ant-select-selection__placeholder {
  opacity: .25;
  font-size: 14px;
}
.accepted-users .anticon-filter {
  position: relative !important;
  height: 12px !important;
}
</style>
