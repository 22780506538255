<template>
    <div>
        <a-row style="padding-bottom: 12px;">
          <span class="font-weight-bold">Download link</span>
        </a-row>
        <a-row style="padding-bottom: 24px;">
          <a class="text-primary" :href="this.downloadLink" download>{{ this.downloadLink}}</a>
          <a-tooltip class="pl-2" title="Copy to clipboard" placement="right">
            <a href="javascript:;"><a-icon type="copy" class="text-primary font-size-16" @click="saveLink" /></a>
          </a-tooltip>
        </a-row>
        <a-row class="footer-modal">
            <a-col class="text-center" :span="24">
                <a-button type="primary" @click="closeModal"> Ok </a-button>
            </a-col>
        </a-row>
    </div>
</template>
<script>

export default {
  name: 'downloadExport',
  props: ['downloadLink'],
  components: {},
  data: () => ({
  }),
  computed: {
  },
  methods: {
    saveLink() {
      this.$clipboard(this.downloadLink)
      this.$notification.success({
        message: 'Export link successfully copied to clipboard',
      })
    },
    closeModal() {
      this.$emit('closeModal')
    },
  },
  async created() {
  },
}
</script>

  <style lang="scss">
  </style>
