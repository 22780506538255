<template>
  <a-config-provider>
    <template #renderEmpty>
      <div class="d-flex flex-column justify-content-center align-items-center my-5">
        <Empty>
          <template #image>
            <EmptyIcon />
          </template>
          <template #description>
            <div v-html="text" />
          </template>
        </Empty>
      </div>
    </template>
    <slot />
  </a-config-provider>
</template>

<script>
import { Empty } from 'ant-design-vue'
import EmptyIcon from '@/components/custom/icons/emptyIcon'

export default {
  props: {
    text: {
      type: String,
      default: 'No Data',
    },
  },
  components: {
    Empty,
    EmptyIcon,
  },
}
</script>

<style>
.ant-empty .ant-empty-image {
  height: 100% !important;
}
</style>
