<template>
  <div class="prisma-users">
    <div>
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div>
          <a-form-model-item class="mr-4" label="Search field:" :colon="false">
            <a-select v-model="selectedSearch" size="large" style="width: 140px">
              <a-select-option v-for="option in searchOptions" :key="option.value" :value="option.value">
                {{ option.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
        <a-input-search size="large" v-model="search" :placeholder="searchPlaceholder" enter-button
          @change="tableUpdate" :disabled="loading" class="" />
      </div>
      <a-table :columns="columns" :data-source="prismaUsers" :rowKey="(record, index) => index" :loading="loading"
        :pagination="pagination" @change="changePagination" class="prisma-users__table">
        <template slot="login" slot-scope="text, record">
          <div class="d-flex flex-column">
            <a class="prisma-users__link" :href="`/prisma/${record.login}/${record.uuid}/tasks`">
              {{ record.login }}
            </a>
            <div style="color: #8C8C8C">
              <span class="mr-1"> UUID: {{ record.uuid }}</span>
              <a-tooltip title="Copy to clipboard" placement="right">
                <a href="javascript:;"><a-icon type="copy" class="copy-icon"
                    @click="clipboardHandle(record.uuid)" /></a>
              </a-tooltip>
            </div>
          </div>
        </template>
        <template slot="registration_date" slot-scope="text, record">
          <span>{{ formatDate(record.registration_date) }}</span>
        </template>
        <template slot="transactions" slot-scope="text, record">
          <a class="prisma-users__link" :href="`/prisma/${record.login}/${record.uuid}/transactions`">
            Show
          </a>
        </template>
        <template slot="bets" slot-scope="text, record">
          <a class="prisma-users__link" :href="`/prisma/${record.login}/${record.uuid}/bets`">
            Show
          </a>
        </template>
        <template slot="availablePrizes" slot-scope="text, record">
          <a class="prisma-users__link" :href="`/prisma/${record.login}/prizes`">
              Show
          </a>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import PrismaService from '@/services/api/apiPrismaService'
import debounce from 'lodash.debounce'

export default {
  name: 'prismaUsers',
  props: ['paymentsView'],
  components: {},
  data: () => ({
    loading: false,
    prismaUsers: null,
    search: '',
    selectedSearch: 'uuid',
    pagination: {
      pageSize: 20,
      current: 1,
      total: 0,
    },
    searchOptions: [
      { title: 'UUID', value: 'uuid' },
      { title: 'Login', value: 'login' },
    ],
    columns: [
      {
        title: 'Login',
        dataIndex: 'login',
        width: '25%',
        scopedSlots: { customRender: 'login' },
      },
      {
        title: 'Registration date',
        dataIndex: 'registration_date',
        width: '25%',
        scopedSlots: { customRender: 'registration_date' },
      },
      {
        title: 'Points scored',
        dataIndex: 'bonus_count',
        width: '16%',
      },
      {
        title: 'Transactions',
        width: '11%',
        scopedSlots: { customRender: 'transactions' },
      },
      {
        title: 'Bets',
        width: '11%',
        align: 'left',
        scopedSlots: { customRender: 'bets' },
      },
      {
        title: 'Available prizes',
        width: '11%',
        scopedSlots: { customRender: 'availablePrizes' },
      },
    ],
  }),
  computed: {
    searchPlaceholder() {
      return this.selectedSearch === 'uuid' ? 'Enter UUID' : 'Enter login'
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    async getUsers() {
      this.loading = true

      const { pageSize, current } = this.pagination
      const searchParams = new URLSearchParams()
      searchParams.append('per_page', pageSize)
      searchParams.append('page', current)
      if (this.search.length) {
        this.selectedSearch === 'uuid' ? searchParams.append('uuid', this.search) : searchParams.append('login', this.search)
      }
      try {
        const response = await PrismaService.getPrismaUsers(searchParams)
        if (response.data.data) {
          this.prismaUsers = response.data.data
          this.pagination.total = response.data.meta.total
        }
      } catch (err) {
        this.$notification.error({
          message: 'Something went wrong',
          description: err.response.data.data.message,
        })
      } finally {
        this.loading = false
      }
    },
    changePagination(pagination) {
      this.pagination = pagination
      this.getUsers()
    },
    clipboardHandle(uuid) {
      this.$clipboard(uuid)
      this.$notification.success({
        message: 'User UUID successfully copied to clipboard',
        description: uuid,
      })
    },
    tableUpdate: debounce(function() {
      this.pagination.current = 1
      this.getUsers()
    }, 1500),
    formatDate: function (date) {
      return this.$moment(date).tz('Etc/GMT-3').format('DD.MM.YY HH:mm')
    },
  },
}
</script>
  <style lang="scss">
  </style>
