<template>
  <div class="users-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
      <a-col :span="18">
        <h3 class="my-2" style="font-size: 20px;">Users</h3>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <a-row type="flex" class="olimp-admin-auth pb-4">
        <a-col flex="250px" class="pr-2" style="box-sizing: border-box !important; font-size: 16px !important">
          <span class="pr-2" style>Search Field:</span>
          <a-select
            v-model="searchField"
            @change="handleSearchField"
            :default-value="searchFields[0]"
            style="width: 140px"
          >
            <a-select-option v-for="sf in searchFields" :key="sf" :value="sf">
              {{ sf[0].toUpperCase() + sf.slice(1).replace('pp_v', 'pp Version').replace('hone_last4', 'hone').replace('_', ' ') }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col flex="auto">
          <a-input-search size="large" v-model="searchValue" placeholder="input search text" enter-button @search="onSearch"/>
        </a-col>
      </a-row>
      <a-table
        :columns="userColumns"
        :row-key="record => record.login"
        :data-source="usersDataC"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        class="users-table"
      >
        <span slot="login" slot-scope="text, record">
          <div class="d-flex flex-column">
            <a href="javascript:" @click="showUser(record.login)">{{ record.login }}</a>
            <div style="color: #8C8C8C">
              <span> UUID: {{ record.uuid.slice(0, 11) + '... ' }}</span>
              <a-tooltip title="Copy to clipboard" placement="right">
                <a href="javascript:;"><a-icon type="copy" class="copy-icon" @click="clipboardHandle(record.uuid)" /></a>
              </a-tooltip>
            </div>
          </div>
        </span>
        <span slot="lang_id" slot-scope="lang_id, record">
          <flag :iso="record.lang.short" />
        </span>
        <span slot="type" slot-scope="type">
          <a-icon v-if="type.toLowerCase() === 'android'" type="android" class="text-success" />
          <a-icon v-if="type.toLowerCase() === 'ios'" type="apple" class="text-primary" />
          <a-icon v-if="type.toLowerCase() === 'web'" type="global" class="text-default" />
        </span>
        <span slot="fcm_token" slot-scope="fcm_token">
          <span v-if="fcm_token.length>1">
            <a-tooltip title="Copy to clipboard" placement="right">
              <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardFcmHandle(fcm_token)" /></a>
            </a-tooltip>
            {{ fcm_token.slice(0,6) }}...
          </span>
          <span v-else class="text-gray-4">Unavailable</span>
        </span>
        <span slot="phone" slot-scope="phone, record">
          <span v-if="phone && record.phone_last4">
            <span v-if="record.phone_decrypted === true">
              {{ record.phone }}
              <a-tooltip title="Hide phone" placement="bottom">
                <a href="javascript:;">
                  <a-icon type="eye" class="text-primary" @click="encryptPhone(record.id)"  />
                </a>
              </a-tooltip>
            </span>
            <span v-else>
              *{{ record.phone_last4 }}
              <a-tooltip title="Show phone" placement="bottom" v-if="decryptionKey">
                <a href="javascript:;" v-if="!record.phone_decrypted">
                  <a-icon type="eye-invisible" class="text-primary" @click="decryptPhone(record.id, record.phone)"  />
                </a>
              </a-tooltip>
            </span>
          </span>
          <span v-else class="text-gray-4">Unavailable</span>
        </span>
        <span slot="app_v" slot-scope="app_v">
          <span v-if="app_v">
            {{ app_v }}
          </span>
          <span v-else class="text-gray-4">Unavailable</span>
        </span>
        <span slot="customer_id" slot-scope="customer_id">
          <span v-if="customer_id">
            {{ customer_id.slice(0,6) }}...
            <a-tooltip title="Copy to clipboard" placement="right">
              <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardCustomerHandle(customer_id)" /></a>
            </a-tooltip>
          </span>
          <span v-else class="text-gray-4">Unavailable</span>
        </span>
        <span slot="updated_at" slot-scope="updated_at">
          {{ $moment(updated_at).format('DD.MM.YY HH:mm') }}
        </span>
        <template slot="operation" slot-scope="text, record">
          <a-tooltip title="Send push-notification" placement="left">
            <a href="javascript:;" @click="sendPush(record)"><a-icon type="notification" class="text-primary font-size-18" /></a>
          </a-tooltip>
        </template>
      </a-table>
    </a-card>
    <a-modal v-model="sendPushModal"
             title="Send push-notification to user"
             ok-text="Send"
             cancel-text="Cancel"
             @ok="sendPushSubmit"
             width="520px"
             :destroyOnClose="true"
    >
      <a-form-model
        ref="sendPushForm"
        :model="sendPushForm"
        :rules="sendPushRules"
      >
        <div v-if="selUser" class="pb-4">Send notification to user with login: <span class="font-weight-bold">{{ selUser.login }}</span></div>
        <a-form-model-item ref="title" label="Title" prop="title">
          <a-input v-model="sendPushForm.title" placeholder="Input title" />
        </a-form-model-item>
        <a-form-model-item ref="body" label="Message body" prop="body">
          <a-input v-model="sendPushForm.body" type="textarea" placeholder="Input message" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      v-model="userModal"
      :title="`User ${selLogin}`"
      class="account-modal"
      width="1000px"
      :footer="null"
      :ok-button-props="{ props: { disabled: true } }"
      :destroyOnClose="true"
    >
      <account-modal :account-login="selLogin" @closeModalFunction="hideUser" />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
// import accountModal from '@/views/apps/accounts/accountModal'
import accountModal from './accountModal'

// const currencies = { 0: 200, 1: 300 }

const userColumns = [
  {
    title: 'Login',
    dataIndex: 'login',
    sorter: true,
    width: '15%',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    width: '7%',
    scopedSlots: { customRender: 'phone' },
  },
  // {
  //   title: 'Currency',
  //   dataIndex: 'currency_id',
  //   sorter: true,
  //   width: '9.5%',
  //   scopedSlots: { customRender: 'currency_id' },
  // },
  // {
  //   title: 'Language',
  //   dataIndex: 'lang_id',
  //   sorter: true,
  //   width: '10%',
  //   scopedSlots: { customRender: 'lang_id' },
  // },
  {
    title: 'Platform',
    dataIndex: 'type',
    width: '5%',
    filters: [
      { text: 'iOS', value: 'IOS' },
      { text: 'Android', value: 'ANDROID' },
      { text: 'Web', value: 'WEB' },
    ],
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Bundle ID',
    dataIndex: 'bundle_id',
    width: '13%',
    scopedSlots: { customRender: 'bundle_id' },
  },
  {
    title: 'App Version',
    dataIndex: 'app_v',
    width: '7%',
    scopedSlots: { customRender: 'app_v' },
  },
  // {
  //   title: 'FCM Token',
  //   dataIndex: 'fcm_token',
  //   width: '10.5%',
  //   scopedSlots: { customRender: 'fcm_token' },
  // },
  // {
  //   title: 'Customer ID',
  //   dataIndex: 'customer_id',
  //   width: '10%',
  //   scopedSlots: { customRender: 'customer_id' },
  // },
  {
    title: 'Last updated',
    dataIndex: 'updated_at',
    align: 'right',
    width: '12%',
    scopedSlots: { customRender: 'updated_at' },
  },
  /*
  {
    title: 'Actions',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
  */
]

export default {
  name: 'index',
  components: {
    // userProfile,
    accountModal,
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    usersDataC() {
      return this.usersData
    },
  },
  data() {
    return {
      accessModule: 'accounts',
      usersData: [],
      pagination: { pageSize: 25 },
      loading: false,
      userColumns,
      userModal: false,
      searchField: 'uuid',
      searchFields: ['uuid', 'login', 'fcm_token', 'phone_last4', 'bundle_id', 'app_v'],
      searchValue: '',
      sendPushModal: false,
      // usersObj: undefined,
      selUser: undefined,
      selLogin: null,
      // selIndex: 0,
      // profileModal: false,
      sendPushForm: {
        title: '',
        body: '',
      },
      sendPushRules: {
        title: [{ required: true, message: 'Please input title', trigger: 'change' }],
        body: [{ required: true, message: 'Please input message body', trigger: 'change' }],
      },
      decryptionKey: !!sessionStorage.getItem('app.encryption.privatekey'),
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    sendPush(rec) {
      this.selUser = rec
      this.sendPushModal = true
      // ---
      console.log(rec)
    },
    handleSearchField() {
      console.log(this.searchField)
    },
    clipboardMessage(field, v) {
      this.$notification.success({
        message: field + ' successfully copied to clipboard',
        description: v,
      })
    },
    clipboardFcmHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.clipboardMessage('FCM Token', v.slice(0, 8) + '...')
    },
    clipboardCustomerHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.clipboardMessage('Customer ID', v.slice(0, 8) + '...')
    },
    clipboardHandle(text) {
      this.$clipboard(text)
      this.clipboardMessage('UUID', text.slice(0, 11) + '...')
    },
    onSearch() {
      const customFieldSearchLength = 2
      if ((this.searchField !== 'login') && (this.searchValue.length < customFieldSearchLength)) {
        this.$message.error(`Search value of this field must contain at least ${customFieldSearchLength} characters`)
      } else {
        this.fetch({ searchField: this.searchField, searchValue: this.searchValue })
      }
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
      })
    },
    fetch(params = {}) {
      console.log('params:', params)
      this.loading = true
      const reqData = { results: 25, ...params }
      console.log(reqData)
      const url = '/admin/accounts'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = Number(response.data.meta.total)
        // pagination.total = response.data.meta.total
        this.loading = false
        this.usersData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    sendPushSubmit() {
      this.$refs.sendPushForm.validate(valid => {
        if (valid) {
          const url = '/admin/accounts/' + this.selUser.login + '/push'
          return apiClient.post(url, this.sendPushForm).then((response) => {
            this.$notification.success({
              message: 'Successfully sent',
              description: 'MessageID: ' + response.data.results[0].message_id,
            })
            this.sendPushModal = false
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Error on send push notification',
              description: error.message,
            })
          })
        } else {
          return false
        }
      })
    },
    decryptPhone(key, value) {
      const decKey = sessionStorage.getItem('app.encryption.privatekey')
      if (!decKey) {
        this.$message.error('Decryption key not found in session storage')
        return
      }
      const target = this.usersData.filter(item => key === item.id)[0]
      // const index = this.usersData.map(e => e.id).indexOf(key)
      const dec = this.$decryptData(decKey, value)
      if (dec) {
        target.phone_decrypted = true
        target.phone_orig = value
        target.phone = dec
      } else {
        this.$message.error('Data decryption failed due to invalid key or data content')
      }
    },
    encryptPhone(key) {
      const target = this.usersData.filter(item => key === item.id)[0]
      target.phone = target.phone_orig
      target.phone_decrypted = false
    },
    showUser(login) {
      this.selLogin = login
      this.userModal = true
    },
    hideUser() {
      this.userModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
@media (max-width: 1600px) {
  .users-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.9em;
    }
  }
}
@media (max-width: 1500px) {
  .users-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.8em;
    }
  }
}
.users-content .anticon-filter {
  position: relative !important;
  height: 12px !important;
}
.copy-icon {
  color: #8C8C8C;
  transition: color 0.3s ease;
  &:hover {
    color: #007bff;
  }
}
</style>
