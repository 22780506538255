import apiClient from '@/services/axios'
import { getErrorMessage } from '@/views/apps/fortune-wheel/composables/useGetErrorMessage.js'

const wheelApiUrl = process.env.VUE_APP_WHEEL_API_URL

const deleteSector = async (wheelId, sectorId) => {
  try {
    const url = `${wheelApiUrl}/admin/wheels/${wheelId}/sectors/${sectorId}`
    const response = await apiClient.delete(url)
    if (response?.data?.success) {
      return response
    } else {
      getErrorMessage(response?.data)
    }
  } catch (e) {
    this.$notification.error({
      message: e?.message ? e.message : 'Error',
    })
  }
}

export { deleteSector }
