const copyToClipboard = (context, value, message = '') => {
  if (!value?.length) {
    return
  }
  context.$clipboard(value)
  if (message?.length) {
    context.$notification.success({
      message,
    })
  }
}

export { copyToClipboard }
