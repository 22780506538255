<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <i class="fe fe-package font-size-24" :class="$style.icon"></i>
      <span class="d-none d-xl-inline">{{ $t('topBar.panelManagement') }}</span>
    </div>
    <a-menu slot="overlay">
      <a-menu-item-group title="Accounts">
        <a-menu-item>
          <a href="javascript: void(0);"><router-link to="/accounts"><i class="fe fe-users font-size-16 mr-1" /> Accounts</router-link></a>
        </a-menu-item>
        <a-menu-item>
          <a href="javascript: void(0);"><router-link to="/permissions"><i class="fe fe-share-2 font-size-16 mr-1" /> Roles & permissions</router-link></a>
        </a-menu-item>
      </a-menu-item-group>
      <a-menu-item-group title="Tools">
        <a-menu-item>
          <a href="javascript: void(0);"><router-link to="/encryption"><i class="fe fe-lock font-size-16 mr-1" /> Encryption</router-link></a>
        </a-menu-item>
      </a-menu-item-group>
      <a-menu-item-group title="Server">
        <a-menu-item>
          <a href="javascript: void(0);"><router-link to="/backup"><i class="fe fe-database font-size-16 mr-1" /> Backup</router-link></a>
        </a-menu-item>
        <a-sub-menu key="disk" icon="eye" v-if="diskStat">
          <span slot="title">
            <i class="fe fe-hard-drive font-size-16 mr-1" /> Disk Stat
          </span>
          <a-menu-item>Used space<br/>
            <span v-if="diskStat.disk_used_space"><a-progress :percent="diskStat.disk_used_space" status="active" /></span>
            <div class="font-size-12"><span class="">{{ diskStat.disk_total_size }} Gb Total</span></div>
            <div class="font-size-12"><span class="text-warning">{{ diskStat.disk_used_size }} Gb Used</span></div>
            <div class="font-size-12"><span class="text-success">{{ diskStat.disk_free_size }} Gb Free</span></div>
          </a-menu-item>
        </a-sub-menu>
      </a-menu-item-group>
<!--      <a-menu-divider />-->
<!--      <a-menu-item>-->
<!--        <a href="javascript: void(0);">-->
<!--          <i class="fe fe-settings mr-2" />-->
<!--          Settings-->
<!--        </a>-->
<!--      </a-menu-item>-->
    </a-menu>
  </a-dropdown>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  data() {
    return {
      diskStat: null,
      diskStatLoading: false,
    }
  },
  mounted () {
    // ---
  },
  created() {
    this.getDiskStat()
  },
  methods: {
    async getDiskStat() {
      this.diskStatLoading = true
      const url = '/admin/server/disk/stat'
      try {
        const response = await apiClient.get(url)
        this.diskStat = response.data.data
        console.log('disk stat', this.diskStat)
      } catch (error) {
        console.log('Error while trying to load disk stat', error)
      } finally {
        this.diskStatLoading = false
      }
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
