export default {
  methods: {
    checkPermissions(userPermissions, module, type) {
      if (userPermissions) {
        const checkPerm = userPermissions.find(obj => {
          return obj.name === module + '.' + type
        })
        return typeof checkPerm !== 'undefined'
      }
      return false
    },
  },
}
