<template>
    <div>
        <a-row style="padding-bottom: 12px;">
            <a-radio-group name="radioGroup" v-model="selectedPromoType" :default-value="selectedPromoType">
                <a-radio v-for="option in exportOptions" :key="option.value" :value="option.value"
                    class="promo-export__radio" defaultValue="banners">{{ option.name }}</a-radio>
            </a-radio-group>
        </a-row>
        <a-row class="footer-modal">
            <a-col class="text-right" :span="24">
                <a-button @click="closeModal"> Cancel </a-button>
                <a-button :loading="loading" type="primary" @click="startExport" class="ml-2">
                    Export
                </a-button>
            </a-col>
        </a-row>
    </div>
</template>
<script>

export default {
  name: 'selectExportType',
  props: [],
  components: {},
  data: () => ({
    loading: false,
    selectedPromoType: 'banners',
    exportOptions: [{ value: 'banners', name: 'Banner' }, { value: 'stories', name: 'Story' }, { value: 'promos', name: 'Banners & Stories' }],
  }),
  computed: {
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    startExport() {
      this.$emit('selectType', this.selectedPromoType)
    },
  },
  async created() {
  },
}
</script>

<style lang="scss"></style>
