<template>
    <a-col :span="24">
      <div :class="$style.card" class="bg-white">
        <div :class="$style.content" @click="$emit('click')">
          <div
            :class="$style.image"
            :style="{ backgroundImage: 'url(' + splashscreenInfo.img_sm_path + ')' }"
          />
          <div :class="$style.left">
            <div class="mb-3">
              <div :class="$style.title">Name</div>
              <div>{{ splashscreenInfo.name }}</div>
            </div>
            <div class="mb-3">
              <div :class="$style.title">Apps</div>
              <div style="line-height: 2.1rem">
                <a-tag style="margin-right: 5px; padding: 1px 8px; border-radius: 2px;" v-for="app in splashscreenInfo.apps.slice(0, 4)" :key="app.id">
                  {{ app.name }}
                  <a-icon type="android" class="text-success" v-if="app.platform==='android'" />
                  <a-icon type="apple" class="text-primary" v-if="app.platform==='ios'" />
                  <a-icon type="global" class="text-default" v-if="app.platform === 'web'" />
                </a-tag>
                <a-tag
                  v-if="splashscreenInfo.apps.length > 4"
                >
                  ...
                </a-tag>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer" :class="$style.footer">
          <div class="d-flex">
            <div class="text-uppercase mr-auto fd-life-time">
              {{ formatDate(splashscreenInfo.starts_at) }} -
              <span>{{ formatDate(splashscreenInfo.ends_in) }}</span>
            </div>
            <div v-if="1 === 1" class="text-gray-6">
              <a-switch
                :default-checked="splashscreenInfo.is_active"
                @change="splashscreenActiveSwitch"
                :disabled="!checkPermissions(user.permissions, 'banners', 'edit')"
              />
            </div>
          </div>
        </div>
      </div>
    </a-col>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'

export default {
  name: 'splashscreenCard',
  props: ['splashscreenInfo'],
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
  },
  data: () => ({
  }),
  methods: {
    openEditModal() {
      this.$emit('childOpenFunction')
    },
    splashscreenActiveSwitch(checked) {
      const url = '/admin/splashscreens/' + this.splashscreenInfo.id + '/status'
      apiClient.patch(url, {
        is_active: +checked,
      }).then((response) => {
        this.$root.$emit('splashscreensActiveChange')
        this.$notification.success({
          message: 'Active status changed',
          description: this.splashscreenInfo.name,
        })
      }).catch(error => { console.log(error) })
    },
    formatDate: function(date) {
      return this.$moment(date).format('DD.MM.YY HH:mm')
    },
    deleteBanner() {
      if (confirm('Do you want to delete these banner?')) {
        const url = '/admin/splashscreens/' + (this.splashscreenInfo.id)
        apiClient.delete(url).then((response) => {
          console.log(response.data)
          // if ((response.data.error) && (!data.error.message))
          this.$notification.success({
            message: 'Splashscreen "' + this.splashscreenInfo.name + '" has been successfully deleted',
            description: '',
          })
          // this.$router.push('/banners')
          this.$emit('delete-splashscreen-card')
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: 'Error while deleting splashscreen',
            description: error.message,
          })
        })
      }
    },
  },
}
</script>

<style lang='scss' module>
  @import './style.module.scss';
</style>
