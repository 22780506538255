<style>
  .table-selected-counter
  {
    z-index: 1;
  }
</style>

<template>
  <div class="promocodes-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
      <a-col :span="18">
        <h3 class="my-2" style="font-size: 20px;">Promocode</h3>
      </a-col>
      <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end;">
        <a-tooltip title="Import .xls file into the table" placement="bottom">
          <a-button type="default" size="large" class="mr-2" @click="showImportModal"><a-icon type="download" /> Import</a-button>
        </a-tooltip>
        <a-tooltip title="Download file with all data " placement="bottom">
          <a-button type="default" size="large" class="mr-2" @click="exportPromocodes">
            <a-icon v-if="isExporting" type="loading" />
            <a-icon v-else type="upload" />
            Export
          </a-button>
        </a-tooltip>
        <a-button type="primary" size="large" @click="showAddPromoModal"><a-icon type="plus" /> Add new</a-button>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <a-row type="flex" class="olimp-admin-auth pb-4 prom-nav">
        <a-col flex="250px" class="pr-2" style="box-sizing: border-box !important; font-size: 16px !important">
          <span class="pr-2" style>Search Field:</span>
          <a-select
            v-model="searchField"
            @change="handleSearchField"
            :default-value="searchFields[0]"
            style="width: 140px"
          >
            <a-select-option v-for="sf in searchFields" :key="sf" :value="sf">
<!--              {{ sf[0].toUpperCase() + sf.slice(1).replace('_', ' ').replace('ido', 'ID') }}-->
              <span>{{ sf.replace('promo_rf', 'Technical').replace('promo_ez', 'Easy').replace('promo_personal', 'Custom') }}</span>
            </a-select-option>
          </a-select>
        </a-col>
        <a-col flex="auto" class="prom-nav__search-input">
          <a-input-search size="large" v-model="searchValue" placeholder="Input search text and press Enter" enter-button @search="onSearch" @change="handleSearchChange" />
        </a-col>
        <a-col align="right" class="pl-3">
          <a-radio-group v-model="marketingOnly" button-style="solid" size="large" @change="handleMarketingChange">
            <a-radio-button :value="false">
              All
            </a-radio-button>
            <a-radio-button :value="true">
              Marketing
            </a-radio-button>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-table
        :columns="promocodeColumns"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: '3%', fixed: true }"
        :row-key="record => record.id"
        :data-source="promocodeData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        class="promocode-table"
      >
<!--        <span slot="starts_at" slot-scope="starts_at">-->
<!--          {{ $moment(starts_at).format('DD.MM.YY HH:mm') }}-->
<!--        </span>-->
        <template slot="login" slot-scope="text">
          <div v-if="text"><a-icon type="user" /> {{ text }}</div>
        </template>
        <template slot="promo_rf" slot-scope="text">
          {{ text }}
          <a-tooltip title="Copy to clipboard" placement="right">
            <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardHandle(text)" /></a>
          </a-tooltip>
        </template>
        <template slot="promo_ez" slot-scope="text">
          {{ text }}
          <a-tooltip title="Copy to clipboard" placement="right">
            <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardHandle(text)" /></a>
          </a-tooltip>
        </template>
        <template slot="promo_personal_title">
          Custom
        </template>
        <template slot="promo_personal" slot-scope="text, record">
          {{ text }}
          <a-popconfirm
            v-if="promocodeData.length"
            title="Reset attempts?"
            @confirm="() => resetAttempts(record.id)"
            class="mb-4"
          >
            <a-tooltip :title="'Attempts to rename\n custom promocode'" placement="top" v-if="text">
              <a-badge v-if="text"
                       :number-style="{
                    backgroundColor: '#fff',
                    color: '#999',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                    marginTop: '0px',
                  }" class="ml-1 mr-3">
                <span slot="count" style="padding: 2px 4px 2px 4px; border-radius: 8px; font-size: 12px;">{{ record.tries }}</span>
              </a-badge>
            </a-tooltip>
          </a-popconfirm>
          <a-tooltip title="Copy to clipboard" placement="right" v-if="text">
            <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardHandle(text)" /></a>
          </a-tooltip>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="row-operations" v-if="checkPermissions(user.permissions, 'promocode', 'edit')">
            <span>
              <a-tooltip title="Edit promocode" placement="bottom">
                <a class="font-size-18 text-primary" href="javascript:;" @click="() => showEditPromoModal(record)"><a-icon type="edit" /></a>
              </a-tooltip>
            </span>
            <span v-if="record.is_marketing === 1">
              <a-tooltip title="Delete promocode" placement="bottom">
                <a-popconfirm
                  v-if="promocodeData.length"
                  title="Sure to delete this promocode?"
                  @confirm="() => onDelete(record.id)"
                >
                  <a class="font-size-18 text-danger" href="javascript:;"><a-icon type="delete" /></a>
                </a-popconfirm>
              </a-tooltip>
            </span>
          </div>
        </template>
      </a-table>
      <div v-if="hasSelected" class="table-selected-counter">
        {{ `Selected ${selectedRowKeys.length} items | ` }}
        <a-tooltip :title="`Delete ${selectedRowKeys.length} items?`" placement="bottom">
          <a-popconfirm
            v-if="selectedRowKeys.length"
            title="Sure to delete selected items?"
            @confirm="() => onDeleteMultiple()"
          >
            <a class="font-size-16 text-danger" href="javascript:;"><a-icon type="delete" /></a>
          </a-popconfirm>
        </a-tooltip>
      </div>
      <a-modal
        title="Import file"
        @ok="importPromocodes"
        class="olimp-admin-modal"
        width="400px"
        :destroyOnClose="true"
        v-model="importModal"
      >
        <a-upload-dragger
          name="import"
          :multiple="false"
          :before-upload="beforeUpload"
          :remove="handleRemove"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">Click or drag .xls(x) file to this area to upload </p>
          <p
            class="ant-upload-hint"
          >Maximum file size: 2 MB</p>
        </a-upload-dragger>
        <template slot="footer">
          <div class="d-flex">
            <div class="mr-auto">
              <CustomButton key="back" @click="hideImportModal">
                Cancel
              </CustomButton>
            </div>
            <div>
              <a-button key="submit" type="primary" @click="importPromocodes" :loading="this.importing" class="auto">
                Import
              </a-button>
            </div>
          </div>
        </template>
      </a-modal>
      <a-modal v-model="editPromoModal"
               title="Edit Custom Promocode"
               @ok="editPromoSubmit"
               class="olimp-admin-modal"
               width="520px"
               :destroyOnClose="true"
      >
        <a-form-model
          ref="editPromoForm"
          :model="editPromoForm"
          :rules="editPromoRules"
        >
          <a-form-model-item ref="promo_personal" label="Custom Promocode" prop="promo_personal">
            <a-input v-model="editPromoForm.promo_personal" placeholder="Input promocode" @change="handleEditPromoInput" :maxLength=20 allowClear />
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <div class="d-flex">
            <div class="mr-auto">
              <!-- <a-button
                key="back"
                @click="hideEditPromoModal"
              >
                Cancel
              </a-button> -->
              <CustomButton key="back" @click="hideEditPromoModal">
                Cancel
              </CustomButton>
            </div>
            <div>
              <a-button key="submit" type="primary" :loading="loading" @click="editPromoSubmit" class="auto">
                Save
              </a-button>
            </div>
          </div>
        </template>
      </a-modal>
      <a-modal v-model="addPromoModal"
               title="Create Marketing Promocode"
               @ok="addPromoSubmit"
               class="olimp-admin-modal"
               width="520px"
               :destroyOnClose="true"
      >
        <a-form-model
          ref="addPromoForm"
          :model="addPromoForm"
          :rules="addPromoRules"
        >
          <a-row>
            <a-col :span="12" class="pr-1">
              <a-form-model-item ref="promo_rf" label="Original promocode" prop="promo_rf">
                <a-input v-model="addPromoForm.promo_rf" placeholder="Input original promocode" :maxLength=30 allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" class="pl-1">
              <a-form-model-item ref="promo_ez" label="Easy promocode" prop="promo_ez">
                <a-input v-model="addPromoForm.promo_ez" placeholder="Input easy promocode" :maxLength=30 allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-form-model>
        <template slot="footer">
          <div class="d-flex">
            <div class="mr-auto">
              <!-- <a-button key="back" @click="hideAddPromoModal">
                Cancel
              </a-button> -->
              <CustomButton key="back" @click="hideAddPromoModal">
                Cancel
              </CustomButton>
            </div>
            <div>
              <a-button key="submit" type="primary" :loading="submitLoading" @click="addPromoSubmit" class="auto">
                Create
              </a-button>
            </div>
          </div>
        </template>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import CustomButton from '@/components/custom/button/CustomButton'

const promocodeColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: '12%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Login',
    dataIndex: 'login',
    width: '20%',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'Original',
    dataIndex: 'promo_rf',
    width: '15%',
    scopedSlots: { customRender: 'promo_rf' },
  },
  {
    title: 'Easy',
    dataIndex: 'promo_ez',
    width: '15%',
    scopedSlots: { customRender: 'promo_ez' },
  },
  {
    // title: 'Promo Personal',
    dataIndex: 'promo_personal',
    width: '20%',
    slots: {
      title: 'promo_personal_title',
    },
    scopedSlots: { customRender: 'promo_personal' },
  },
  {
    title: 'Action',
    dataIndex: 'operation',
    align: 'right',
    width: '15%',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  components: {
    CustomButton,
    //
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
  },
  data() {
    return {
      accessModule: 'promocode',
      promocodeData: [],
      pagination: { pageSize: 25 },
      loading: false,
      submitLoading: false,
      promocodeColumns,
      selectedRowKeys: [],
      searchField: 'login',
      searchFields: ['login', 'promo_rf', 'promo_ez', 'promo_personal'],
      searchStrictFields: ['login', 'promo_rf', 'promo_ez', 'promo_personal'],
      searchValue: '',
      marketingOnly: false,
      editPromoModal: false,
      importFile: [],
      importing: false,
      editPromoForm: {
        id: null,
        promo_personal: null,
      },
      addPromoModal: false,
      importModal: false,
      isExporting: false,
      addPromoForm: {
        promo_rf: '',
        promo_ez: '',
      },
      addPromoRules: {
        promo_rf: [{ required: true, message: 'Please input promoRF', trigger: 'change' }],
        promo_ez: [{ required: true, message: 'Please input promoEZ', trigger: 'change' }],
      },
      editPromoRules: {
        // promo_personal: [{ required: true, message: 'Please input promocode', trigger: 'change' }],
      },
    }
  },
  mounted () {
    this.fetch({ marketingOnly: this.marketingOnly, searchField: this.searchField, searchValue: this.searchValue })
  },
  created() {
    //
  },
  methods: {
    handleSearchField() {
      console.log(this.searchField)
      this.searchValue = ''
    },
    handleSearchChange(e) {
      if (!e.target.value.length) { this.fetch({ marketingOnly: this.marketingOnly }) }
    },
    showImportModal() {
      this.importModal = true
    },
    hideImportModal() {
      this.importModal = false
    },
    beforeUpload(file) {
      this.importFile = file
      return false
    },
    handleRemove(file) {
      this.importFile = undefined
    },
    importPromocodes() {
      const file = this.importFile
      if (file.size / 1024 / 1024 > 2) {
        this.$notification.error({
          message: 'Error while importing promocodes',
          description: 'File is too heavy (max 2MB)',
        })
        return
      }

      this.importing = true

      const url = '/admin/promocode/import'
      const formData = new FormData()
      formData.append('import', file)

      apiClient.post(url, formData).then((response) => {
        this.$notification.success({
          message: 'Promocodes has been successfully imported',
        })
        this.hideImportModal()
        this.fetch({ marketingOnly: this.marketingOnly, searchField: this.searchField, searchValue: this.searchValue })
        this.importing = false
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while importing promocodes',
          description: error.message,
        })
        this.importing = false
      })
    },
    exportPromocodes() {
      if (this.isExporting) return
      this.isExporting = true
      const url = '/admin/promocode/export'
      apiClient.get(url, { responseType: 'blob' }).then((response) => {
        const filename = 'promocode_export.xlsx'
        const element = document.createElement('a')
        element.href = window.URL.createObjectURL(response.data)
        element.download = filename
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
        this.isExporting = false
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while exporting promocodes',
          description: error.message,
        })
        this.isExporting = false
      })
    },
    showAddPromoModal() {
      this.addPromoModal = true
    },
    showEditPromoModal(record) {
      this.editPromoForm.id = record.id
      this.editPromoForm.promo_personal = record.promo_personal
      this.editPromoModal = true
    },
    hideEditPromoModal() {
      this.editPromoModal = false
    },
    hideAddPromoModal() {
      this.addPromoModal = false
      this.$refs.addPromoForm.resetFields()
    },
    handleEditPromoInput(e) {
      // -
    },
    handleMarketingChange(e) {
      console.log(e.target.value)
      this.fetch({ marketingOnly: this.marketingOnly, searchField: this.searchField, searchValue: this.searchValue })
    },
    onSearch() {
      // if (!this.searchStrictFields.includes(this.searchField) && (this.searchValue.length < 4)) {
      if ((this.searchValue.length < 2)) {
        this.$message.error('Search value must contain at least 2 characters')
      } else {
        this.fetch({ searchField: this.searchField, searchValue: this.searchValue, marketingOnly: this.marketingOnly })
      }
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    addPromoSubmit() {
      this.$refs.addPromoForm.validate(valid => {
        if (valid) {
          this.submitLoading = true
          const url = '/admin/promocode/marketing'
          apiClient.post(url, this.addPromoForm).then((response) => {
            this.$notification.success({
              message: 'Promocode successfully created',
              description: '',
            })
            // this.resetAddLinkForm()
            this.submitLoading = false
            this.fetch({ searchField: this.searchField, searchValue: this.searchValue, marketingOnly: this.marketingOnly })
            this.hideAddPromoModal()
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Error while creating promocode',
              description: error.message,
            })
            this.submitLoading = false
          })
        }
      })
    },
    editPromoSubmit() {
      this.$refs.editPromoForm.validate(valid => {
        if (valid) {
          const url = '/admin/promocode/' + this.editPromoForm.id
          const sendPromo = this.editPromoForm.promo_personal.length ? { promo_personal: this.editPromoForm.promo_personal } : { }
          apiClient.patch(url, sendPromo).then((response) => {
            this.$notification.success({
              message: 'Promocode successfully updated',
              description: '',
            })
            const newData = [...this.promocodeData]
            const target = newData.filter(item => this.editPromoForm.id === item.id)[0]
            if (target) {
              target.promo_personal = this.editPromoForm.promo_personal.toUpperCase()
              this.promocodeData = newData
            }
            // this.resetAddLinkForm()
            this.hideEditPromoModal()
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Error updating promocode',
              description: error.message,
            })
          })
        } else {
          return false
        }
      })
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
        marketingOnly: this.marketingOnly,
      })
    },
    fetch(params = {}) {
      console.log('params:', params)
      this.loading = true
      const reqData = { results: 25, ...params }
      console.log(reqData)
      const url = '/admin/promocode'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.loading = false
        this.promocodeData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    onDelete(key) {
      const target = this.promocodeData.filter(item => key === item.id)[0]
      const url = '/admin/promocode/' + key
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'Promocode "' + target.promo_ez + '" successfully deleted',
          description: '',
        })
        this.promocodeData.splice(this.promocodeData.findIndex(x => x.id === target.id), 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting promocode',
          description: error.message,
        })
      })
    },
    onDeleteMultiple() {
      const url = '/admin/promocode'
      const selectedData = { selected: this.selectedRowKeys }
      console.log(selectedData)
      apiClient.delete(url, { params: selectedData }).then((response) => {
        this.$notification.success({
          message: 'Successfully deleted',
          description: `${this.selectedRowKeys.length} promocodes`,
        })
        this.selectedRowKeys = []
        this.fetch({ searchField: this.searchField, searchValue: this.searchValue, marketingOnly: this.marketingOnly })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting promocode',
          description: error.message,
        })
      })
    },
    resetAttempts(objId) {
      const url = '/admin/promocode/reset-attempts/' + objId
      apiClient.get(url).then((response) => {
        this.$notification.success({
          message: 'Reset attempts successful',
          description: '',
        })
        this.promocodeData[this.promocodeData.findIndex(x => x.id === objId)].ec = response.data.data.ec
        this.promocodeData[this.promocodeData.findIndex(x => x.id === objId)].tries = response.data.data.tries
        // console.log(this.promocodeData[this.promocodeData.findIndex(x => x.id === objId)])
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while reset attempts',
          description: error.message,
        })
      })
    },
    clipboardMessage(field, v) {
      this.$notification.success({
        message: field + ' copied to clipboard',
        description: v,
      })
    },
    clipboardHandle(text) {
      this.$clipboard(text)
      this.clipboardMessage('Promocode', text.slice(0, 6) + '...')
    },
  },
}
</script>

<style lang="scss">
@media (max-width: 1600px) {
  .promocode-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.9em;
    }
  }
}
@media (max-width: 1500px) {
  .promocode-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.8em;
    }
  }
}
.prom-nav {
  @media(max-width: 1026px) {
    justify-content: space-between;
    &__search-input {
      order: 3;
      margin-top: 10px;
    }
  }
}
</style>
